<div class="main-background">
  <br><br><br><br><br><br>
  <div class="container" fxLayout="row" fxLayoutAlign="center stretch" [ngClass.lt-md]="'container-lt-md'" fxLayout.lt-md="column" fxLayoutWrap>
    <div fxFlex="50%">
      <br><br><br><br>
      <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
      <br><br><br><br>
        <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>

            <mat-form-field floatLabel="never" >
              <mat-label class="label-field"> Email address</mat-label>
              <div class="input-field" >
                <input class="input-text" matInput type="text" formControlName="id">
              </div>
            </mat-form-field>

            <mat-form-field floatLabel="never" style="margin-top:-3%;">
              <mat-label class="label-field"> Password </mat-label>
              <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                <input class="pass-input-text" matInput type="password" formControlName="password" [type]="hidepwd ? 'password' : 'text'">
                <mat-icon matSuffix (click)="hidepwd = !hidepwd" style="vertical-align:center; padding: 2% 2% 0% 0.5%; transform: scale(1.3); cursor: pointer;">
                  {{hidepwd ? 'visibility_off' : 'visibility'}}
                </mat-icon>
              </div>
            </mat-form-field>

            <!-- Would be used at a later stage for new version of the dashboard -->
            <div fxLayout="row" fxLayoutAlign="space-between center" style=" margin-top: -2%;">
              <div style="text-align: left; margin-left:10%;">
                <a routerLink="V2/ForgotPassword" [state]="{mode: 'register'}" class="password-text" mat-list-item>
                  <span>Need to link <b>account?</b></span>
                </a>
              </div>
              <div style="text-align: right; margin-right:10%;">
                <a routerLink="V2/ForgotPassword" [state]="{mode: 'forget'}" class="password-text" mat-list-item>
                  <span>Forgot your password?</span>
                </a>
              </div>
            </div>
            <br>

            <span *ngIf = "invalidLogin" class="error-message">{{invalidLoginText}}</span>
            <button class="button"  mat-raised-button type="submit">
              <span class="font-Libre-Medium">Login</span>
            </button>
        </form>
    </div>
    <div fxFlex="50%" *ngIf="!mobileScreen" fxHide.lt-md>
      <img class="img" src="assets/2.0/AVA_login_imagery.jpg" alt="AA Labs">
    </div>
  </div>
</div>
