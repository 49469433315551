<div class="main-background">
    <br><br><br><br><br><br>
    <div class="container" fxLayout="row" fxLayoutAlign="center stretch" [ngClass.lt-md]="'container-lt-md'" fxLayout.lt-md="column" fxLayoutWrap>
      <div fxFlex="50%">
        <br>
        <div *ngIf="!formSubmitted" fxLayout="column" fxLayoutAlign="center stretch">
            <span class="text-config">Registration</span>
            <br>
            <form [formGroup]="registrationForm" (ngSubmit)="register()" novalidate>
              <div *ngIf="!showNextPage">
              <span class="normal-text"> Please create a username for this account.</span><br>
              <span class="normal-text"> This is used for logging in with the headset.</span>

              <mat-form-field floatLabel="never" >
                  <mat-label class="label-field"> User Name </mat-label>
                  <div class="input-field" >
                    <input class="input-text" autofocus matInput type="username" formControlName="username">
                  </div>
              </mat-form-field>
              <span *ngIf="registrationForm.controls.username.touched && registrationForm.controls.username.hasError('required')" class="error-message">Username is required.</span>
              <span *ngIf="registrationForm.controls.username.touched && registrationForm.controls.username.hasError('minlength')" class="error-message">Username should be atleast 2 characters.</span>
              <span *ngIf="registrationForm.controls.username.touched && registrationForm.controls.username.hasError('maxlength')" class="error-message">Please create a short username for use within the headset.</span>
              <span *ngIf="registrationForm.controls.username.touched && registrationForm.controls.username.hasError('pattern') && !registrationForm.controls.username.hasError('minlength') && !registrationForm.controls.username.hasError('maxlength')" class="error-message">Username can only contain alphanumeric characters, dots and underscores.</span>

              <span class="normal-text">Please create a password for this account.</span><br>
              <span class="normal-text">The password must have:</span>
              <span fxLayout="row" class="checkbox-text">
                  <span>
                      <mat-checkbox [checked]="!registrationForm.controls.pwd.hasError('minlength') && !(registrationForm.controls.pwd.value.length == 0)" tabindex="-1">Minimum 8 characters</mat-checkbox>
                      <mat-checkbox [checked]="!registrationForm.controls.pwd.hasError('upper') && !registrationForm.controls.pwd.hasError('lower') && !(registrationForm.controls.pwd.value.length == 0)" tabindex="-1">Uppercase & lowercase</mat-checkbox>
                  </span>
                  <span>
                      <mat-checkbox [checked]="!registrationForm.controls.pwd.hasError('nums') && !(registrationForm.controls.pwd.value.length == 0)" tabindex="-1">Numeric character</mat-checkbox>
                      <mat-checkbox [checked]="!registrationForm.controls.pwd.hasError('special') && !(registrationForm.controls.pwd.value.length == 0)" tabindex="-1">Special character</mat-checkbox>
                  </span>
              </span>

                <mat-form-field floatLabel="never" >
                  <mat-label class="label-field"> Password </mat-label>
                  <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                    <input class="input-text" matInput type="password" formControlName="pwd" [type]="hidepwd ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hidepwd = !hidepwd" style="vertical-align:middle; padding: 2% 2% 0% 0.5%;   transform: scale(1.5); cursor: pointer;">
                      {{hidepwd ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                  </div>
                </mat-form-field>
                <span *ngIf="registrationForm.controls.pwd.touched && registrationForm.controls.pwd.hasError('required')" class="error-message">Password is required.</span>

                <mat-form-field floatLabel="never" >
                  <mat-label class="label-field"> Confirm Password</mat-label>
                  <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                    <input class="input-text" matInput type="password" formControlName="conpwd" [type]="hideconpwd ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hideconpwd = !hideconpwd" style="vertical-align:middle; padding: 2% 2% 0% 0.5%;  transform: scale(1.5); cursor: pointer;">
                      {{hideconpwd ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                  </div>
                </mat-form-field>

                <span *ngIf="registrationForm.controls.conpwd.touched && registrationForm.controls.conpwd.hasError('required')" class="error-message">Confirm password is required.</span>
                <span *ngIf = "registrationForm.controls.conpwd.hasError('confirmPasswordMatch')" class="error-message">Passwords should match.</span>

                <span class="normal-text">Please create a pin for this account.</span><br>
                <span class="normal-text">This is used for logging in with the headset:</span>

                <div fxLayout="row" fxLayoutAlign="space-around">
                  <mat-form-field floatLabel="never" fxFlex="30">
                      <mat-label class="label-field"> PIN </mat-label>
                      <div class="input-field" fxLayout="row" fxLayoutAlign="space-between center">
                        <input class="input-text" matInput type="password" formControlName="pin" [type]="hidepin ? 'password' : 'text'">
                        <mat-icon fxFlex="20" matSuffix (click)="hidepin = !hidepin" style="vertical-align:middle; transform: scale(1.5); cursor: pointer;">
                          {{hidepin ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                      </div>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" fxFlex="30">
                      <mat-label class="label-field"> Confirm PIN </mat-label>
                      <div class="input-field" fxLayout="row" fxLayoutAlign="space-between center">
                        <input class="input-text" matInput type="password" formControlName="conpin" [type]="hideconpin ? 'password' : 'text'">
                        <mat-icon fxFlex="20" matSuffix (click)="hideconpin = !hideconpin" style="vertical-align:middle; transform: scale(1.5); cursor: pointer;">
                          {{hideconpin ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                      </div>
                    </mat-form-field>
                </div>
                <span *ngIf = "registrationForm.controls.conpin.hasError('confirmPINMatch')" class="error-message">PINs should match.</span>
                <button class="button"  mat-raised-button type="button" (click) = 'next()' [disabled]="registrationForm.invalid">
                  <span class="font-Libre-Medium">NEXT</span>
                </button>
              </div>
              <div *ngIf="showNextPage">
                <span class="normal-text"> Please fill in the following personal details.</span><br>
                <span class="normal-text" style="font-weight:bold"> This is optional.</span><br><br><br>
                <div fxLayout="row" fxLayoutAlign="space-around">
                  <mat-form-field floatLabel="never" appearance="fill" fxFlex="30" class="dropdown">
                    <mat-label class="label-field" [ngClass]="selectedGender?'hidden':''">Gender</mat-label>
                    <mat-select [(value)]="selectedGender">
                      <!--  (ngModel)="selectedGender" name="selectedGender" -->
                      <mat-option value="Male">Male</mat-option>
                      <mat-option value="Female">Female</mat-option>
                      <mat-option value="NA">Not Specified</mat-option>
                    </mat-select>
                  </mat-form-field>

                <mat-form-field floatLabel="never" appearance="fill" fxFlex="30" class="dropdown" floatLabel="never">
                  <mat-label class="label-field" [ngClass]="selectedAge?'hidden':''">Age Range</mat-label>
                  <mat-select [(value)]="selectedAge">
                    <mat-option value="NA">Not Specified</mat-option>
                    <mat-option value="18-24">18-24</mat-option>
                    <mat-option value="25-29">25-29</mat-option>
                    <mat-option value="30-34">30-34</mat-option>
                    <mat-option value="35-39">35-39</mat-option>
                    <mat-option value="40-44">40-44</mat-option>
                    <mat-option value="45-49">45-49</mat-option>
                    <mat-option value="50-54">50-54</mat-option>
                    <mat-option value="55-59">55-59</mat-option>
                    <mat-option value="60-64">60-64</mat-option>
                    <mat-option value="65-69">65-69</mat-option>
                    <mat-option value="70-74">70-74</mat-option>
                    <mat-option value="75-79">75-79</mat-option>
                    <mat-option value="80-84">80-84</mat-option>
                    <mat-option value="85-89">85-89</mat-option>
                    <mat-option value="90-94">90-94</mat-option>
                  </mat-select>
                </mat-form-field>
                </div>
                <br><br><br>
                <div>
                  <mat-checkbox class="normal-text" (click) ="toggleCheck()">By ticking, you are confirming that you have
                    read, <br> understood and agree to Avatar Academy's <br> <span (click)="tnc()" style="text-decoration: underline;">terms and conditions.</span>
                  </mat-checkbox>
                </div><br>
                <button class="button"  mat-raised-button type="submit" [disabled]="!isTCchecked">
                  <span class="font-Libre-Medium">REGISTER</span>
                </button>
              </div>
            </form>
        </div>
        <div *ngIf="formSubmitted" fxLayout="column" fxLayoutAlign="center stretch">
          <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
          <br>
          <img class="img-center" src="/assets/2.0/AVA_tick_icon.svg" alt="AVA_full-logo" height="120px" width="120px">
          <br>
          <span class="normal-text" style="font-size:xx-large;">Your registration is complete.</span><br>
          <span class="normal-text" style="font-size:xx-large;">Welcome to Avatar Academy.</span>
          <br><br>
          <button class="button"  mat-raised-button type="button" [routerLink]="''">
            <span class="font-Libre-Medium">LOGIN</span>
          </button>
        </div>
      </div>
      <div fxFlex="50%" *ngIf="!mobileScreen" fxHide.lt-md>
        <img class="img" src="/assets/2.0/AVA_login_imagery.jpg" alt="AA Labs">
      </div>
    </div>
  </div>
