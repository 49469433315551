<app-main-nav>
    <br>
    <div class="header-text-config">
        Privacy Detials
    </div>
    <br>
    <div class="text-config">
        Text <br>
        Text Text Text Text Text Text Text Text Text 
    </div>
  
</app-main-nav>  