import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../../shared/service/http.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  SingleAppRoles = ['AVA', 'AVAT', 'AVAP'];

  routes = {
    'BOS': '/CoursesV2/BOS',
    'FPT': '/CoursesV2/FPT',
    'VIS': '/CoursesV2/VIS2',
    'RA': '/CoursesV2/RA',
    'JAN': '/CoursesV2/JAN',
    'DEP': '/CoursesV2/DEP',
    'AVA': '/CoursesV2/AVA',
    'AVAT': '/CoursesV2/AVAT',
    'AVAP': '/CoursesV2/AVAP'
  };

  dataSource = new MatTableDataSource();
  dataSourceModules = new MatTableDataSource();
  displayedColumns = ['Name']
  displayedColumnsModules = ['FullName', 'Users', 'Duration'];
  search: String = "";
  tempData = [];
  userRole: any;
  userType: any;
  isLoadingCourse = true;
  isLoadingModule = true;
  showModules = true;
  selectedCourse;
  fadeOut = false;
  existsError = false;
  errorToShow: String = "";

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private _httpService: HttpService) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  ngOnInit() {
    this._httpService.getAuthentication()
      .subscribe(data => {
        this.userType = data.userType;
        this.userRole = data.role;
        if (this.userType == 'Admin') {
          if (this.SingleAppRoles.includes(this.userRole)) {
            this._httpService.getSACourses({ "org": this.userRole }).subscribe(data => {
              this.selectedCourse = data[0];
              this.isLoadingCourse = false;
              this.dataSource.sort = this.sort;
              this.dataSource = data;

              this._httpService.getSAModuleData({ 'course': data[0].Name, "org": this.userRole }).subscribe(data => {
                this.isLoadingModule = false;
                this.dataSourceModules.sort = this.sort;
                this.dataSourceModules = data;
              }, error => {
                this.isLoadingModule = false
              })
            }, error => {
              this.isLoadingCourse = false
            })
          }
          //#region Non Single App Roles
          if (this.userRole == 'VIS') {
            this._httpService.getCourses('VIS2').subscribe(data => {
              this.isLoadingCourse = false;
              this.dataSource.sort = this.sort;
              // this.dataSource.paginator = this.paginator;
              this.dataSource = data;
            }, error => {
              this.isLoadingCourse = false
              //console.log("Trainees Table  Error", error);
            })
            this._httpService.getModules('VIS2').subscribe(data => {
              this.isLoadingModule = false;
              this.dataSourceModules.sort = this.sort;
              // this.dataSourceModules.paginator = this.paginator;
              this.dataSourceModules = data;
            }, error => {
              this.isLoadingModule = false
              //console.log("Trainees Table  Error", error);
            })
          }
          if (this.userRole == 'BOS') {
            this._httpService.getCourses('BOS3').subscribe(data => {
              this.isLoadingCourse = false;
              this.dataSource.sort = this.sort;
              // this.dataSource.paginator = this.paginator;
              this.dataSource = data;
            }, error => {
              this.isLoadingCourse = false
              //console.log("Trainees Table  Error", error);
            })
            this._httpService.getModules('BOS3').subscribe(data => {
              this.isLoadingModule = false;
              this.dataSourceModules.sort = this.sort;
              // this.dataSourceModules.paginator = this.paginator;
              this.dataSourceModules = data;
            }, error => {
              this.isLoadingModule = false
              //console.log("Trainees Table  Error", error);
            })
          }
          if (this.userRole == 'FPT') {
            this._httpService.getCourses('FPT').subscribe(data => {
              this.isLoadingCourse = false;
              this.dataSource.sort = this.sort;
              // this.dataSource.paginator = this.paginator;
              this.dataSource = data;
            }, error => {
              this.isLoadingCourse = false
              //console.log("Trainees Table  Error", error);
            })
            this._httpService.getModules('FPT').subscribe(data => {
              this.isLoadingModule = false;
              this.dataSourceModules.sort = this.sort;
              // this.dataSourceModules.paginator = this.paginator;
              this.dataSourceModules = data;
            }, error => {
              this.isLoadingModule = false
              //console.log("Trainees Table  Error", error);
            })
          }
          if (this.userRole == 'RA') {
            this._httpService.getCourses('RA').subscribe(data => {
              this.isLoadingCourse = false;
              this.dataSource.sort = this.sort;
              // this.dataSource.paginator = this.paginator;
              this.dataSource = data;
            }, error => {
              this.isLoadingCourse = false
              //console.log("Trainees Table  Error", error);
            })
            this._httpService.getModules('RA').subscribe(data => {
              this.isLoadingModule = false;
              this.dataSourceModules.sort = this.sort;
              // this.dataSourceModules.paginator = this.paginator;
              this.dataSourceModules = data;
            }, error => {
              this.isLoadingModule = false
              //console.log("Trainees Table  Error", error);
            })
          }
          if (this.userRole == 'JAN') {
            this._httpService.getCourses('JAN').subscribe(data => {
              this.isLoadingCourse = false;
              this.dataSource.sort = this.sort;
              // this.dataSource.paginator = this.paginator;
              this.dataSource = data;
            }, error => {
              this.isLoadingCourse = false
              //console.log("Trainees Table  Error", error);
            })
            this._httpService.getModules('JAN').subscribe(data => {
              this.isLoadingModule = false;
              this.dataSourceModules.sort = this.sort;
              // this.dataSourceModules.paginator = this.paginator;
              this.dataSourceModules = data;
            }, error => {
              this.isLoadingModule = false
              //console.log("Trainees Table  Error", error);
            })
          }
          if (this.userRole == 'DEP') {
            this._httpService.getCourses('DEP').subscribe(data => {
              this.isLoadingCourse = false;
              this.dataSource.sort = this.sort;
              // this.dataSource.paginator = this.paginator;
              this.dataSource = data;
            }, error => {
              this.isLoadingCourse = false
              //console.log("Trainees Table  Error", error);
            })
            this._httpService.getModules('DEP').subscribe(data => {
              this.isLoadingModule = false;
              this.dataSourceModules.sort = this.sort;
              // this.dataSourceModules.paginator = this.paginator;
              this.dataSourceModules = data;
            }, error => {
              this.isLoadingModule = false
              //console.log("Trainees Table  Error", error);
            })
          }
          //#endregion
        }
      }, error => {
        if (error.status == 401) {
          this.router.navigateByUrl("/Logout", { state: { error: '401' } });
        }
      })
  }

  sortRows(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (a, b) {
      var i = 0;
      while (i < len) { a = a[prop[i]]; b = b[prop[i]]; i++; }
      return b > a ? 1 : b < a ? -1 : 0;
    });
    return arr;
  };

  searchTable(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showModuleDetails(courseData) {
    this.selectedCourse = courseData;
    this._httpService.getSAModuleData({ 'course': courseData.Name, "org": this.userRole }).subscribe(data => {
      this.isLoadingModule = false;
      this.dataSourceModules.sort = this.sort;
      this.dataSourceModules = data;
    }, error => {
      this.isLoadingModule = false
    })
  }

  showDetails(moduleData) {
    if (this.SingleAppRoles.includes(this.userRole)) {
      this.router.navigateByUrl(this.routes[this.userRole], {
        state: {
          courseID: this.selectedCourse.CourseID,
          moduleName: moduleData.name,
          moduleFullName: moduleData.FullName,
          endpoint: moduleData.Endpoint
        }
      });
    }
    else {
      const route = this.routes[this.userRole];
      if (route) {
        this.router.navigateByUrl(route, { state: { courseName: moduleData.name } });
      }
    }
  }

  public courseForm = this.formBuilder.group({
    name: ['', Validators.compose([Validators.required])]
  }, {
  });

  closePopup() {
    this.fadeOut = false;
    this.courseForm.reset();
  }

  isSARole(): boolean {
    return this.SingleAppRoles.includes(this.userRole);
  }
}
