import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';

@Component({
  selector: 'app-trainee-registration',
  templateUrl: './trainee-registration.component.html',
  styleUrls: ['./trainee-registration.component.scss']
})
export class TraineeRegistrationComponent implements OnInit {

  mobileScreen = false;
  showNextPage = false;
  selectedGender = 'NA';
  selectedAge = 'NA';
  isTCchecked = false;
  formSubmitted = false;
  hidepwd = true;
  hideconpwd = true;
  hidepin = true;
  hideconpin = true;

  userID = null;
  org = null;
  app = null;
  traineeRegisterDetails;
  usernameToCheck;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private _httpService: HttpService,
    private _sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    if (window.innerWidth <= 575) {
      this.mobileScreen = true;
    }
    this.userID = this.activatedRoute.snapshot.params["userID"];
    this.org = this.activatedRoute.snapshot.params["org"];
    this.app = this.activatedRoute.snapshot.params["app"];
  }
  public registrationForm = this.formBuilder.group({
    pwd: ['', Validators.compose([Validators.required, Validators.minLength(8),
    this.regexValidator(new RegExp('[0-9]'), { 'nums': true }),
    this.regexValidator(new RegExp('[a-z]'), { 'lower': true }),
    this.regexValidator(new RegExp('[A-Z]'), { 'upper': true }),
    this.regexValidator(new RegExp('[-\/:-@\[-\`{-~!()_&*£$%#<>.]'), { 'special': true }),
    ])],
    conpwd: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    username: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(10), Validators.pattern('^[a-zA-Z0-9_\.]{2,10}$')])],
    pin: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
    conpin: ['', Validators.compose([Validators.required, , Validators.minLength(4)])]
  }, {
    validator: [this.confirmPasswordMatch('pwd', 'conpwd'), this.confirmPINMatch('pin', 'conpin')]
  });

  confirmPasswordMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  confirmPINMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPINMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    }
  }

  next() {
    this.usernameToCheck = {
      "username": this.registrationForm.controls['username'].value,
      "org": this.org
    }
    //TODO: How to differentiate between SA and non SA?
    if (this.app == "SA") {
    this._httpService.checkSAUsername(this.usernameToCheck)
      .subscribe(data => {
        if (data == "Available") {
          this.showNextPage = true;
        }
        else if (data == "Not available") {
          this.snackBar.open("Oops! Username is unavailable. Please try another username.", 'Close', {
            duration: 8000,
            "horizontalPosition": "center",
            "verticalPosition": "top",
          });
        }
      }, error => {
        // console.log("Add Trainees Error", error);
      })
    }
    else if (this.app == "V2") {
      this._httpService.checkUsername(this.usernameToCheck)
      .subscribe(data => {
        if (data == "Available") {
          this.showNextPage = true;
        }
        else if (data == "Not available") {
          this.snackBar.open("Oops! Username is unavailable. Please try another username.", 'Close', {
            duration: 8000,
            "horizontalPosition": "center",
            "verticalPosition": "top",
          });
        }
      }, error => {
        // console.log("Add Trainees Error", error);
      })
    }
  }

  toggleCheck() {
    this.isTCchecked = !this.isTCchecked
  }

  register() {
    this.traineeRegisterDetails = {
      "userID": this.userID,
      "orgName": this.org,
      "username": this.registrationForm.controls['username'].value,
      "password": this.registrationForm.controls['pwd'].value,
      "pin": this.registrationForm.controls['pin'].value,
      "gender": this.selectedGender,
      "age": this.selectedAge
    }
    if (this.isTCchecked) {
      if (this.app == "SA") {
        this._httpService.registerSATrainee(this.traineeRegisterDetails)
          .subscribe(data => {
            if (data == "User doesn't exist") {
              this.snackBar.open("User doesn't exist!", 'Close', {
                duration: 5000,
                "horizontalPosition": "right",
                "verticalPosition": "bottom",
              });
            }
            else if (data == "Invalid") {
              this.snackBar.open("Something went wrong! Please try again later.", 'Close', {
                duration: 5000,
                "horizontalPosition": "right",
                "verticalPosition": "bottom",
              });
            }
            else {
              this.snackBar.open('Registration successful!', 'Close', {
                duration: 3000,
                "horizontalPosition": "right",
                "verticalPosition": "bottom",
              });
              this.formSubmitted = true;
              this._httpService.sendSATraineeAcknowledgement({ "userID": this.userID })
                .subscribe(data => {

                });
            }
          }, error => {
            // console.log("Add Trainees Error", error);
          })
      }
      else if (this.app == "V2") {
        this._httpService.registerTraineeBOS(this.traineeRegisterDetails)
          .subscribe(data => {
            if (data == "User doesn't exist") {
              this.snackBar.open("User doesn't exist!", 'Close', {
                duration: 5000,
                "horizontalPosition": "right",
                "verticalPosition": "bottom",
              });
            }
            else if (data == "Invalid") {
              this.snackBar.open("Something went wrong! Please try again later.", 'Close', {
                duration: 5000,
                "horizontalPosition": "right",
                "verticalPosition": "bottom",
              });
            }
            else {
              this.snackBar.open('Registration successful!', 'Close', {
                duration: 3000,
                "horizontalPosition": "right",
                "verticalPosition": "bottom",
              });
              this.formSubmitted = true;
              this._httpService.sendTraineeAcknowledgement({"org":this.org, "userID":this.userID})
                .subscribe(data => {

                });
            }
          }, error => {
            // console.log("Add Trainees Error", error);
          })
      }
    }
  }

  tnc() {
    window.open("https://mersus.io/terms-conditions/", "_blank");
  }
}
