<div class="main-back">
    <br><br><br><br><br><br>
    <div class="container" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
        <div fxFlex="50%">
          <br><br>
          <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
          <br><br><br><br>
          <img class="img-tick" src="/assets/2.0/AVA_tick_icon.svg" alt="Tick">
          <br><br>
          <div>
            <div class="white-font">Your email has been verified.</div>
            <br>
            <div class="white-font">Welcome to Avatar Academy.</div>
          </div>
        </div>
        <div fxFlex="50%" *ngIf="!mobileScreen">
          <img class="img" src="/assets/new/Mask Group 46.jpg" alt="AA Labs">
        </div>
      </div>          
</div>