<app-main-nav>
    <div class="main-container">
      <div>
        <div>
          <div class="text-config">Modules</div>
          <div class="med-text-config">List of modules in {{courseFullName}}</div>
          <div  fxLayoutAlign="flex-start" fxLayout.lt-md="column"  class="search-bar-config">
              <mat-form-field style="min-width: 5%; max-width: 93%;">
                <input class="input-config" (keyup)="searchTable($event.target.value)" matInput>
                <mat-icon class="mat-icon-config" matSuffix>search</mat-icon>
              </mat-form-field>
            </div>   
        </div>
      </div>
          <div class = "table-config" *ngIf="userRole!='IMT' && userRole!='IMTLCETB'">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="FullName">
                  <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                  <mat-cell class="table-data-font" *matCellDef="let row"> {{row.FullName}} </mat-cell>
                </ng-container>
  
                  <ng-container matColumnDef="Users">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Users </mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Users}} </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="Duration">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Duration </mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Duration}} </mat-cell>
                  </ng-container>
  
                <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showDetails(row)"></mat-row>
              </mat-table>
  
              <div *ngIf="isLoading" style="margin-left:0.5%; margin-top: 0.5%;">
                <div *ngFor="let _ of [].constructor(5)">
                  <app-skeleton-loading Cwidth="1190" Cheight="48"><br><br>
                  </app-skeleton-loading>
                </div>
              </div>
              <!-- <mat-paginator #paginator class="paginator-class" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->
        </div>
    </div>
  </app-main-nav>
  