<div class="main-background">
    <br><br><br><br><br><br>
    <div class="container" fxLayout="row" fxLayoutAlign="center stretch" [ngClass.lt-md]="'container-lt-md'" fxLayout.lt-md="column" fxLayoutWrap>
      <div fxFlex="50%">
        <br><br><br><br>
        <div fxLayout="column">
            <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
        <br><br>
        <div *ngIf="mode=='forget'" class="normal-text">
            Enter the email address associated with your account 
            and we'll send you a link to reset your password.
        </div>
        <div *ngIf="mode=='register'" class="normal-text">
          Enter the email address associated with your account 
          to continue the registration process.
      </div>
          <form [formGroup]="resetForm" (ngSubmit)="reset()" novalidate>
    
              <mat-form-field floatLabel="never" >
                <mat-label class="label-field"> Email address</mat-label>
                <div class="input-field" >
                  <input class="input-text" matInput type="text" formControlName="email">
                </div>
              </mat-form-field>
              <span *ngIf="resetForm.controls.email.touched && resetForm.controls.email.hasError('email')" class="error-message">Invalid Email</span>
              <button class="button"  mat-raised-button type="submit">
                <span *ngIf="mode=='forget'" class="font-Libre-Medium">RESET</span>
                <span *ngIf="mode=='register'" class="font-Libre-Medium">CONTINUE</span>
              </button>
          </form>
          <br *ngIf="inviteSent || emailError">
          <br *ngIf="inviteSent || emailError">
          <div *ngIf="mode=='forget' && inviteSent" fxLayout="row" style="margin-left:8%;">
            <img src="/assets/2.0/AVA_tick_icon.svg" fxFlex="10%" style="height: 20px;">
            <span class="small-text-config"> Password reset link sent to {{this.resetForm.controls['email'].value}}</span>
          </div>
          <span *ngIf="emailError" class="error-message"> 
            The entered email is either not registered with us or has already completed the registration process. Please recheck and try again.
          </span>
        </div>
      </div>
      <div fxFlex="50%" *ngIf="!mobileScreen" fxHide.lt-md>
        <img class="img" src="/assets/2.0/AVA_login_imagery.jpg" alt="AA Labs">
      </div>
    </div>  
  </div>