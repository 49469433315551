<app-main-nav>
    <div fxLayout="row" fxLayoutAlign="center" *ngIf="showAddModulePopup" class="lpopup"
        [ngClass.lt-lg]="'popup-smallScreen'">
        <div fxLayout="column" fxLayoutGap="10%" fxFlex="99">
            <span class="med-heading-text-config" style="margin-right:auto;">Enter module details</span>
            <form [formGroup]="moduleForm" (ngSubmit)="addModule()" validate fxLayout="column">
                <mat-label class="non-floating-form-label"> Module Name </mat-label>
                <mat-form-field floatLabel="always">
                    <div class="form-input-field">
                        <input class="form-input-text" matInput type="text" formControlName="moduleName">
                    </div>
                    <span
                        *ngIf="moduleForm.controls.moduleName.touched && moduleForm.controls.moduleName.hasError('required')"
                        class="error-message">Module name is required.</span>
                </mat-form-field>
                <mat-label class="non-floating-form-label"> Module Reference Name </mat-label>
                <mat-form-field floatLabel="always">
                    <div class="form-input-field">
                        <input class="form-input-text" matInput type="text" formControlName="refName">
                    </div>
                </mat-form-field>
                <span *ngIf="moduleForm.controls.refName.touched && moduleForm.controls.refName.hasError('pattern')"
                    class="error-message">Incorrect Module reference format. Example: M1</span>
                <span *ngIf="moduleForm.controls.refName.touched && moduleForm.controls.refName.hasError('required')"
                    class="error-message">Module reference name is required.</span>
                <mat-label class="non-floating-form-label" style=" margin-top:5px;"> Module Modes </mat-label>
                <div class="boolean-options" fxLayout="row wrap">
                    <mat-checkbox fxFlex="50" color="primary" formControlName="hasExplainer">
                        Explainer</mat-checkbox>
                    <mat-checkbox fxFlex="50" color="primary" formControlName="hasPractice">
                        Practice</mat-checkbox>
                    <mat-checkbox fxFlex="50" color="primary" formControlName="hasDemonstration">
                        Demonstration</mat-checkbox>
                    <mat-checkbox fxFlex="50" color="primary" formControlName="hasExam">
                        Exam</mat-checkbox>
                </div>
                <mat-label class="non-floating-form-label" style=" margin-top:5px;"> Cover Image <br> <span
                        style="font-size: small;">512 x
                        390px (min size)</span></mat-label><br>
                <div class="image-upload-container" fxLayout="column" fxLayoutAlign="center center">
                    <img *ngIf="selectedImage" [src]="selectedImage" alt="Selected Image"
                        style="padding: 1%; max-width: 100%; max-height: 100%;">
                    <label *ngIf="!selectedImage" for="fileInput" class="file-upload-label">
                        Choose file on your device
                    </label>
                    <label *ngIf="selectedImage" for="fileInput" class="file-upload-label">
                        Replace Image
                    </label>
                    <input type="file" accept="image/*" (change)="onFileSelected($event)" id="fileInput"
                        class="input-file">
                    <span class="image-name" *ngIf="selectedFileName">{{ selectedFileName }}</span>
                </div>
                <span class="error-message" *ngIf="existsError"> {{errorToShow}} </span>
                <button class="button" mat-raised-button type="submit" [disabled]="this.moduleForm.invalid">SAVE
                    CHANGES</button>
            </form>
        </div>
        <img src="/assets/2.0/AVA_close_icon.svg"
            style="z-index: 1; height: 50px; position:relative; margin: -9% -23% 1% -5%; cursor:pointer;"
            (click)="closePopup()">
    </div>
    <div fxLayout="row" fxLayoutAlign="center" *ngIf="showEditModulePopup" class="lpopup"
        [ngClass.lt-lg]="'popup-smallScreen'">
        <div fxLayout="column" fxLayoutGap="10%" fxFlex="99">
            <span class="med-heading-text-config" style="margin-right:auto;">Enter module details</span>
            <form [formGroup]="moduleForm" (ngSubmit)="editModule()" validate fxLayout="column">
                <mat-label class="non-floating-form-label"> Module Name </mat-label>
                <mat-form-field floatLabel="always">
                    <div class="form-input-field">
                        <input class="form-input-text" matInput type="text" formControlName="moduleName">
                    </div>
                </mat-form-field>
                <mat-label class="non-floating-form-label"> Module Reference Name </mat-label>
                <mat-form-field floatLabel="always">
                    <div class="form-input-field">
                        <input class="form-input-text" matInput type="text" formControlName="refName" readonly>
                    </div>
                </mat-form-field>
                <mat-label class="non-floating-form-label" style=" margin-top:5px;"> Module Modes </mat-label>
                <div class="boolean-options" fxLayout="row wrap">
                    <mat-checkbox fxFlex="50" color="primary" formControlName="hasExplainer">
                        Explainer</mat-checkbox>
                    <mat-checkbox fxFlex="50" color="primary" formControlName="hasPractice">
                        Practice</mat-checkbox>
                    <mat-checkbox fxFlex="50" color="primary" formControlName="hasDemonstration">
                        Demonstration</mat-checkbox>
                    <mat-checkbox fxFlex="50" color="primary" formControlName="hasExam">
                        Exam</mat-checkbox>
                </div>
                <mat-label class="non-floating-form-label" style=" margin-top:5px;"> Cover Image
                    <div fxLayout="row">
                        <span fxFlex="40" style="font-size: small;">512 x 390px (min size)</span>
                        <label fxFlex="30" *ngIf="selectedModuleImage" for="fileInput" class="file-upload-label">Replace
                            Image</label>
                        <button type="button" fxFlex="30" *ngIf="selectedModuleImage" class="file-upload-label"
                            (click)="deleteModuleImage()">Delete Image</button>
                    </div>
                </mat-label>
                <div class="image-upload-container" fxLayout="column" fxLayoutAlign="center center">
                    <img *ngIf="selectedModuleImage" [src]="selectedModuleImage" alt="Select a Module Image"
                        style="padding: 1%; max-width: 100%; max-height: 100%;">
                    <label fxFlex="50" *ngIf="!selectedModuleImage" for="fileInput" class="file-upload-label">
                        Choose file on your device
                    </label>
                    <input type="file" accept="image/*" (change)="onFileSelected($event)" id="fileInput"
                        class="input-file">
                    <span class="image-name" *ngIf="selectedFileName">{{ selectedFileName }}</span>
                </div>
                <span class="error-message" *ngIf="existsError"> {{errorToShow}} </span>

                <button class="button" mat-raised-button type="submit" [disabled]="this.moduleForm.invalid">SAVE
                    CHANGES</button>
            </form>
        </div>
        <img src="/assets/2.0/AVA_close_icon.svg"
            style="z-index: 1; height: 50px; position:relative; margin: -9% -23% 1% -5%; cursor:pointer;"
            (click)="closePopup()">
    </div>
    <div fxLayout="column" [ngClass]="fadeOut? 'main-container pointer-events': 'main-container'"
        [style.opacity]="fadeOut?'.2':null" fxLayoutGap="1%" fxFlexFill>
        <div fxFlex="10%" class="text-config">{{courseName}}</div>
        <div fxFlex="40%" fxLayout="row" class="mid-container" fxLayout.lt-md="column">
            <!-- Image & Details -->
            <div fxFlex="40" style="padding: 0% 4% 0% 0%; position: relative;">
                <img [src]="courseImage ? courseImage : 'assets/2.0/addimage_white.svg'" alt="Course Image"
                    height="100%" width="100%">
                <div style="position: absolute; top: 20px; right: 80px; display: flex; gap: 4px;">
                    <button class="small-buttons" (click)="triggerFileInput()">
                        <mat-icon matTooltip="Edit Course Image">create</mat-icon>
                    </button>
                    <input type="file" #courseFileInput accept="image/*" (change)="replaceCourseImage($event)"
                        style="display: none" />
                    <button class="small-buttons" (click)="deleteCourseImage()">
                        <mat-icon matTooltip="Delete Course Image">delete</mat-icon>
                    </button>
                </div>
            </div>
            <div fxFlex="40" class="form-container">
                <form [formGroup]="courseForm" (ngSubmit)="addModule()" validate fxLayout="column">
                    <div fxLayout="row" style="width:50px; align-self:end; margin-right:25%;" fxLayoutGap="5px">
                        <button class="small-buttons" type="button" (click)="editCourseDetails()" [disabled]="isEdit">
                            <mat-icon matTooltip="Edit Course Details">create</mat-icon>
                        </button>
                        <button class="small-buttons" type="submit" [disabled]="!isEdit" (click)="saveCourseDetails()">
                            <mat-icon matTooltip="Edit Course Details" style="font-weight: 900;">done</mat-icon>
                        </button>
                    </div>
                    <mat-label class="non-floating-label"> Sheet ID </mat-label>
                    <mat-form-field floatLabel="always">
                        <div class="input-field">
                            <input class="input-text" matInput type="text" formControlName="sheetID">
                        </div>
                    </mat-form-field>
                    <mat-label class="non-floating-label"> Database Name </mat-label>
                    <mat-form-field floatLabel="always">
                        <div class="input-field">
                            <input class="input-text" matInput type="text" formControlName="dbname">
                        </div>
                    </mat-form-field>
                    <mat-label class="non-floating-label"> Analytics Endpoint</mat-label>
                    <mat-form-field floatLabel="always">
                        <div class="input-field">
                            <input class="input-text" matInput type="text" formControlName="analytics">
                        </div>
                    </mat-form-field>
                    <mat-label class="non-floating-label"> Access Token </mat-label>
                    <mat-form-field floatLabel="always">
                        <div class="input-field">
                            <input class="input-text" matInput type="text" formControlName="token">
                        </div>
                    </mat-form-field>
                    <mat-label class="non-floating-label"> Content Endpoint</mat-label>
                    <mat-form-field floatLabel="always">
                        <div class="input-field">
                            <input class="input-text" matInput type="text" formControlName="content">
                        </div>
                    </mat-form-field>
                    <mat-label class="non-floating-label"> Number of Modules</mat-label>
                    <mat-form-field floatLabel="always">
                        <div class="input-field">
                            <input class="input-text" matInput type="number" formControlName="numberOfModules">
                        </div>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div fxFlex="50%" fxLayout="column" class="bottom-container" fxLayoutGap="1%" fxLayout.lt-md="column">
            <!-- Modules Table -->
            <div fxLayout="row" class="top-container" fxLayoutAlign="start stretch" fxLayoutGap="60%"
                fxLayout.lt-md="column" fxLayoutGap.lt-md="1%">
                <div class="med-text-config">Modules</div>
                <button class="module-button" mat-raised-button (click)="openModulePopup()">
                    <mat-icon style="font-weight:800;">add</mat-icon> &nbsp; Add Module
                </button>
            </div>
            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start stretch">
                <mat-card class="card-config" fxFlex="20%" [ngClass.lt-md]="'card-column'" fxFlex.md="40%"
                    *ngFor="let module of moduleDetail">
                    <div *ngIf="isLoading" style="margin-left:5%; margin-top: 12%;">
                        <app-skeleton-loading Cwidth="230" Cheight="115">
                        </app-skeleton-loading>
                    </div>
                    <mat-card-title *ngIf="!isLoading" class="card-heading-config"
                        [ngClass.lt-md]="'card-column-heading'">
                        <img [src]="module.img? module.img : 'assets/2.0/addimage_blue.svg'" class="module-image"
                            [ngClass.lt-md]="'column-module-image'" />
                        <div class="small-buttons-container" [ngClass.lt-md]="'column-small-buttons-container'">
                            <button class="small-buttons" (click)="openEditModulePopup(module)" type="button">
                                <mat-icon matTooltip="Edit Module">create</mat-icon>
                            </button>
                            <button class="small-buttons" (click)="deleteModule(module)" type="button">
                                <mat-icon matTooltip="Delete Module">delete</mat-icon>
                            </button>
                        </div>
                    </mat-card-title>
                    <mat-card-content class="card-content-config" [ngClass.lt-md]="'card-column-content'">
                        {{ module.name }}<br />
                        {{ module.ref }}
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</app-main-nav>