<app-main-nav>
  <div class="actual-main-div">
    <div flex *ngIf="popupChart">
      <div class="chart-popup" fxLayout="row">
        <div fxLayout="column" fxFlex="95">
          <canvas id="big-line-chart" height="600" (mousemove)="bigChartHovered($event)" fxFlex="70%"
            *ngIf="!isSARole() && !isKPIRole()">{{bigLineChart}}</canvas>
          <canvas id="kpi-big-line-chart" height="600" (mousemove)="kpiBigChartHovered($event)" fxFlex="70%"
            *ngIf="isSARole() || isKPIRole()">{{kpiBigLineChart}}</canvas>
          <mat-card-content fxFlex="30%">
            <div class="graph-cards" fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayout.lt-lg="row wrap">
              <mat-card class="card-config" fxFlex="25%" fxFlex.lt-lg="50%">
                <mat-card-title class="card-heading-config" style="font-size: 1.2vw;">
                  <img src="/assets/icon/Timer_icon.svg" style="height: 20px; vertical-align: middle;"> &nbsp;
                  <span>Duration</span>
                </mat-card-title>
                <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
                  <app-skeleton-loading Cwidth="200" Cheight="120">
                  </app-skeleton-loading>
                </div>
                <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'"
                  style="font-size: 1vw;">
                  <span *ngIf="graphSessionTime !== undefined">{{graphSessionTime}}</span>
                  <span *ngIf="graphSessionTime === undefined">00H:00m:00s</span>
                </mat-card-content>
                <mat-card-content class="card-secondary-content-config"
                  [ngClass.lt-lg]="'card-column-secondary-content'">
                  <p>session time</p>
                </mat-card-content>
              </mat-card>
              <mat-card class="card-config" fxFlex="25%" fxFlex.lt-lg="50%" *ngIf="isSARole() || isKPIRole()">
                <mat-card-title class="card-heading-config" style="font-size: 1.2vw;">
                  <img src="/assets/icon/Timer_icon.svg" style="height: 20px; vertical-align: middle;"> &nbsp;
                  <span>Timing</span>
                </mat-card-title>
                <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
                  <app-skeleton-loading Cwidth="200" Cheight="120">
                  </app-skeleton-loading>
                </div>
                <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'">
                  <p [ngClass]="targetsMissed <= 0 ? 'positive' : 'negative'">
                    <span *ngIf="targetsMissed !== undefined">{{targetsMissed}}</span>
                    <span *ngIf="targetsMissed === undefined" class="positive">0</span>
                  </p>
                </mat-card-content>
                <mat-card-content class="card-secondary-content-config"
                  [ngClass.lt-lg]="'card-column-secondary-content'">
                  <p>targets missed</p>
                </mat-card-content>
              </mat-card>
              <mat-card class="card-config" fxFlex="25%" fxFlex.lt-lg="50%" *ngIf="isSARole() || isKPIRole()">
                <mat-card-title class="card-heading-config" style="font-size: 1.2vw;">
                  <img src="/assets/icon/Timer_icon.svg" style="height: 20px; vertical-align: middle;"> &nbsp;
                  <span>Hesitation</span>
                </mat-card-title>
                <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
                  <app-skeleton-loading Cwidth="200" Cheight="120">
                  </app-skeleton-loading>
                </div>
                <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'">
                  <p [ngClass]="hesitation <= 0 ? 'positive' : 'negative'">
                    <span *ngIf="hesitation !== undefined">{{hesitation}}</span>
                    <span *ngIf="hesitation === undefined" class="positive">0</span>
                  </p>
                </mat-card-content>
                <mat-card-content class="card-secondary-content-config"
                  [ngClass.lt-lg]="'card-column-secondary-content'">
                  <p>prompts required</p>
                </mat-card-content>
              </mat-card>
              <mat-card class="card-config" fxFlex="25%" fxFlex.lt-lg="50%" *ngIf="isSARole() || isKPIRole()">
                <mat-card-title class="card-heading-config" style="font-size: 1.2vw;">
                  <img src="/assets/icon/Timer_icon.svg" style="height: 20px; vertical-align: middle;"> &nbsp;
                  <span>Movement</span>
                </mat-card-title>
                <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
                  <app-skeleton-loading Cwidth="200" Cheight="120">
                  </app-skeleton-loading>
                </div>
                <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'">
                  <p [ngClass]="speedWarning <= 0 ? 'positive' : 'negative'">
                    <span *ngIf="speedWarning !== undefined">{{speedWarning}}</span>
                    <span *ngIf="speedWarning === undefined" class="positive">0</span>
                  </p>
                </mat-card-content>
                <mat-card-content class="card-secondary-content-config"
                  [ngClass.lt-lg]="'card-column-secondary-content'">
                  <p>speed warnings</p>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card-content>
        </div>
        <img fxFlex="5" src="/assets/2.0/AVA_close_icon.svg"
          style="height: 50px; position:relative; margin: -6% 0%; cursor:pointer;" (click)="closeChartPopup()">
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="stretch" flex fxLayout.lt-lg="column" [style.opacity]="fadeOut?'.2':null"
      [ngClass]="fadeOut? 'main-div pointer-events': 'main-div'">
      <div fxFlex="40" fxLayout="column" fxFlexAlign="stretch">
        <!-- <div class="text-config">Trainee</div> -->
        <div class="text-config">
          <span *ngIf="userRole !='VIS'"
            [ngClass]="userStatus === 'Active' ? 'positive' : userStatus === 'Inactive' ? 'neutral' : 'negative'">
            <mat-icon style="transform: scale(2);">fiber_manual_record</mat-icon>
          </span>
          {{name}}
        </div>
        <br>
        <div fxLayout="row" fxLayout.lt-lg="row" flex fxLayoutGap="10%">
          <div class="label-config" fxFlex="40" fxFlex.lt-lg="70" fxFlex.md="80">
            <p>Completion</p>
            <p>Total Sessions</p>
            <p>Sessions Duration</p>
            <p>Start Date</p>
          </div>
          <div class="data-text-config" fxFlex="40" fxFlex.lt-lg="30" fxFlex.md="20">
            <p>{{completion}}%</p>
            <p>{{totalSessions}}</p>
            <p>{{totalDuration}}</p>
            <p>{{startDate}}</p>
          </div>
        </div>
        <br><br><br><br>
        <div class="table-config" *ngIf="isSARole()">
          <a style="background-color: #021B36;">
            <div *ngFor="let course of courses">
              <mat-expansion-panel style="background-color: #021B36;" #panel (click)="selectCourse(course)"
                (mouseleave)="panel.close()">
                <mat-expansion-panel-header class="table-header-font">
                  <div class="medium-white-font">
                    <mat-icon mat-list-icon>keyboard_arrow_down</mat-icon><span>{{ course.CourseName }}</span>
                  </div>
                </mat-expansion-panel-header>
                <mat-table [dataSource]="dataSource" hideHeader>
                  <ng-container matColumnDef="Modules">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Modules </mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Modules}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Progress">
                    <mat-header-cell class="table-header-font" *matHeaderCellDef> Progress</mat-header-cell>
                    <mat-cell class="table-data-font" *matCellDef="let row" style="margin-left:30px;">
                      <div *ngIf="row.progress == 100">
                        <img src="/assets/2.0/AVA_tick_icon.svg" style="height: 35px;">
                      </div>
                      <div *ngIf="row.progress != 100">
                        <circle-progress [percent]="row.progress" [radius]="15" space="-3" outerStrokeColor="#23865a"
                          innerStrokeColor="#021B36" outerStrokeWidth="5" innerStrokeWidth="1" animationDuration="600"
                          [showSubtitle]="false" [showBackground]="false" [showTitle]="false"
                          [showZeroOuterStroke]="false" [showUnits]="false"></circle-progress>
                      </div>

                    </mat-cell>
                  </ng-container>

                  <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="open(row)"
                    [ngClass]="{'highlight': selectedRow == row ? row : null}"></mat-row>
                </mat-table>
                <div *ngIf="isLoading" style="margin-left:2%; margin-top: 0.5%;">
                  <div *ngFor="let _ of [].constructor(3)">
                    <app-skeleton-loading Cwidth="450" Cheight="48"><br><br>
                    </app-skeleton-loading>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
          </a>
        </div>
        <div class="table-config" *ngIf="!isSARole()">
          <mat-table [dataSource]="dataSource" hideHeader>
            <ng-container matColumnDef="Modules">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Modules </mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Modules}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Progress">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Progress</mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row" style="margin-left:30px;">
                <div *ngIf="row.progress == 100">
                  <img src="/assets/2.0/AVA_tick_icon.svg" style="height: 35px;">
                </div>
                <div *ngIf="row.progress != 100">
                  <circle-progress [percent]="row.progress" [radius]="15" space="-3" outerStrokeColor="#23865a"
                    innerStrokeColor="#021B36" outerStrokeWidth="5" innerStrokeWidth="1" animationDuration="600"
                    [showSubtitle]="false" [showBackground]="false" [showTitle]="false" [showZeroOuterStroke]="false"
                    [showUnits]="false"></circle-progress>
                </div>

              </mat-cell>
            </ng-container>
            <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="open(row)"
              [ngClass]="{'highlight': selectedRow == row ? row : null}"></mat-row>
          </mat-table>
          <div *ngIf="isLoading" style="margin-left:2%; margin-top: 0.5%;">
            <div *ngFor="let _ of [].constructor(3)">
              <app-skeleton-loading Cwidth="450" Cheight="48"><br><br>
              </app-skeleton-loading>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showDetailsPanel" class="secondary-container" fxFlex="60" fxFlex.lt-lg="100" fxFlexAlign="stretch">
        <!-- <div fxLayout="row">
                    <div class="text-config" style="font-size: 35px; padding: 2%; margin-left: 1%;" fxFlex="85">{{selectedModuleFullName}}</div>
                    <img src="/assets/2.0/AVA_close_icon.svg" style="height: 50px; cursor:pointer;" (click)="close()" fxFlex="15">
                </div> -->
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayout.lt-lg="row wrap">
          <mat-card class="card-config" fxFlex="33.3%" fxFlex.lt-lg="100%">
            <mat-card-title class="card-heading-config">
              <img src="/assets/icon/dashboard_duration_icon.svg" style="height: 30px; vertical-align: middle;"> &nbsp;
              <span>Improvement</span>
            </mat-card-title>
            <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
              <app-skeleton-loading Cwidth="200" Cheight="120">
              </app-skeleton-loading>
            </div>
            <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'">
              <p [ngClass]="improvement >= 0 ? 'positive' : 'negative'">
                <span *ngIf="improvement > 0">+</span>{{improvement}}%
              </p>
            </mat-card-content>
            <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg]="'card-column-secondary-content'">
              <p>since 1st session</p>
            </mat-card-content>
          </mat-card>
          <mat-card class="card-config" fxFlex="33.3%" fxFlex.lt-lg="100%">
            <mat-card-title class="card-heading-config">
              <img src="/assets/icon/dashboard_sessions_icon.svg" style="height: 30px; vertical-align: middle;"> &nbsp;
              <span>Ranking</span>
            </mat-card-title>
            <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
              <app-skeleton-loading Cwidth="200" Cheight="120">
              </app-skeleton-loading>
            </div>
            <mat-card-content class="card-content-config"
              [ngClass.lt-lg]="'card-content-config-ltlg'">{{ranking}}</mat-card-content>
            <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg]="'card-column-secondary-content'">
              <p>in Leaderboard</p>
            </mat-card-content>
          </mat-card>
          <mat-card class="card-config" fxFlex="33.3%" fxFlex.lt-lg="100%">
            <mat-card-title class="card-heading-config">
              <img src="/assets/icon/dashboard_outcomes_icon.svg" style="height: 30px; vertical-align: middle;"> &nbsp;
              <span *ngIf="!isSARole() && !isKPIRole()">Best
                Time</span>
              <span *ngIf="isSARole() || isKPIRole()">Top Score</span>
            </mat-card-title>
            <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
              <app-skeleton-loading Cwidth="200" Cheight="120">
              </app-skeleton-loading>
            </div>
            <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'">
              <span *ngIf="(!isSARole() && !isKPIRole()) && bestWhatever == 0">00:00:00</span>
              <span *ngIf="(isSARole() || isKPIRole()) && bestWhatever == 0">0</span>
              <span *ngIf="bestWhatever != 0">{{bestWhatever}}</span>
            </mat-card-content>
            <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg]="'card-column-secondary-content'">
              <p>of all time</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div>
          <mat-card style="cursor: -webkit-zoom-in; cursor: zoom-in;" class="card-config" fxFlex="100%"
            fxFlex.lt-lg="100%" (click)="openChartPopup()">
            <mat-card-title class="card-heading-config" style="padding:1%">
              <span>Performance</span>
            </mat-card-title>
            <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 5%;">
              <app-skeleton-loading Cwidth="600" Cheight="200">
              </app-skeleton-loading>
            </div>
            <mat-card-content ngClass.lt-lg="pointer-events">
              <mat-card-content class="chart-config">
                <canvas id="line-chart" height="300" fxFlex="98%" (mousemove)="chartHovered($event)"
                  *ngIf="!isSARole() && !isKPIRole()">{{lineChart}}</canvas>
              </mat-card-content>
              <mat-card-content class="chart-config">
                <canvas id="kpi-line-chart" height="250" fxFlex="98%" (mousemove)="kpiChartHovered($event)"
                  *ngIf="isSARole() || isKPIRole()">{{kpiLineChart}}</canvas>
              </mat-card-content>
            </mat-card-content>
            <mat-card-content>
              <div class="graph-cards" style="margin-top: -4% !important;" fxLayout="row wrap"
                fxLayoutAlign="start stretch" fxLayout.lt-lg="row wrap">
                <mat-card class="card-config" fxFlex="25%" fxFlex.lt-lg="50%">
                  <mat-card-title class="card-heading-config" style="font-size: 1.1vw;">
                    <img src="/assets/icon/Timer_icon.svg" style="height: 20px; vertical-align: middle;"> &nbsp;
                    <span>Duration</span>
                  </mat-card-title>
                  <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
                    <app-skeleton-loading Cwidth="200" Cheight="120">
                    </app-skeleton-loading>
                  </div>
                  <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'"
                    style="font-size: 1vw;">
                    <span *ngIf="graphSessionTime !== undefined">{{graphSessionTime}}</span>
                    <span *ngIf="graphSessionTime === undefined">00H:00m:00s</span>
                  </mat-card-content>
                  <mat-card-content class="card-secondary-content-config"
                    [ngClass.lt-lg]="'card-column-secondary-content'">
                    <p>session time</p>
                  </mat-card-content>
                </mat-card>
                <mat-card class="card-config" fxFlex="25%" fxFlex.lt-lg="50%" *ngIf="isSARole() || isKPIRole()">
                  <mat-card-title class="card-heading-config" style="font-size: 1.1vw;">
                    <img src="/assets/kpi/timing_icon.svg" style="height: 22px; vertical-align: middle;"> &nbsp;
                    <span>Timing</span>
                  </mat-card-title>
                  <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
                    <app-skeleton-loading Cwidth="200" Cheight="120">
                    </app-skeleton-loading>
                  </div>
                  <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'">
                    <p [ngClass]="targetsMissed <= 0 ? 'positive' : 'negative'">
                      <span *ngIf="targetsMissed !== undefined">{{targetsMissed}}</span>
                      <span *ngIf="targetsMissed === undefined" class="positive">0</span>
                    </p>
                  </mat-card-content>
                  <mat-card-content class="card-secondary-content-config"
                    [ngClass.lt-lg]="'card-column-secondary-content'">
                    <p>targets missed</p>
                  </mat-card-content>
                </mat-card>
                <mat-card class="card-config" fxFlex="25%" fxFlex.lt-lg="50%" *ngIf="isSARole() || isKPIRole()">
                  <mat-card-title class="card-heading-config" style="font-size: 1.1vw;">
                    <img src="/assets/kpi/hesitation_icon.svg" style="height: 21px; vertical-align: middle;"> &nbsp;
                    <span>Hesitation</span>
                  </mat-card-title>
                  <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
                    <app-skeleton-loading Cwidth="200" Cheight="120">
                    </app-skeleton-loading>
                  </div>
                  <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'">
                    <p [ngClass]="hesitation <= 0 ? 'positive' : 'negative'">
                      <span *ngIf="hesitation !== undefined">{{hesitation}}</span>
                      <span *ngIf="hesitation === undefined" class="positive">0</span>
                    </p>
                  </mat-card-content>
                  <mat-card-content class="card-secondary-content-config"
                    [ngClass.lt-lg]="'card-column-secondary-content'">
                    <p>prompts required</p>
                  </mat-card-content>
                </mat-card>
                <mat-card class="card-config" fxFlex="25%" fxFlex.lt-lg="50%" *ngIf="isSARole() || isKPIRole()">
                  <mat-card-title class="card-heading-config" style="font-size: 1.1vw;">
                    <img src="/assets/kpi/movement_icon.svg" style="height: 20px; vertical-align: middle;"> &nbsp;
                    <span>Movement</span>
                  </mat-card-title>
                  <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 8%;">
                    <app-skeleton-loading Cwidth="200" Cheight="120">
                    </app-skeleton-loading>
                  </div>
                  <mat-card-content class="card-content-config" [ngClass.lt-lg]="'card-content-config-ltlg'">
                    <p [ngClass]="speedWarning <= 0 ? 'positive' : 'negative'">
                      <span *ngIf="speedWarning !== undefined">{{speedWarning}}</span>
                      <span *ngIf="speedWarning === undefined" class="positive">0</span>
                    </p>
                  </mat-card-content>
                  <mat-card-content class="card-secondary-content-config"
                    [ngClass.lt-lg]="'card-column-secondary-content'">
                    <p>speed warnings</p>
                  </mat-card-content>
                </mat-card>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="step-table-config">
          <mat-table [dataSource]="dataSourceStep">
            <ng-container matColumnDef="Step">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Step </mat-header-cell>
              <mat-cell class="step-table-data-font" *matCellDef="let row"> {{row.Step}} </mat-cell>
              <mat-footer-cell *matFooterCellDef> TOTALS </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="BestTime">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Best Time </mat-header-cell>
              <mat-cell class="step-table-data-font" *matCellDef="let row">
                <div *ngIf="row.BestTime!='00:00:00' && row.BestTime <= row.Target">
                  <img src="/assets/2.0/AVA_tick_icon.svg" style="height: 25px;"> &nbsp; {{row.BestTime=="00:00:00"?
                  '--:--:--': row.BestTime}}
                </div>
                <div *ngIf="row.BestTime=='00:00:00' || row.BestTime > row.Target">
                  <img src="/assets/2.0/AVA_incomplete_icon.svg" style="height: 30px; margin-left: -2px;"> &nbsp;
                  {{row.BestTime=="00:00:00"? '--:--:--': row.BestTime}}
                </div>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>
                <div *ngIf="bestTimeTotal <= targetTotal" fxLayout="row">
                  <img src="/assets/2.0/AVA_tick_icon.svg" style="height: 25px;"> &nbsp; <span>{{bestTimeTotal=="0"?
                    '--:--:--': bestTimeTotal}} </span>
                </div>
                <div *ngIf="bestTimeTotal > targetTotal" fxLayout="row">
                  <img src="/assets/2.0/AVA_incomplete_icon.svg" style="height: 25px;"> &nbsp;
                  <span>{{bestTimeTotal=="0"? '--:--:--': bestTimeTotal}} </span>
                </div>
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="AVG">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> AVG </mat-header-cell>
              <mat-cell class="step-table-data-font" *matCellDef="let row"> {{row.AVG}} </mat-cell>
              <mat-footer-cell *matFooterCellDef> {{avgTotal=="0"? '--:--:--': avgTotal}} </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Target">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Target </mat-header-cell>
              <mat-cell class="step-table-data-font" *matCellDef="let row"> {{row.Target}} </mat-cell>
              <mat-footer-cell *matFooterCellDef> {{targetTotal=="0"? '--:--:--': targetTotal}} </mat-footer-cell>
            </ng-container>

            <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumnsSteps"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsSteps;"></mat-row>
            <mat-footer-row *matFooterRowDef="displayedColumnsSteps; sticky: true"></mat-footer-row>
          </mat-table>
          <div *ngIf="isModuleLoading" style="margin-left:2%; margin-top: 0.5%;">
            <div *ngFor="let _ of [].constructor(8)">
              <app-skeleton-loading Cwidth="600" Cheight="45"><br><br>
              </app-skeleton-loading>
            </div>
          </div>
        </div>
        <div class="step-table-config"
          *ngIf="isSARole() || isKPIRole() || ((userRole=='RA') && (selectedModuleName=='M1' || selectedModuleName=='M2'))">
          <mat-card class="card-config" fxFlex="100%" fxFlex.lt-lg="100%">
            <mat-card-title class="card-heading-config" style="padding:1%">
              <span>Examination</span>
            </mat-card-title>
            <div *ngIf="isModuleLoading" style="margin-left:5%; margin-top: 5%;">
              <app-skeleton-loading Cwidth="600" Cheight="200">
              </app-skeleton-loading>
            </div>
            <mat-card-content ngClass.lt-lg="pointer-events">
              <mat-card-content class="chart-config">
                <canvas id="bar-chart" height="150" fxFlex="98%">{{barChart}}</canvas>
              </mat-card-content>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</app-main-nav>