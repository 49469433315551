import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import {HttpService} from '../../service/http.service';
// import { SharedService } from '../../service/shared.service';

@Component({
  selector: 'app-trainees',
  templateUrl: './trainees.component.html',
  styleUrls: ['./trainees.component.scss']
})
export class TraineesComponent{
  scoreID = null;
  courseName = null;
  trainingSessionData: Array<any>;
  modulesData: Array<any>;
  examData: Array<any>;
  personalData: Array<any>;
  userRole;

  constructor(
    private router: Router,
    // private _httpService: HttpService,
    // private _sharedService: SharedService
  ) {
      if(this.router.getCurrentNavigation().extras.state){
        this.scoreID = this.router.getCurrentNavigation().extras.state.scoreID;
        this.courseName = this.router.getCurrentNavigation().extras.state.courseName;
      }
   }

  // ngOnInit() {
  //   if(this._sharedService.getRole() == "VIS"){
  //     this.userRole = "VIS_CASE";
  //   }

  //   // console.log("this.courseName: ", this.courseName);
  //   if(this.courseName == "Robotics"){
  //     this.userRole = "Robotics";
  //     this._httpService.getFIT001DataUser({"scoreID" : this.scoreID})
  //     .subscribe(data=>{
  //       // console.log(data);
  //       this.personalData = data.personalData;
  //       this.trainingSessionData = data.trainingSessionData;
  //       this.modulesData = data.modulesData;
  //       this.examData = data.examData;
  //     }, error => {
  //       // console.log("Trainees Error");
  //       this.dummyValues();
  //     })

  //   }else if(this.courseName == "PLC"){
  //     this.userRole = "PLC";      
  //     this._httpService.getFIT002DataUser({"scoreID" : this.scoreID})
  //     .subscribe(data=>{
  //       // console.log(data);
  //       this.personalData = data.personalData;
  //       this.trainingSessionData = data.trainingSessionData;
  //       this.modulesData = data.modulesData;
  //       this.examData = data.examData;
  //     }, error => {
  //       // console.log("Trainees Error");
  //       this.dummyValues();
  //     })

  //   }else if(this.courseName == "VIS_CASE"){
  //     this.userRole = "VIS_CASE";
  //     this._httpService.getVIS001DataUser({"scoreID" : this.scoreID})
  //     .subscribe(data=>{
  //       // console.log(data);
  //       this.personalData = data.personalData;
  //       this.trainingSessionData = data.trainingSessionData;
  //       this.modulesData = data.modulesData;
  //       // this.examData = data.examData;
  //     }, error => {
  //       // console.log("Trainees Error");
  //       this.dummyValues();
  //     })

  //   }else if(this.courseName == "KWETB_Robotics"){
  //     this.userRole = "KWETB_Robotics";
  //     this._httpService.getKWETB001DataUser({"scoreID" : this.scoreID})
  //     .subscribe(data=>{
  //       // console.log(data);
  //       this.personalData = data.personalData;
  //       this.trainingSessionData = data.trainingSessionData;
  //       this.modulesData = data.modulesData;
  //       this.examData = data.examData;
  //     }, error => {
  //       // console.log("Trainees Error");
  //       this.dummyValues();
  //     })

  //   }else if(this.courseName == "KWETB_PLC"){
  //     this.userRole = "KWETB_PLC";      
  //     this._httpService.getKWETB002DataUser({"scoreID" : this.scoreID})
  //     .subscribe(data=>{
  //       // console.log(data);
  //       this.personalData = data.personalData;
  //       this.trainingSessionData = data.trainingSessionData;
  //       this.modulesData = data.modulesData;
  //       this.examData = data.examData;
  //     }, error => {
  //       // console.log("Trainees Error");
  //       this.dummyValues();
  //     })

  //   }else if(this.courseName == "BOS"){
  //     this.userRole = "BOS";
  //     this._httpService.getBOS002DataUser({"scoreID" : this.scoreID})
  //     .subscribe(data=>{
  //       // console.log(data);
  //       this.personalData = data.personalData;
  //       this.trainingSessionData = data.trainingSessionData;
  //       this.modulesData = data.modulesData;
  //       // this.examData = data.examData;
  //     }, error => {
  //       // console.log("Trainees Error");
  //       this.dummyValues();
  //     })

  //   }
  // }

  dummyValues(){
    this.personalData = [
      { param: 'Name', val: null},
      { param: 'Email', val: null},
      { param: 'Last Session', val: null},
      { param: 'Total Session', val: null},
      { param: 'Passed Training', val: null},
      { param: 'Passed Exam', val: null},
    ];
    this.trainingSessionData = [
      { param: 'Date', val: null},
      { param: 'Course', val: null},
      { param: 'Company', val: null},
      { param: 'Start', val: null},
      { param: 'End', val: null},
      { param: 'Total Time', val: null}
    ];
    this.modulesData = [
      { param: 'Startup', val: null},
      { param: 'Joint Jog', val: null},
      { param: 'World Jog', val: null},
      { param: 'Tool Jog', val: null},
      { param: 'Washer Positioning', val: null},
      { param: 'Washer Transport', val: null},
      { param: 'Measurement Gauge', val: null}
    ];
    this.examData = [
      { param: 'Total Questions', val: null},
      { param: 'Correct answers', val: null},
      { param: 'Time bonus', val: null},
      { param: 'Overall score', val: null},
      { param: 'Grading', val: null}
    ];
  }


}
