import { IfStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';

export interface Course {
  DBName: string;
  CourseName: string;
  NumberOfModules: number;
  CourseID: string;
  Data: ModuleData[];
}

export interface ModuleData {
  Modules: string;
  Explainer: string;
  Practice: string;
  Exam: string;
  Demonstration: string;
  moduleName: string;
}

@Component({
  selector: 'app-trainees-details-v2',
  templateUrl: './trainees-details-v2.component.html',
  styleUrls: ['./trainees-details-v2.component.scss']
})
export class TraineesDetailsV2Component implements OnInit {
  SingleAppRoles = ['AVA', 'AVAT', 'AVAP'];
  KPIRoles = ['JAN', 'DEP']
  userRole;
  userType;
  name;
  userID;
  totalSessions;
  totalDuration;
  modulesCompleted;

  showDetailsPanel = false;
  selectedRow;
  selectedModuleName;
  selectedModuleFullName;
  dataSource;
  dataSourceStep;
  moduleExamPercent;
  moduleDuration;
  moduleSessions;

  bestTimeTotal = '--:--:--';
  avgTotal = '--:--:--';
  targetTotal;
  isLoading = true;
  isModuleLoading = true;
  lineChart;
  kpiLineChart;
  bigLineChart;
  kpiBigLineChart;
  popupChart = false;
  fadeOut = false;
  userStatus = 'Active';
  improvement;
  ranking;
  bestWhatever;
  completion;
  startDate;
  graphSessionTime = "00H:00m:00s";
  graphSessionTimeArray;
  graphSpeedFaultArray;
  graphHesitationFaultArray;
  graphTimeFaultArray;
  graphScoreArray;
  targetsMissed = 0;
  hesitation = 0;
  speedWarning = 0;
  barChart;
  courses: Course[];
  selectedCourse;

  displayedColumns = ['Modules', 'Progress'];
  displayedColumnsSteps = ['Step', 'BestTime', 'AVG', 'Target']

  requestObject;

  constructor(
    private router: Router,
    private _httpService: HttpService,
    private _sharedService: SharedService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.name = this.router.getCurrentNavigation().extras.state.name;
      this.userID = this.router.getCurrentNavigation().extras.state.userID;
      this.userStatus = this.router.getCurrentNavigation().extras.state.status;
      localStorage.setItem('userid', this.userID);
      localStorage.setItem('name', this.name);
    }
  }

  ngOnInit() {
    this.userID = localStorage.getItem('userid');
    this.name = localStorage.getItem('name');
    this._httpService.getAuthentication()
      .subscribe(data => {
        this.userType = data.userType;
        this.userRole = data.role;

        if (this.SingleAppRoles.includes(this.userRole)) {
          this._httpService.getSATraineesModuleStatus(this.userRole, this.userID).subscribe(data => {
            this.totalSessions = data.totalSessions;
            this.totalDuration = data.totalSessionDuration;
            this.modulesCompleted = data.totalModulesCompleted;
            this.completion = data.completionPercent;
            this.startDate = data.startDate;
            //this.dataSource = new MatTableDataSource(data.tableData);
            this.courses = data.Courses;
            this.dataSource = data.Courses[0].Data;
            this.selectedCourse = data.Courses[0];
            this.calculateProgress();
            if (this.dataSource.length > 0) {
              this.open(this.dataSource[0]);
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            //console.log("Trainees Modules Status Error", error);
          })
        }
        //#region Non Single App Roles
        if (this.userRole == "VIS") {
          this._httpService.getTraineesModuleStatus('VIS2', this.userID).subscribe(data => {
            this.totalSessions = data.totalSessions;
            this.totalDuration = data.totalSessionDuration;
            this.modulesCompleted = data.totalModulesCompleted;
            this.completion = data.completionPercent;
            this.startDate = data.startDate;
            //this.dataSource = new MatTableDataSource(data.tableData);
            this.dataSource = data.tableData;
            this.calculateProgress();
            if (this.dataSource.length > 0) {
              this.open(this.dataSource[0]);
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            //console.log("Trainees Modules Status Error", error);
          })
        }
        if (this.userRole == "BOS") {
          this._httpService.getTraineesModuleStatus('BOS3', this.userID).subscribe(data => {
            this.totalSessions = data.totalSessions;
            this.totalDuration = data.totalSessionDuration;
            this.modulesCompleted = data.totalModulesCompleted;
            this.completion = data.completionPercent;
            this.startDate = data.startDate;
            //this.dataSource = new MatTableDataSource(data.tableData);
            this.dataSource = data.tableData;
            this.calculateProgress();
            if (this.dataSource.length > 0) {
              this.open(this.dataSource[0]);
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            //console.log("Trainees Modules Status Error", error);
          })
        }
        if (this.userRole == "FPT") {
          this._httpService.getTraineesModuleStatus('FPT', this.userID).subscribe(data => {
            this.totalSessions = data.totalSessions;
            this.totalDuration = data.totalSessionDuration;
            this.modulesCompleted = data.totalModulesCompleted;
            this.completion = data.completionPercent;
            this.startDate = data.startDate;
            //this.dataSource = new MatTableDataSource(data.tableData);
            this.dataSource = data.tableData;
            this.calculateProgress();
            if (this.dataSource.length > 0) {
              this.open(this.dataSource[0]);
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            //console.log("Trainees Modules Status Error", error);
          })
        }
        if (this.userRole == "JAN") {
          this._httpService.getTraineesModuleStatus('JAN', this.userID).subscribe(data => {
            this.totalSessions = data.totalSessions;
            this.totalDuration = data.totalSessionDuration;
            this.modulesCompleted = data.totalModulesCompleted;
            this.completion = data.completionPercent;
            this.startDate = data.startDate;
            //this.dataSource = new MatTableDataSource(data.tableData);
            this.dataSource = data.tableData;
            this.calculateProgress();
            if (this.dataSource.length > 0) {
              this.open(this.dataSource[0]);
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            //console.log("Trainees Modules Status Error", error);
          })
        }
        if (this.userRole == "DEP") {
          this._httpService.getTraineesModuleStatus('DEP', this.userID).subscribe(data => {
            this.totalSessions = data.totalSessions;
            this.totalDuration = data.totalSessionDuration;
            this.modulesCompleted = data.totalModulesCompleted;
            this.completion = data.completionPercent;
            this.startDate = data.startDate;
            //this.dataSource = new MatTableDataSource(data.tableData);
            this.dataSource = data.tableData;
            this.calculateProgress();
            if (this.dataSource.length > 0) {
              this.open(this.dataSource[0]);
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            //console.log("Trainees Modules Status Error", error);
          })
        }
        if (this.userRole == "RA") {
          this._httpService.getTraineesModuleStatus('RA', this.userID).subscribe(data => {
            this.totalSessions = data.totalSessions;
            this.totalDuration = data.totalSessionDuration;
            this.modulesCompleted = data.totalModulesCompleted;
            this.completion = data.completionPercent;
            this.startDate = data.startDate;
            //this.dataSource = new MatTableDataSource(data.tableData);
            this.dataSource = data.tableData;
            this.calculateProgress();
            if (this.dataSource.length > 0) {
              this.open(this.dataSource[0]);
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            //console.log("Trainees Modules Status Error", error);
          })
        }
        //#endregion
      }, error => {
        if (error.status == 401) {
          this.router.navigateByUrl("/Logout", { state: { error: '401' } });
        }
      }
      )
  }

  close() {
    this.showDetailsPanel = false;
    this.selectedRow = null;
  }

  open(rowData) {
    this.selectedRow = rowData;
    this.selectedModuleName = rowData.moduleName;
    this.showDetailsPanel = true;
    this.selectedModuleFullName = rowData.Modules;

    this.requestObject = {
      "moduleName": this.selectedModuleName,
      "userID": this.userID
    }
    if (this.SingleAppRoles.includes(this.userRole)) {
      this._httpService.getSATraineesModuleDetails(this.userRole, this.userID, this.selectedModuleName, this.selectedCourse.CourseID).subscribe(data => {
        this.moduleDuration = data.duration;
        this.moduleSessions = data.sessions;
        this.moduleExamPercent = data.examPercent;
        this.practiceKPIGraphConfig(data.score, data.practiceHistorySessions);
        this.graphSessionTimeArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.practiceHistoryTime[index];
          return acc;
        }, {});
        this.graphSpeedFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.speedFaults[index];
          return acc;
        }, {});
        this.graphHesitationFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.hesitationFaults[index];
          return acc;
        }, {});
        this.graphTimeFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.timeFaults[index];
          return acc;
        }, {});

        this.dataSourceStep = new MatTableDataSource(data.stepsTableData);

        this.bestTimeTotal = data.bestTimeTotal;
        this.avgTotal = data.avgTimeTotal;
        this.targetTotal = data.targetTimeTotal;

        this.bestWhatever = data.topScore;

        this.examHistoryGraphConfig(data.examHistoryScore, data.examHistorySessions);
        this.isModuleLoading = false;
      }, error => {
        this.isModuleLoading = false;
        //console.log("Trainees Module Details Error", error);
      })
      this._httpService.getSATraineesModuleOverview(this.selectedCourse.CourseID, this.selectedModuleName, this.userID).subscribe(data => {
        this.improvement = data.improvement;
      })
      var tempData = [];

      const body = { "org": this.userRole, "courseID": this.selectedCourse.CourseID, "moduleName": this.selectedModuleName };
      this._httpService.getSALeaderboardData(body).subscribe(data => {
        if (data != 'No Data') {
          for (let key in data) {
            tempData.push(data[key]);
          } this.sortRowsDesc('BestScore', tempData);
          var rankAssign = 1;
          let foundUser = false;
          tempData.forEach(element => {
            element.Rank = rankAssign++;

            if (!foundUser && this.userID === element.userID) {
              this.ranking = element.Rank;
              foundUser = true;
            }
          });
          if (!foundUser) this.ranking = 0;
        }
        else {
          this.ranking = 0;
        }
      }, error => {
        //console.log("LeaderBoard  Error", error);
      })
    }
    //#region Non Single App Roles
    if (this.userRole == 'VIS') {
      this._httpService.getTraineesModuleDetails('VIS2', this.userID, this.selectedModuleName).subscribe(data => {
        this.moduleDuration = data.duration;
        this.moduleSessions = data.sessions;
        this.moduleExamPercent = data.examPercent;
        this.practiceHistoryGraphConfig(data.practiceHistorySeconds, data.practiceHistorySessions, data.practiceHistoryTargetSeconds);
        this.graphSessionTimeArray = data.practiceHistorySeconds.reduce((acc, key, index) => {
          acc[key] = data.practiceHistoryTime[index - 1];
          return acc;
        }, {});
        this.dataSourceStep = new MatTableDataSource(data.stepsTableData);

        this.bestTimeTotal = data.bestTimeTotal;
        this.avgTotal = data.avgTimeTotal;
        this.targetTotal = data.targetTimeTotal;

        this.bestWhatever = data.bestTimeTotal;
        this.isModuleLoading = false;
      }, error => {
        this.isModuleLoading = false;
        //console.log("Trainees Module Details Error", error);
      })
      this._httpService.getTraineesModuleOverview('VIS2', this.userID, this.selectedModuleName).subscribe(data => {
        this.improvement = data.improvement;
      })
      var tempData = [];
      this._httpService.getLeaderboardData('VIS2', this.selectedModuleName).subscribe(data => {
        if (data != 'No Data') {
          for (let key in data) {
            tempData.push(data[key]);
          } this.sortRows('BestTime', tempData);
          var rankAssign = 1;
          let foundUser = false;
          tempData.forEach(element => {
            element.Rank = rankAssign++;

            if (!foundUser && this.userID === element.userID) {
              this.ranking = element.Rank;
              foundUser = true;
            }
          });
          if (!foundUser) this.ranking = 0;
        }
        else {
          this.ranking = 0;
        }
      }, error => {
        //console.log("LeaderBoard  Error", error);
      })
    }
    if (this.userRole == 'BOS') {
      this._httpService.getTraineesModuleDetails('BOS3', this.userID, this.selectedModuleName).subscribe(data => {
        this.moduleDuration = data.duration;
        this.moduleSessions = data.sessions;
        this.moduleExamPercent = data.examPercent;
        this.practiceHistoryGraphConfig(data.practiceHistorySeconds, data.practiceHistorySessions, data.practiceHistoryTargetSeconds);
        this.graphSessionTimeArray = data.practiceHistorySeconds.reduce((acc, key, index) => {
          acc[key] = data.practiceHistoryTime[index - 1];
          return acc;
        }, {});
        this.dataSourceStep = new MatTableDataSource(data.stepsTableData);

        this.bestTimeTotal = data.bestTimeTotal;
        this.avgTotal = data.avgTimeTotal;
        this.targetTotal = data.targetTimeTotal;

        this.bestWhatever = data.bestTimeTotal;
        this.isModuleLoading = false;
      }, error => {
        this.isModuleLoading = false;
        //console.log("Trainees Module Details Error", error);
      })
      this._httpService.getTraineesModuleOverview('BOS3', this.userID, this.selectedModuleName).subscribe(data => {
        this.improvement = data.improvement;
      })
      var tempData = [];
      this._httpService.getLeaderboardData('BOS3', this.selectedModuleName).subscribe(data => {
        if (data != 'No Data') {
          for (let key in data) {
            tempData.push(data[key]);
          } this.sortRows('BestTime', tempData);
          var rankAssign = 1;
          let foundUser = false;
          tempData.forEach(element => {
            element.Rank = rankAssign++;

            if (!foundUser && this.userID === element.userID) {
              this.ranking = this.getOrdinalSuffix(element.Rank);
              foundUser = true;
            }
          });
          if (!foundUser) this.ranking = this.getOrdinalSuffix(0);
        }
        else {
          this.ranking = 0;
        }
      }, error => {
        //console.log("LeaderBoard  Error", error);
      })
    }
    if (this.userRole == 'FPT') {
      this._httpService.getTraineesModuleDetails('FPT', this.userID, this.selectedModuleName).subscribe(data => {
        this.moduleDuration = data.duration;
        this.moduleSessions = data.sessions;
        this.moduleExamPercent = data.examPercent;
        this.practiceHistoryGraphConfig(data.practiceHistorySeconds, data.practiceHistorySessions, data.practiceHistoryTargetSeconds);
        this.graphSessionTimeArray = data.practiceHistorySeconds.reduce((acc, key, index) => {
          acc[key] = data.practiceHistoryTime[index - 1];
          return acc;
        }, {});
        this.dataSourceStep = new MatTableDataSource(data.stepsTableData);

        this.bestTimeTotal = data.bestTimeTotal;
        this.avgTotal = data.avgTimeTotal;
        this.targetTotal = data.targetTimeTotal;

        this.bestWhatever = data.bestTimeTotal;

        this.isModuleLoading = false;
      }, error => {
        this.isModuleLoading = false;
        //console.log("Trainees Module Details Error", error);
      })
      this._httpService.getTraineesModuleOverview('FPT', this.userID, this.selectedModuleName).subscribe(data => {
        this.improvement = data.improvement;
      })
      var tempData = [];
      this._httpService.getLeaderboardData('FPT', this.selectedModuleName).subscribe(data => {
        if (data != 'No Data') {
          for (let key in data) {
            tempData.push(data[key]);
          } this.sortRows('BestTime', tempData);
          var rankAssign = 1;
          let foundUser = false;
          tempData.forEach(element => {
            element.Rank = rankAssign++;

            if (!foundUser && this.userID === element.userID) {
              this.ranking = element.Rank;
              foundUser = true;
            }
          });
          if (!foundUser) this.ranking = 0;
        }
        else {
          this.ranking = 0;
        }
      }, error => {
        //console.log("LeaderBoard  Error", error);
      })
    }
    if (this.userRole == 'JAN') {
      this._httpService.getTraineesModuleDetails('JAN', this.userID, this.selectedModuleName).subscribe(data => {
        this.moduleDuration = data.duration;
        this.moduleSessions = data.sessions;
        this.moduleExamPercent = data.examPercent;
        this.practiceKPIGraphConfig(data.score, data.practiceHistorySessions);
        this.graphSessionTimeArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.practiceHistoryTime[index];
          return acc;
        }, {});
        this.graphSpeedFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.speedFaults[index];
          return acc;
        }, {});
        this.graphHesitationFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.hesitationFaults[index];
          return acc;
        }, {});
        this.graphTimeFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.timeFaults[index];
          return acc;
        }, {});

        this.dataSourceStep = new MatTableDataSource(data.stepsTableData);

        this.bestTimeTotal = data.bestTimeTotal;
        this.avgTotal = data.avgTimeTotal;
        this.targetTotal = data.targetTimeTotal;

        this.bestWhatever = data.topScore;

        this.examHistoryGraphConfig(data.examHistoryScore, data.examHistorySessions);
        this.isModuleLoading = false;
      }, error => {
        this.isModuleLoading = false;
        //console.log("Trainees Module Details Error", error);
      })
      this._httpService.getTraineesModuleOverview('JAN', this.userID, this.selectedModuleName).subscribe(data => {
        this.improvement = data.improvement;
      })
      var tempData = [];
      this._httpService.getLeaderboardData('JAN', this.selectedModuleName).subscribe(data => {
        if (data != 'No Data') {
          for (let key in data) {
            tempData.push(data[key]);
          }
          this.sortRowsDesc('BestScore', tempData);
          var rankAssign = 1;
          let foundUser = false;
          tempData.forEach(element => {
            element.Rank = rankAssign++;
            if (!foundUser && this.userID === element.userID) {
              this.ranking = element.Rank;
              foundUser = true;
            }
          });
          if (!foundUser) this.ranking = 0;
        }
        else {
          this.ranking = 0;
        }
      }, error => {
        //console.log("LeaderBoard  Error", error);
      })
    }
    if (this.userRole == 'DEP') {
      this._httpService.getTraineesModuleDetails('DEP', this.userID, this.selectedModuleName).subscribe(data => {
        this.moduleDuration = data.duration;
        this.moduleSessions = data.sessions;
        this.moduleExamPercent = data.examPercent;
        this.practiceKPIGraphConfig(data.score, data.practiceHistorySessions);
        this.graphSessionTimeArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.practiceHistoryTime[index];
          return acc;
        }, {});
        this.graphSpeedFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.speedFaults[index];
          return acc;
        }, {});
        this.graphHesitationFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.hesitationFaults[index];
          return acc;
        }, {});
        this.graphTimeFaultArray = data.practiceHistorySessions.reduce((acc, key, index) => {
          acc[key] = data.timeFaults[index];
          return acc;
        }, {});

        this.dataSourceStep = new MatTableDataSource(data.stepsTableData);

        this.bestTimeTotal = data.bestTimeTotal;
        this.avgTotal = data.avgTimeTotal;
        this.targetTotal = data.targetTimeTotal;

        this.bestWhatever = data.topScore;

        this.examHistoryGraphConfig(data.examHistoryScore, data.examHistorySessions);
        this.isModuleLoading = false;
      }, error => {
        this.isModuleLoading = false;
        //console.log("Trainees Module Details Error", error);
      })
      this._httpService.getTraineesModuleOverview('DEP', this.userID, this.selectedModuleName).subscribe(data => {
        this.improvement = data.improvement;
      })
      var tempData = [];
      this._httpService.getLeaderboardData('DEP', this.selectedModuleName).subscribe(data => {
        if (data != 'No Data') {
          for (let key in data) {
            tempData.push(data[key]);
          }
          this.sortRowsDesc('BestScore', tempData);
          var rankAssign = 1;
          let foundUser = false;
          tempData.forEach(element => {
            element.Rank = rankAssign++;
            if (!foundUser && this.userID === element.userID) {
              this.ranking = element.Rank;
              foundUser = true;
            }
          });
          if (!foundUser) this.ranking = 0;
        }
        else {
          this.ranking = 0;
        }
      }, error => {
        //console.log("LeaderBoard  Error", error);
      })
    }
    if (this.userRole == 'RA') {
      this._httpService.getTraineesModuleDetails('RA', this.userID, this.selectedModuleName).subscribe(data => {
        this.moduleDuration = data.duration;
        this.moduleSessions = data.sessions;
        this.moduleExamPercent = data.examPercent;
        this.practiceHistoryGraphConfig(data.practiceHistorySeconds, data.practiceHistorySessions, data.practiceHistoryTargetSeconds);
        this.graphSessionTimeArray = data.practiceHistorySeconds.reduce((acc, key, index) => {
          acc[key] = data.practiceHistoryTime[index - 1];
          return acc;
        }, {});
        this.dataSourceStep = new MatTableDataSource(data.stepsTableData);

        this.bestTimeTotal = data.bestTimeTotal;
        this.avgTotal = data.avgTimeTotal;
        this.targetTotal = data.targetTimeTotal;

        this.bestWhatever = data.bestTimeTotal;

        this.examHistoryGraphConfig(data.examHistoryScore, data.examHistorySessions);

        this.isModuleLoading = false;
      }, error => {
        this.isModuleLoading = false;
        //console.log("Trainees Module Details Error", error);
      })
      this._httpService.getTraineesModuleOverview('RA', this.userID, this.selectedModuleName).subscribe(data => {
        this.improvement = data.improvement;
      })
      var tempData = [];
      this._httpService.getLeaderboardData('RA', this.selectedModuleName).subscribe(data => {
        if (data != 'No Data') {
          for (let key in data) {
            tempData.push(data[key]);
          } this.sortRows('BestTime', tempData);
          var rankAssign = 1;
          let foundUser = false;
          tempData.forEach(element => {
            element.Rank = rankAssign++;

            if (!foundUser && this.userID === element.userID) {
              this.ranking = element.Rank;
              foundUser = true;
            }
          });
          if (!foundUser) this.ranking = 0;
        }
        else {
          this.ranking = 0;
        }
      }, error => {
        //console.log("LeaderBoard  Error", error);
      })
    }
    //#endregion
  }

  openChartPopup() {
    this.popupChart = true;
    if (this.SingleAppRoles.includes(this.userRole)) {
      this._httpService.getSATraineesModuleDetails(this.userRole, this.userID, this.selectedModuleName, this.selectedCourse.CourseID).subscribe(data => {
        this.practiceKPIGraphConfigDetailed(data.score, data.practiceHistorySessions);
      }, error => {
        //console.log("Trainees Module Details Error", error);
      })
    }
    //#region Non Single App Roles
    if (this.userRole == "VIS") {
      this._httpService.getTraineesModuleDetails('VIS2', this.userID, this.selectedModuleName).subscribe(data => {
        this.practiceHistoryGraphConfigDetailed(data.practiceHistorySeconds, data.practiceHistorySessions, data.practiceHistoryTargetSeconds);
      }, error => {
        //console.log("Trainees Module Details Error", error);
      })
    }
    if (this.userRole == "BOS") {
      this._httpService.getTraineesModuleDetails('BOS', this.userID, this.selectedModuleName).subscribe(data => {
        this.practiceHistoryGraphConfigDetailed(data.practiceHistorySeconds, data.practiceHistorySessions, data.practiceHistoryTargetSeconds);
      }, error => {
        //console.log("Trainees Module Details Error", error);
      })
    }
    if (this.userRole == "FPT") {
      this._httpService.getTraineesModuleDetails('FPT', this.userID, this.selectedModuleName).subscribe(data => {
        this.practiceHistoryGraphConfigDetailed(data.practiceHistorySeconds, data.practiceHistorySessions, data.practiceHistoryTargetSeconds);
      }, error => {
        //console.log("Trainees Module Details Error", error);
      })
    }
    if (this.userRole == "JAN") {
      this._httpService.getTraineesModuleDetails('JAN', this.userID, this.selectedModuleName).subscribe(data => {
        this.practiceKPIGraphConfigDetailed(data.score, data.practiceHistorySessions);
      }, error => {
        //console.log("Trainees Module Details Error", error);
      })
    }
    if (this.userRole == "DEP") {
      this._httpService.getTraineesModuleDetails('DEP', this.userID, this.selectedModuleName).subscribe(data => {
        this.practiceKPIGraphConfigDetailed(data.score, data.practiceHistorySessions);
      }, error => {
        //console.log("Trainees Module Details Error", error);
      })
    }
    if (this.userRole == "RA") {
      this._httpService.getTraineesModuleDetails('RA', this.userID, this.selectedModuleName).subscribe(data => {
        this.practiceHistoryGraphConfigDetailed(data.practiceHistorySeconds, data.practiceHistorySessions, data.practiceHistoryTargetSeconds);
      }, error => {
        //console.log("Trainees Module Details Error", error);
      })
    }
    //#endregion
    this.fadeOut = true;
  }

  closeChartPopup() {
    this.popupChart = false;
    this.fadeOut = false;
  }

  examHistoryGraphConfig(yValues, xValues) {
    const graph_label = xValues;
    const graph_value = yValues;

    if (this.barChart) {
      this.barChart.data.labels = graph_label;
      this.barChart.data.datasets[0].data = graph_value;
      this.barChart.update();
    } else {
      this.barChart = new Chart('bar-chart', {
        type: 'bar',
        data: {
          labels: graph_label,
          datasets: [{
            data: graph_value,
            label: "Score",
            borderColor: "#007d6f",
            fill: true,
            backgroundColor: "#007d6f"
          }]
        },
        options: {
          title: {
            display: false
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                fontFamily: "Libre Franklin",
                fontColor: "#273e5c",
                labelString: 'Score'
              },
              ticks: {
                fontColor: "#273e5c",
                beginAtZero: true,
                fontFamily: "Libre Franklin",
                maxTicksLimit: 6
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                fontFamily: "Libre Franklin",
                fontColor: "#273e5c",
                labelString: 'No. of Attempts'
              },
              ticks: {
                fontColor: "#273e5c",
                fontFamily: "Libre Franklin",
                beginAtZero: true,
                maxTicksLimit: 10
                //maxTicksLimit: Math.round((Math.max.apply(Math, xValues) / 8)/20)*20,
              },
            }]
          },
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 3,
              hitRadius: 15,
              hoverRadius: 9,
              hoverBorderWidth: 4
            }
          },
          maintainAspectRatio: false
        }
      });
    }
  }

  practiceHistoryGraphConfig(yValues, xValues, targetValues) {
    const graph_label = xValues;
    const graph_value = yValues;

    if (this.lineChart) {
      this.lineChart.data.labels = graph_label;
      this.lineChart.data.datasets[0].data = graph_value;
      this.lineChart.data.datasets[1].data = targetValues;
      this.lineChart.update();
    } else {
      this.lineChart = new Chart('line-chart', {
        type: 'line',
        data: {
          labels: graph_label,
          datasets: [{
            data: graph_value,
            label: "Session Time",
            borderColor: "#007d6f",
            fill: false,
            lineTension: 0,
            pointRadius: 0,
            borderWidth: 2
          },
          {
            data: targetValues,
            label: "Target Time",
            borderColor: "rgb(2, 32, 55, 0.8)",
            fill: false,
            lineTension: 0,
            pointRadius: 0,
            borderDash: [8, 3]
          },]
        },
        options: {
          title: {
            display: false
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                fontFamily: "Libre Franklin",
                fontColor: "#273e5c",
                labelString: 'Seconds'
              },
              ticks: {
                fontColor: "#273e5c",
                beginAtZero: true,
                fontFamily: "Libre Franklin"
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                fontFamily: "Libre Franklin",
                fontColor: "#273e5c",
                labelString: 'No. of Sessions'
              },
              ticks: {
                fontColor: "#273e5c",
                fontFamily: "Libre Franklin",
                beginAtZero: true,
                maxTicksLimit: 10
                //maxTicksLimit: Math.round((Math.max.apply(Math, xValues) / 8)/20)*20,
              },
            }]
          },
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 3,
              hitRadius: 15,
              hoverRadius: 9,
              hoverBorderWidth: 4
            }
          },
          maintainAspectRatio: false
        }
      });
    }


  }

  practiceHistoryGraphConfigDetailed(yValues, xValues, targetValues) {
    const graph_label = xValues;
    const graph_value = yValues;

    this.bigLineChart = new Chart('big-line-chart', {
      type: 'line',
      data: {
        labels: graph_label,
        datasets: [{
          data: graph_value,
          label: "Session Time",
          borderColor: "#007d6f",
          fill: false,
          lineTension: 0,
          pointRadius: 1,
          borderWidth: 3,
          hoverRadius: 11,
          hoverBorderWidth: 5,
          radius: 3,
          hitRadius: 15
        },
        {
          data: targetValues,
          label: "Target Time",
          borderColor: "rgb(2, 32, 55, 0.8)",
          fill: false,
          lineTension: 0,
          pointRadius: 0,
          borderDash: [8, 3]
        },]
      },
      options: {
        title: {
          display: false
        },
        responsive: true,
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "Libre Franklin",
              fontColor: "#273e5c",
              labelString: 'Seconds',
              fontSize: 18
            },
            ticks: {
              fontColor: "#273e5c",
              beginAtZero: true,
              min: 0,
              fontFamily: "Libre Franklin",
              fontSize: 14
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              fontFamily: "Libre Franklin",
              fontColor: "#273e5c",
              labelString: 'No. of Sessions',
              fontSize: 18
            },
            ticks: {
              maxTicksLimit: 20,
              fontColor: "#273e5c",
              beginAtZero: true,
              fontFamily: "Libre Franklin",
              fontSize: 14,
              min: 0,
              suggestedMin: 0,
              suggestedMax: xValues.length - 1
            },
          }]
        },
        legend: {
          display: false
        },
        maintainAspectRatio: false
      }
    });
  }

  practiceKPIGraphConfig(yValues, xValues) {
    const graph_label = xValues;
    const graph_value = yValues;

    if (this.kpiLineChart) {
      this.kpiLineChart.data.labels = graph_label;
      this.kpiLineChart.data.datasets[0].data = graph_value;
      this.kpiLineChart.update();
    } else {
      this.kpiLineChart = new Chart('kpi-line-chart', {
        type: 'line',
        data: {
          labels: graph_label,
          datasets: [{
            data: graph_value,
            label: "Score",
            borderColor: "#007d6f",
            fill: false,
            lineTension: 0,
            pointRadius: 0,
            borderWidth: 2
          }]
        },
        options: {
          title: {
            display: false
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                fontFamily: "Libre Franklin",
                fontColor: "#273e5c",
                labelString: 'Score'
              },
              ticks: {
                fontColor: "#273e5c",
                beginAtZero: true,
                fontFamily: "Libre Franklin",
                maxTicksLimit: 6
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                fontFamily: "Libre Franklin",
                fontColor: "#273e5c",
                labelString: 'No. of Sessions'
              },
              ticks: {
                fontColor: "#273e5c",
                fontFamily: "Libre Franklin",
                beginAtZero: true,
                maxTicksLimit: 10
                //maxTicksLimit: Math.round((Math.max.apply(Math, xValues) / 8)/20)*20,
              },
            }]
          },
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 3,
              hitRadius: 15,
              hoverRadius: 9,
              hoverBorderWidth: 4
            }
          },
          maintainAspectRatio: false
        }
      });
    }


  }

  practiceKPIGraphConfigDetailed(yValues, xValues) {
    const graph_label = xValues;
    const graph_value = yValues;

    this.kpiBigLineChart = new Chart('kpi-big-line-chart', {
      type: 'line',
      data: {
        labels: graph_label,
        datasets: [{
          data: graph_value,
          label: "Score",
          borderColor: "#007d6f",
          fill: false,
          lineTension: 0,
          pointRadius: 1,
          borderWidth: 3,
          hoverRadius: 11,
          hoverBorderWidth: 5,
          radius: 3,
          hitRadius: 15
        }]
      },
      options: {
        title: {
          display: false
        },
        responsive: true,
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "Libre Franklin",
              fontColor: "#273e5c",
              labelString: 'Score',
              fontSize: 18
            },
            ticks: {
              fontColor: "#273e5c",
              beginAtZero: true,
              min: 0,
              fontFamily: "Libre Franklin",
              fontSize: 14,
              maxTicksLimit: 6
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              fontFamily: "Libre Franklin",
              fontColor: "#273e5c",
              labelString: 'No. of Sessions',
              fontSize: 18
            },
            ticks: {
              maxTicksLimit: 20,
              fontColor: "#273e5c",
              beginAtZero: true,
              fontFamily: "Libre Franklin",
              fontSize: 14,
              min: 0,
              suggestedMin: 0,
              suggestedMax: xValues.length - 1
            },
          }]
        },
        legend: {
          display: false
        },
        maintainAspectRatio: false
      }
    });
  }

  calculateProgress(): void {
    var totalFields;
    this.dataSource.forEach(module => {
      var completedFields = Object.values(module).filter(value => value === 'Yes').length;
      totalFields = 3;
      if (this.userRole == 'JAN' || this.userRole == 'DEP')
        totalFields = module.numberOfModes;
      if (this.userRole == 'RA') {
        totalFields = 2;
        completedFields = completedFields - 1;
      }
      if(this.SingleAppRoles.includes(this.userRole)){
        module.progress = module.progress;
      }
      else{
        module.progress = (completedFields / totalFields) * 100;
      }
    });
  }

  sortRows(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (a, b) {
      var i = 0;
      while (i < len) { a = a[prop[i]]; b = b[prop[i]]; i++; }
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return arr;
  }

  sortRowsDesc(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (b, a) {
      var i = 0;
      while (i < len) { a = a[prop[i]]; b = b[prop[i]]; i++; }
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return arr;
  }

  getOrdinalSuffix(number: number): string {
    const suffixes = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return number + 'th';
    } else {
      return number + suffixes[lastDigit];
    }
  }

  chartHovered(event: any) {
    const activePoints = this.lineChart.getElementsAtEventForMode(event, 'point', { intersect: true });
    if (activePoints.length > 0) {
      const clickedPoint = activePoints[0];
      const datasetIndex = clickedPoint._datasetIndex;
      const dataIndex = clickedPoint._index;

      const dataset = this.lineChart.data.datasets[datasetIndex];
      const value = dataset.data[dataIndex];

      this.graphSessionTime = this.graphSessionTimeArray[value];
    }
  }

  bigChartHovered(event: any) {
    const activePoints = this.bigLineChart.getElementsAtEventForMode(event, 'point', { intersect: true });
    if (activePoints.length > 0) {
      const clickedPoint = activePoints[0];
      const datasetIndex = clickedPoint._datasetIndex;
      const dataIndex = clickedPoint._index;

      const dataset = this.bigLineChart.data.datasets[datasetIndex];
      const value = dataset.data[dataIndex];

      this.graphSessionTime = this.graphSessionTimeArray[value];
    }
  }

  kpiChartHovered(event: any) {
    const activePoints = this.kpiLineChart.getElementsAtEventForMode(event, 'point', { intersect: true });

    if (activePoints.length > 0) {
      const clickedPoint = activePoints[0];
      const dataIndex = clickedPoint._index;

      const sessionKey = this.kpiLineChart.data.labels[dataIndex];

      this.graphSessionTime = this.graphSessionTimeArray[sessionKey];
      this.speedWarning = this.graphSpeedFaultArray[sessionKey];
      this.hesitation = this.graphHesitationFaultArray[sessionKey];
      this.targetsMissed = this.graphTimeFaultArray[sessionKey];

    }
  }

  selectCourse(course: any) {
    this.selectedCourse = course;
    const matchingCourse = this.courses.find(c => c.CourseID === course.CourseID);

    if (matchingCourse) {
      this.dataSource = matchingCourse.Data;
    }
  }

  kpiBigChartHovered(event: any) {
    const activePoints = this.kpiBigLineChart.getElementsAtEventForMode(event, 'point', { intersect: true });
    if (activePoints.length > 0) {
      const clickedPoint = activePoints[0];
      const dataIndex = clickedPoint._index;

      const sessionKey = this.kpiBigLineChart.data.labels[dataIndex];

      this.graphSessionTime = this.graphSessionTimeArray[sessionKey];
      this.speedWarning = this.graphSpeedFaultArray[sessionKey];
      this.hesitation = this.graphHesitationFaultArray[sessionKey];
      this.targetsMissed = this.graphTimeFaultArray[sessionKey];
    }
  }

  isSARole(): boolean {
    return this.SingleAppRoles.includes(this.userRole);
  }
  isKPIRole(): boolean {
    return this.KPIRoles.includes(this.userRole);
  }
}
