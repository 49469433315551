import { Component, OnInit, ViewChild } from '@angular/core';
 import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
 import { Router, ActivatedRoute } from '@angular/router';
 import {HttpService} from '../../shared/service/http.service';

 @Component({
  selector: 'app-module-list-v2',
  templateUrl: './module-list-v2.component.html',
  styleUrls: ['./module-list-v2.component.scss']
})
export class ModuleListV2Component implements OnInit {
  dataSource= new MatTableDataSource();
  dataSourceImtech= new MatTableDataSource();
  displayedColumns = ['FullName', 'Users', 'Duration'];
  search : String ="";
  tempData=[];
  userRole: any;
  userType: any;
  isLoading = true;
  courseFullName;

  constructor(
    private router: Router,
    private _httpService: HttpService) {
      if(this.router.getCurrentNavigation().extras.state){
        this.courseFullName = this.router.getCurrentNavigation().extras.state.name;
        localStorage.setItem('moduleName', this.courseFullName);
      }
     }

@ViewChild(MatSort, { static: true }) sort: MatSort;
@ViewChild('paginator') paginator: MatPaginator;

ngOnInit() {
  this.courseFullName = localStorage.getItem('moduleName');
  this._httpService.getAuthentication()
  .subscribe(data =>{
    this.userType = data.userType;
    this.userRole = data.role;
    if(this.userType == 'Admin'){
      if(this.userRole == 'VIS' || this.userRole == 'Mersus'){
        this._httpService.getModules('VIS2').subscribe(data=>{
          for (let key in data) {
            if(!key.includes('Quiz'))
              this.tempData.push(data[key]);
        }
        this.isLoading = false;
        this.sortRows('latestTime', this.tempData);

        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.tempData;
        }, error =>{
          this.isLoading = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'BOS' || this.userRole == 'Mersus'){
        this._httpService.getModules('BOS3').subscribe(data=>{
        this.isLoading = false;
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource = data;
        }, error =>{
          this.isLoading = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'FPT' || this.userRole == 'Mersus'){
        this._httpService.getModules('FPT').subscribe(data=>{
          for (let key in data) {
            if(!key.includes('Quiz'))
              this.tempData.push(data[key]);
        }
        this.isLoading = false;
        this.sortRows('latestTime', this.tempData);
        
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.tempData;
        }, error =>{
          this.isLoading = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'AVA' || this.userRole == 'Mersus'){
        this._httpService.getModules('AvaDemo').subscribe(data=>{
          for (let key in data) {
            if(!key.includes('Quiz'))
              this.tempData.push(data[key]);
        }
        this.isLoading = false;
        this.sortRows('latestTime', this.tempData);
        
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.tempData;
        }, error =>{
          this.isLoading = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'JAN' || this.userRole == 'Mersus'){
        this._httpService.getModules('JAN').subscribe(data=>{
          for (let key in data) {
            if(!key.includes('Quiz'))
              this.tempData.push(data[key]);
        }
        this.isLoading = false;
        this.sortRows('latestTime', this.tempData);
        
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.tempData;
        }, error =>{
          this.isLoading = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'DEP' || this.userRole == 'Mersus'){
        this._httpService.getModules('DEP').subscribe(data=>{
          for (let key in data) {
            if(!key.includes('Quiz'))
              this.tempData.push(data[key]);
        }
        this.isLoading = false;
        this.sortRows('latestTime', this.tempData);
        
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.tempData;
        }, error =>{
          this.isLoading = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
      if(this.userRole == 'RA' || this.userRole == 'Mersus'){
        this._httpService.getModules('RA').subscribe(data=>{
          for (let key in data) {
            if(!key.includes('Quiz'))
              this.tempData.push(data[key]);
        }
        this.isLoading = false;
        this.sortRows('latestTime', this.tempData);
        
        this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.tempData;
        }, error =>{
          this.isLoading = false
          //console.log("Trainees Table  Error", error);
        }) 
      }
    }
    }, error=>{
      if(error.status == 401){
        this.router.navigateByUrl("/Logout", { state: { error: '401' }});
      }
    })    
  }

  sortRows(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (a, b) {
        var i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        return b > a ? 1 : b < a ? -1 : 0;
    });
    return arr;
};

  searchTable(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showDetails(moduleData) {
    this.router.navigateByUrl("/CoursesV2/BOS", { state: {courseName: moduleData.name}});
  }

}
