import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';
import { ExportToCsv } from 'export-to-csv';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-trainees-v2',
  templateUrl: './trainees-v2.component.html',
  styleUrls: ['./trainees-v2.component.scss']
})
export class TraineesV2Component implements OnInit {
  ImtechRoles = ['IMT', 'IMTLCETB']
  dataSource = new MatTableDataSource();
  dataSourceImtech = new MatTableDataSource();
  displayedColumns = ['Name', 'Module', 'Date', 'Explainer', 'Practice', 'Exam', 'Sessions', 'Duration']
  displayedColumnsImtech = ['Name', 'Date', 'Exam', 'Score', 'Duration', 'Action']
  search: String = "";
  tempData = [];
  userRole: any;
  userType: any;
  isLoading = true;
  csvDataSource;
  csvReportDataSource;
  options = {
    filename: "Imtech_Data_" + new Date().toLocaleDateString(),
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Imtech_Data - ' + new Date(),
    useTextFile: false,
    useBom: true,
    // useKeysAsHeaders: true,
    headers: ['Name', 'Date', 'Score', 'Duration']
  };

  constructor(
    private router: Router,
    private _httpService: HttpService,
    private _sharedService: SharedService) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('imTechpaginator') imTechpaginator: MatPaginator;

  ngOnInit() {
    this._httpService.getAuthentication()
      .subscribe(data => {
        this.userType = data.userType;
        this.userRole = data.role;
        if (this.userType == 'Admin') {
          //NOTE: THIS IS ONLY FUNCTIONAL FOR IMTECHS

          // if(this.userRole == 'VIS' || this.userRole == 'Mersus'){
          //   this._httpService.getActivity('VIS2').subscribe(data=>{
          //     for (let key in data) {
          //       if(!key.includes('Quiz'))
          //         this.tempData.push(data[key]);
          //   }
          //   this.isLoading = false;
          //   this.sortRows('latestTime', this.tempData);

          //   this.dataSource.sort = this.sort;
          //   this.dataSource.paginator = this.paginator;
          //   this.dataSource.data = this.tempData;
          //   }, error =>{
          //     this.isLoading = false
          //     //console.log("Trainees Table  Error", error);
          //   }) 
          // }
          // if(this.userRole == 'BOS' || this.userRole == 'Mersus'){
          //   this._httpService.getActivity('BOS3').subscribe(data=>{
          //     for (let key in data) {
          //       if(!key.includes('Quiz'))
          //         this.tempData.push(data[key]);
          //   }
          //   this.isLoading = false;
          //   this.sortRows('latestTime', this.tempData);

          //   this.dataSource.sort = this.sort;
          //   this.dataSource.paginator = this.paginator;
          //   this.dataSource.data = this.tempData;
          //   }, error =>{
          //     this.isLoading = false
          //     //console.log("Trainees Table  Error", error);
          //   }) 
          // }
          // if(this.userRole == 'FPT' || this.userRole == 'Mersus'){
          //   this._httpService.getActivity('FPT').subscribe(data=>{
          //     for (let key in data) {
          //       if(!key.includes('Quiz'))
          //         this.tempData.push(data[key]);
          //   }
          //   this.isLoading = false;
          //   this.sortRows('latestTime', this.tempData);

          //   this.dataSource.sort = this.sort;
          //   this.dataSource.paginator = this.paginator;
          //   this.dataSource.data = this.tempData;
          //   }, error =>{
          //     this.isLoading = false
          //     //console.log("Trainees Table  Error", error);
          //   }) 
          // }
          // if(this.userRole == 'AVA' || this.userRole == 'Mersus'){
          //   this._httpService.getActivity('AvaDemo').subscribe(data=>{
          //     for (let key in data) {
          //       if(!key.includes('Quiz'))
          //         this.tempData.push(data[key]);
          //   }
          //   this.isLoading = false;
          //   this.sortRows('latestTime', this.tempData);

          //   this.dataSource.sort = this.sort;
          //   this.dataSource.paginator = this.paginator;
          //   this.dataSource.data = this.tempData;
          //   }, error =>{
          //     this.isLoading = false
          //     //console.log("Trainees Table  Error", error);
          //   }) 
          // }
          // if(this.userRole == 'JAN' || this.userRole == 'Mersus'){
          //   this._httpService.getActivity('JAN').subscribe(data=>{
          //     for (let key in data) {
          //       if(!key.includes('Quiz'))
          //         this.tempData.push(data[key]);
          //   }
          //   this.isLoading = false;
          //   this.sortRows('latestTime', this.tempData);

          //   this.dataSource.sort = this.sort;
          //   this.dataSource.paginator = this.paginator;
          //   this.dataSource.data = this.tempData;
          //   }, error =>{
          //     this.isLoading = false
          //     //console.log("Trainees Table  Error", error);
          //   }) 
          // }
          // if(this.userRole == 'DEP' || this.userRole == 'Mersus'){
          //   this._httpService.getActivity('DEP').subscribe(data=>{
          //     for (let key in data) {
          //       if(!key.includes('Quiz'))
          //         this.tempData.push(data[key]);
          //   }
          //   this.isLoading = false;
          //   this.sortRows('latestTime', this.tempData);

          //   this.dataSource.sort = this.sort;
          //   this.dataSource.paginator = this.paginator;
          //   this.dataSource.data = this.tempData;
          //   }, error =>{
          //     this.isLoading = false
          //     //console.log("Trainees Table  Error", error);
          //   }) 
          // }
          // if(this.userRole == 'RA' || this.userRole == 'Mersus'){
          //   this._httpService.getActivity('RA').subscribe(data=>{
          //     for (let key in data) {
          //       if(!key.includes('Quiz'))
          //         this.tempData.push(data[key]);
          //   }
          //   this.isLoading = false;
          //   this.sortRows('latestTime', this.tempData);

          //   this.dataSource.sort = this.sort;
          //   this.dataSource.paginator = this.paginator;
          //   this.dataSource.data = this.tempData;
          //   }, error =>{
          //     this.isLoading = false
          //     //console.log("Trainees Table  Error", error);
          //   }) 
          // }
          if (this.userRole == 'IMT' || this.userRole == 'Mersus') {
            this._httpService.getActivity('IMT').subscribe(data => {
              for (let key in data) {
                if (!key.includes('Quiz'))
                  this.tempData.push(data[key]);
              }
              this.isLoading = false;
              this.sortRows('latestTime', this.tempData);
              this.dataSourceImtech.sort = this.sort;
              this.dataSourceImtech.paginator = this.imTechpaginator;
              this.dataSourceImtech.data = this.tempData;

              this.csvDataSource = this.tempData;
            }, error => {
              this.isLoading = false
              //console.log("Trainees Table  Error", error);
            })
          }
          if (this.userRole == 'IMTLCETB' || this.userRole == 'Mersus') {
            this._httpService.getActivity('IMTLCETB').subscribe(data => {
              for (let key in data) {
                if (!key.includes('Quiz'))
                  this.tempData.push(data[key]);
              }
              this.isLoading = false;
              this.sortRows('latestTime', this.tempData);
              this.dataSourceImtech.sort = this.sort;
              this.dataSourceImtech.paginator = this.imTechpaginator;
              this.dataSourceImtech.data = this.tempData;

              this.csvDataSource = this.tempData;
            }, error => {
              this.isLoading = false
              //console.log("Trainees Table  Error", error);
            })
          }
        }
      }, error => {
        if (error.status == 401) {
          this.router.navigateByUrl("/Logout", { state: { error: '401' } });
        }
      })
  }

  sortRows(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (a, b) {
      var i = 0;
      while (i < len) { a = a[prop[i]]; b = b[prop[i]]; i++; }
      return b > a ? 1 : b < a ? -1 : 0;
    });
    return arr;
  };

  searchTable(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showDetails(userData) {
    this.router.navigateByUrl("V2/TraineesDetailsV2", { state: { name: userData.Name, userID: userData.User_ID } });
  }

  downloadData() {
    this.csvDataSource.forEach(function (val) {
      delete val.Module; delete val.Explainer; delete val.Practice; delete val.latestTime; delete val.SessionID;
      delete val.moduleName, delete val.User_ID; delete val.practiceBeatTarget; delete val.durSum; delete val.Action; delete val.Exam;
      delete val.Sessions;
    });
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(this.csvDataSource);
  }

  downloadReport(data) {
    var userData = { "userID": data.User_ID };

    if (this.userRole == 'IMT') {
      this._httpService.getQuizDataImtech(userData).subscribe(reportData => {
        this.csvReportDataSource = reportData;
        var reportOptions = {
          filename: "ImtechReport_" + data.Name,
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Date: ' + new Date() + '\nUsername: ' + data.Name + '\nScore: ' + data.Score,
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
        };
        if (Array.isArray(reportData) && reportData.length > 0) {
          const csvReportExporter = new ExportToCsv(reportOptions);
          csvReportExporter.generateCsv(this.csvReportDataSource);
        }
        else {
          Swal.fire({
            title: 'No data',
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#021B36'
          }).then(() => {
          });
        }
      }, error => {
      })
    }
    if (this.userRole == 'IMTLCETB') {
      this._httpService.getQuizDataImtechLCETB(userData).subscribe(reportData => {
        this.csvReportDataSource = reportData;
        var reportOptions = {
          filename: "ImtechReport_" + data.Name,
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Date: ' + new Date() + '\nUsername: ' + data.Name + '\nScore: ' + data.Score,
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
        };
        if (Array.isArray(reportData) && reportData.length > 0) {
          const csvReportExporter = new ExportToCsv(reportOptions);
          csvReportExporter.generateCsv(this.csvReportDataSource);
        }
        else {
          Swal.fire({
            title: 'No data',
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#021B36'
          }).then(() => {
          });
        }
      }, error => {
      })
    }
  }

  isIMTRole(): boolean {
    return this.ImtechRoles.includes(this.userRole);
  }
}
