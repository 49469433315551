import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SharedService } from '../../../shared/service/shared.service';
import {HttpService} from '../../../shared/service/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-jan',
  templateUrl: './jan.component.html',
  styleUrls: ['./jan.component.scss']
})
export class JanComponent implements OnInit {
  dataSource= new MatTableDataSource();
  errorsChart;
  modesChart;
  passRate;
  passRateUpdate;
  totalTrainees;
  totalTraineesUpdate;
  totalSessions;
  totalSessionsUpdate;
  sessionDuration;
  sessionDurationUpdate;
  displayedColumns = ['Rank', 'Name', 'Score', 'Attempts']
  search : String ="";
  error;
  modes;
  isLoading = true;
  isStatsLoading = true;

  //Course Details
  courseName;
  courseFullName;
  userRole: string;
  userType: string;

  constructor(
    private router: Router,
    private _httpService: HttpService,
    private _sharedService: SharedService
  ) {
    if(this.router.getCurrentNavigation().extras.state){
      this.courseName = this.router.getCurrentNavigation().extras.state.courseName;
      this.getCourseName(this.courseName);
      localStorage.setItem('module', this.courseName);
    }   
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() { 
    //Works but can be optimized
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.courseName = localStorage.getItem('module');
    this.getCourseName(this.courseName);
    this._httpService.getAuthentication()
    .subscribe(data =>{
      this.userType = data.userType;
      this.userRole = data.role;
      if(this.userType=='Admin'){
        if(this.userRole == 'JAN' || this.userRole == 'Mersus'){
          var tempData=[];
          this._httpService.getLeaderboardData('JAN',this.courseName).subscribe(data=>{
            if(data != 'No Leaderboard Data!'){
              for (let key in data) {
                tempData.push(data[key]);
            }this.sortRows('BestScore', tempData);
            var rankAssign = 1;
            tempData.forEach(element => {
              element.Rank= rankAssign++;
            });
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.data = tempData;
            this.isLoading = false;
            }
            else{
              this.isLoading = false;
            }
          }, error =>{
            this.isLoading = false;
            //console.log("LeaderBoard  Error", error);
          })
          this._httpService.getModuleStats('JAN', this.courseName).subscribe(data=>{
            this.barChartConfigTrainingModes(data.modesData);
            this.barChartConfigErrors(data.errors);
            this.passRate = data.passRate;
            this.passRateUpdate = data.passRateUpdate;
            this.totalTrainees = data.totalTrainees;
            this.totalTraineesUpdate = data.totalTraineesUpdate;
            this.totalSessions = data.totalSessions;
            this.totalSessionsUpdate = data.totalSessionsUpdate;
            this.sessionDuration = data.sessionDuration;
            this.sessionDurationUpdate = data.sessionDurationUpdate;
            this.isStatsLoading = false;
          }, error => {
            this.isStatsLoading = false;
            // console.log("Module Stats Error");
          })  
        }
      }
    }, error=>{
      if(error.status == 401){
        this.router.navigateByUrl("/Logout", { state: { error: '401' }});
      }
    })    
  }

  showDetails(userData) {
    this.router.navigateByUrl("V2/TraineesDetailsV2", { state: {name: userData.Name, userID: userData.userID}});
  }

  sortRows(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (b, a) {
        var i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    });
    return arr;
};

  barChartConfigErrors(data){
    this.error = data;
    var labels = new Array();
    var barGraphValues = new Array();

    for (let key in this.error) {
      labels.push(key);
      barGraphValues.push(this.error[key]);
    }
    this.errorsChart = new Chart('error-chart', {
      type: 'horizontalBar',
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: ["#007d6f", "#007d6f","#007d6f","#007d6f", "#007d6f"],
            maxBarThickness: 60,
            data: barGraphValues
          }
        ]
      },
      options: {
        legend: { display: false },
        scales: {
          yAxes: [{
            gridLines: {
              display: false
              },
              ticks: {
                beginAtZero: true
            }
          }],
          xAxes: [{
            display: false,
            gridLines: {
              display: false
              },
              ticks: {
                beginAtZero: true
            }
          }]
        },
        maintainAspectRatio: false
      }
  });  
  }

  barChartConfigTrainingModes(modesData){
    this.modes = modesData;
    var labels = ['Practice', 'Explainer', 'Exam', 'Demonstration']
    var barGraphValues = []

    for (let key in labels) {
      barGraphValues.push(this.modes[labels[key]]);
    }
    this.modesChart = new Chart('modes-chart', {
      type: 'horizontalBar',
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: ["#007d6f", "#007d6f","#007d6f","#007d6f"],
            maxBarThickness: 60,
            data: barGraphValues
          }
        ]
      },
      options: {
        legend: { display: false },
        scales: {
          yAxes: [{
            gridLines: {
              display: false
              },
              ticks: {
                beginAtZero: true
            }
          }],
          xAxes: [{
            display: false,
            gridLines: {
              display: false
              },
              ticks: {
                beginAtZero: true
            }
          }]
        },
        maintainAspectRatio: false
      }
  });  
  }

  searchTable(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getCourseName(courseID){
    switch(courseID){
      case 'M1':
        this.courseFullName = 'Settle Plates';
        break;
      case 'M2':
        this.courseFullName = 'Lining Change Prep';
        break;
      case 'M3':
        this.courseFullName = 'Lining Swap';
        break;
      case 'M4':
        this.courseFullName = 'Clearout';
        break;
    }
  }
}
