import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {HttpService} from '../service/http.service';
import {Router} from '@angular/router';
import { SharedService } from '../service/shared.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
  //Need to make changes in the HTML for any future Imtech projects
  adminRoles = [
    { name: 'VIS', hasDropdown: false },
    { name: 'BOS', hasDropdown: true },
    { name: 'FPT', hasDropdown: true },
    { name: 'RA', hasDropdown: true },
    { name: 'JAN', hasDropdown: true },
    { name: 'DEP', hasDropdown: true },
    { name: 'AVA', hasDropdown: true },
    { name: 'AVAT', hasDropdown: true },
    { name: 'AVAP', hasDropdown: true }
  ];

  clients = [
    { id: 'VIS', name: 'VistaMed' },
    { id: 'BOS', name: 'Boston Scientific' },
    { id: 'FPT', name: 'First Polymer Training' },
    { id: 'RA', name: 'Robotics Arm' },
    { id: 'JAN', name: 'Janssen' },
    { id: 'DEP', name: 'DePuy' },
    { id: 'IMT', name: 'Imtech Skills' },
    { id: 'IMTLCETB', name: 'Imtech Skills LCETB' },
    { id: 'AVA', name: 'AVA Demo' },
    { id: 'AVAT', name: 'AVA Test' },
    { id: 'AVAP', name: 'AVA Public' }
  ];

  userRole;
  userType;
  expandRows = [];
  userName = "";

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private _httpService: HttpService,
    private snackBar: MatSnackBar,
    private _sharedService: SharedService
    ) {}

    ngOnInit() {
      if(this.router.url != "/Logout"){
        this._httpService.getAuthentication()
        .subscribe(data=>{
          this._sharedService.setRole(data.role);
          this._sharedService.setUserType(data.userType);
          this.userRole = this._sharedService.getRole();
          this.userType = this._sharedService.getUserType();
          this.userName = data.id;
        }, error =>{
          if(error.status == 401){
            this.router.navigateByUrl("/Logout", { state: { error: '401' }});
        }
        else{
          this.router.navigateByUrl("/Logout");
        }
        })
      }
    }

    openMersus(){
      window.open("https://mersus.io/", "_blank");
    }

    openPrivacy(){
      window.open("https://mersus.io/terms-conditions/", "_blank");
    }
}
