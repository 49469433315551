<div class="main-back">
  <br><br><br><br><br><br>
  <div class="container" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutWrap
      fxLayoutGap="0.5%" fxLayoutAlign="center">
      <div fxFlex="50%">
          <br><br>
          <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
          <div class="form-container">
              <br>
              <div class="white-font">Create Password</div>
              <div class="white-font-med">
                <ul>
                  <li>At least 8 characters</li>
                  <li>At least 1 Upper case character</li>
                  <li>At least 1 Lower case character</li>
                  <li>At least 1 Symbol</li>
                  <li>At least 1 Number</li>
                </ul>
              </div>
              <form [formGroup]="changePassword" (ngSubmit)="changePasswordCall()" novalidate>

                  <mat-form-field>
                    <mat-label style="color: white;"> New Password </mat-label>
                    <input style="color: white;" matInput [type]="passwordTextType" placeholder="password" formControlName="password">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label style="color: white;"> Confirm New Password </mat-label>
                    <input style="color: white;" matInput [type]="passwordTextType" placeholder="Password" formControlName="confirmPassword">
                  </mat-form-field>
                  <div style="color: white;">
                    <input type="checkbox" style="transform: scale(1.5)" (click)="showPassword()">         Show Password
                  </div>
                  <span *ngIf = "samePassword" class="error-message">Use a different password.</span>
                  <br>
                  <button class="button" [disabled]="!changePassword.valid" mat-raised-button type="submit">
                    <span class="font-Libre-Medium">SAVE</span>
                  </button>
              </form>        
          </div>

      </div>
      <div fxFlex="50%" *ngIf="!mobileScreen">
          <img class="img" src="/assets/new/Mask Group 46.jpg" alt="AA Labs">
      </div>
  </div>
</div>