<app-main-nav *ngIf="NotExpired" >
  <div fxLayout="column" fxLayoutGap="2%" class="actual-main-container">
    <!-- Top text and Add button -->
    <div fxFlex="5" fxLayout="column" fxLayoutGap="1%">
      <span class="heading-text-config">
        Welcome, {{username}} 
      </span>
      <span class="med-text-config">
        Summary of training to date
      </span>
    </div>
    <!-- Cards -->
    <div fxFlex="10" [style.opacity]="fadeOut?'-.2':null" [ngClass]="fadeOut? 'main-container pointer-events': 'main-container'" fxLayoutGap="2%" fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayout.lt-md="row wrap" fxLayoutWrap style="margin-top:1.5%">
      <mat-card *ngIf= "isKPIRole()" 
      class="card-config" fxFlex="22%" [ngClass.lt-lg] ="'card-column'" fxFlex.lt-md="40%">
        <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'" >
          <img style="height: 25px; vertical-align:middle" *ngIf="!mobileScreen" src="assets\2.0\AVA_moduleCompletion_icon.svg"> &nbsp;
          Errors
        </mat-card-title>
        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
          <app-skeleton-loading Cwidth="250" Cheight="100">
          </app-skeleton-loading>
        </div>
        <mat-card-content class="card-content-config" [ngClass.lt-lg] ="'card-column-content'" >{{errorsCaught}}</mat-card-content>
        <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg] ="'card-column-secondary-content'" >
          <p>errors caught</p>
        </mat-card-content>
        </mat-card>
      <mat-card *ngIf= "userRole != 'IMT' && userRole != 'IMTLCETB'" class="card-config" fxFlex="22%" [ngClass.lt-lg] ="'card-column'" fxFlex.lt-md="40%">
        <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'">
          <img style="height: 25px; vertical-align:top" *ngIf="!mobileScreen" src="/assets/2.0/AVA_trainees_icon.svg"> &nbsp;
          Users
        </mat-card-title>
        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
          <app-skeleton-loading Cwidth="250" Cheight="100">
          </app-skeleton-loading>
        </div>
      <mat-card-content class="card-content-config" [ngClass.lt-lg] ="'card-column-content'" >{{userCount}}</mat-card-content>
        <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg] ="'card-column-secondary-content'">
          <p>unique users</p>
        </mat-card-content>
      </mat-card>
      <mat-card class="card-config" fxFlex="22%" [ngClass.lt-lg] ="'card-column'" fxFlex.lt-md="40%">
        <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'" >
          <img style="height: 25px; vertical-align:top" *ngIf="!mobileScreen" src="/assets/icon/dashboard_sessions_icon.svg"> &nbsp;
          Sessions
        </mat-card-title>
        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
          <app-skeleton-loading Cwidth="250" Cheight="100">
          </app-skeleton-loading>
        </div>
        <mat-card-content class="card-content-config" [ngClass.lt-lg] ="'card-column-content'" >{{totalSessions}}</mat-card-content>
        <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg] ="'card-column-secondary-content'">
          <p>training sessions</p>
        </mat-card-content>
      </mat-card>
      <mat-card class="card-config" fxFlex="22%" [ngClass.lt-lg] ="'card-column'" fxFlex.lt-md="40%">
        <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'">
          <img style="height: 25px; vertical-align:middle" *ngIf="!mobileScreen" src="/assets/icon/Timer_icon.svg"> &nbsp;
          Duration
        </mat-card-title>
        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
          <app-skeleton-loading Cwidth="250" Cheight="100">
          </app-skeleton-loading>
        </div>
        <mat-card-content class="card-content-config" [ngClass.lt-lg] ="'card-column-content'" >{{totalTime}}</mat-card-content>
        <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg] ="'card-column-secondary-content'" >
          <p>training length</p>
          </mat-card-content>
      </mat-card>
      <mat-card *ngIf= "!isKPIRole() && userRole != 'Mersus' && userRole != 'IMT' && userRole != 'IMTLCETB'" 
      class="card-config" fxFlex="22%" [ngClass.lt-lg] ="'card-column'" fxFlex.lt-md="40%">
      <mat-card-title class="card-heading-config" [ngClass.lt-lg] ="'card-column-heading'" >
        <img style="height: 25px; vertical-align:middle" *ngIf="!mobileScreen" src="assets\2.0\AVA_moduleCompletion_icon.svg"> &nbsp;
        Completion
      </mat-card-title>
      <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
        <app-skeleton-loading Cwidth="250" Cheight="100">
        </app-skeleton-loading>
      </div>
      <mat-card-content class="card-content-config" [ngClass.lt-lg] ="'card-column-content'" >{{trainingOutcomes}}</mat-card-content>
      <mat-card-content class="card-secondary-content-config" [ngClass.lt-lg] ="'card-column-secondary-content'" >
        <p>completed modules</p>
      </mat-card-content>
      </mat-card>
      <!-- Mersus Dashboard -->
      <div fxFlex="25%" *ngIf="userRole == 'Mersus'" fxFlex.lt-md="100">
        <div fxLayout="row">
          <mat-form-field class="custom-filter-config" [floatLabel]="'never'" fxFlex="50" fxFlex.lt-md="100">
            <mat-label style="margin: -5% 0% 5% 15%; font-family: 'Libre Franklin';">Custom Filter</mat-label>
            <mat-select [(ngModel)]="selectedValue" name="selectedValue" (selectionChange)="filterClient($event.value)">
              <mat-option class="filter-options-config" *ngFor="let client of clients" [value]="client.value">
                <span class="filter-selected-text">{{client.viewValue}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-icon class="custom-filter-config-arrow">keyboard_arrow_down</mat-icon>
        </div>
        </div>
    </div>
    <!-- License expiry -->
    <div fxLayout="column"  fxLayoutGap="10%" fxFlex="99" *ngIf="showActiveLicense" class="popup" [ngClass.lt-lg]="'popup-smallScreen'">
  <div class="container"  fxLayout="row" fxLayoutAlign="center stretch" [ngClass.lt-md]="'container-lt-md'" fxLayout.lt-md="column" fxLayoutWrap>
    <div fxFlex="50%">
      <br><br>
      <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
      <p style="color: white;font-size:25px;margin: 10px;padding: 30px;" class="font-Libre-Medium1"><strong>Your License is expiring</strong></p>
      <p style="color: white;font-size:25px;margin: 10px;padding: 30px;" class="font-Libre-Medium1"> Your training application is using a license that is due to expire in <strong> {{status}} </strong> days.Please contact your administrator to ensure continued access</p>
      <button class="button"  mat-raised-button type="submit" (click)="hidepopup()">
        <span class="font-Libre-Medium1">OK</span>
      </button>
    </div>
    <div fxFlex="50%" *ngIf="!mobileScreen" fxHide.lt-md>
      <img class="img" src="assets/2.0/AVA_login_imagery.jpg" alt="AA Labs">
    </div>
  </div>

    </div>
    <!-- Graph -->
    <div fxFlex="85" [style.opacity]="fadeOut?'-.2':null" [ngClass]="fadeOut? 'main-container pointer-events': 'main-container'" fxLayout="row wrap" fxLayout.lt-md="row wrap" fxLayoutAlign.lt-md="center stretch" fxFlex>
      <div fxFlex="94" fxLayout="column" fxFlex.lt-md="100" fxLayoutGap="1%">
        <span class="med-text-config" style="font-size: 28px;">Training Usage</span>
        <div fxFlex="5" class="text-config font-Libre-Bold" fxFlex.lt-md="7">
          <button mat-raised-button  (click)="applyCurrentMonthFilter()"class="btn-filter">30 DAYS</button>
          <button mat-raised-button (click)="applyDateFilter()" class="btn-filter">3 MONTHS</button>
          <button mat-raised-button  (click)="applyYearlyFilter()" class="btn-filter">12 MONTHS</button>
          <button mat-raised-button (click)="applyAlltimeFilter()" class="btn-filter">ALL TIME</button>
          <div fxLayout="row">
            <a (click)="showFilter = !showFilter">
              <img src="assets/calendar.png" class="show_div">
            </a>
            <div style="background: rgba(255, 255, 255, 0.5);" class="show_filter" *ngIf="showFilter">
              <select [(ngModel)]="from" style="width: 100px;height: 30px;">
                <option *ngFor="let m of betterMonths" [value]='m.value'>{{m.month}}</option>
              </select>
            &nbsp;
            <select [(ngModel)]="toMonth" style="width: 100px;height: 30px;">
              <option *ngFor="let m1 of betterMonths" [value]='m1.value'>{{m1.month}}</option>
            </select>&nbsp;
            <button class="filter-button" (click)="applyDateFilter1()">Filter Data</button>
            </div>
          </div>
        </div>

        <!-- <div fxFlex="10" class="med-text-config font-Libre-Thin" fxFlex.lt-md="7" fxHide.lt-sm>
          Session per month
        </div> -->
        <div fxFlex="100" fxFlex.lt-md="80">
          <div class="chart-config">
            <div fxHide.lt-md *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center;">
              <app-skeleton-loading Cwidth="1120" Cheight="400">
              </app-skeleton-loading>
            </div>
            <canvas #lineChartCanvas id="line-chart" height="400" fxFlex="100%"></canvas>
          </div>
        </div>
      </div>
  <!-- Client Dashboard -->
      <!-- Comment Module completed table -->
      <!-- <div fxFlex="30" class = "table-config" *ngIf="userRole != 'Mersus'" fxLayout="column" fxFlex.lt-md="100">
        <div *ngIf= "userRole !== 'IMT' && userRole !== 'IMTLCETB' && !isLoading" fxFlex="20" class="mod-text-config" fxFlex.lt-md="100">
          Recently completed modules
        </div>
        <mat-table *ngIf= "userRole !== 'IMT' && userRole !== 'IMTLCETB' && !isLoading" fxFlex="60" [dataSource]="dataSource" fxFlex.lt-md="100">
          <ng-container matColumnDef="Trainee">
            <mat-header-cell class="table-header-font" *matHeaderCellDef> Trainee </mat-header-cell>
            <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Trainee}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Module">
            <mat-header-cell class="table-header-font" *matHeaderCellDef> Module </mat-header-cell>
            <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Module}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Date">
            <mat-header-cell class="table-header-font" *matHeaderCellDef> Date </mat-header-cell>
            <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Date}} </mat-cell>
          </ng-container>

          <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showDetails(row)"></mat-row>
        </mat-table>
        <div fxHide.lt-md *ngIf="isLoading">
          <app-skeleton-loading Cwidth="500" Cheight="420">
          </app-skeleton-loading>
        </div>
        <div fxHide.lg *ngIf="isLoading">
          <app-skeleton-loading Cwidth="500" Cheight="320">
          </app-skeleton-loading>
        </div>
      </div> -->
    </div>
  </div>
</app-main-nav>
<app-expired-liscence *ngIf="showExpiredLicense"></app-expired-liscence>

