<div class="main-background">
    <br><br><br><br><br><br>
    <div class="container" fxLayout="row" fxLayoutAlign="center stretch" [ngClass.lt-md]="'container-lt-md'" fxLayout.lt-md="column" fxLayoutWrap>
      <div fxFlex="50%">
        <br><br><br><br>
        <div fxLayout="column">
            <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
        <br><br>
        <div class="normal-text">
            Enter in a new password below. The password must have <br>
            <div fxLayout="row" class="checkbox-text">
                <div>
                    <mat-checkbox [checked]="!passwordForm.controls.pwd.hasError('minlength') && !(passwordForm.controls.pwd.value.length == 0)" tabindex="-1">Minimum 8 characters</mat-checkbox>
                    <mat-checkbox [checked]="!passwordForm.controls.pwd.hasError('upper') && !passwordForm.controls.pwd.hasError('lower') && !(passwordForm.controls.pwd.value.length == 0)" tabindex="-1">Uppercase & lowercase</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [checked]="!passwordForm.controls.pwd.hasError('nums') && !(passwordForm.controls.pwd.value.length == 0)" tabindex="-1">Numeric character</mat-checkbox>
                    <mat-checkbox [checked]="!passwordForm.controls.pwd.hasError('special') && !(passwordForm.controls.pwd.value.length == 0)" tabindex="-1">Special character</mat-checkbox>
                </div>
            </div>
        </div>
          <form [formGroup]="passwordForm" (ngSubmit)="reset()" novalidate>

              <mat-form-field floatLabel="never" >
                <mat-label class="label-field"> Password </mat-label>
                <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap >
                  <input class="input-text" matInput type="password" formControlName="pwd" [type]="hidepwd ? 'password' : 'text'">
                  <mat-icon matSuffix (click)="hidepwd = !hidepwd" style="vertical-align:middle; padding: 2% 2% 0% 0.5%; transform: scale(1.3); cursor: pointer;">
                    {{hidepwd ? 'visibility_off' : 'visibility'}}
                  </mat-icon>
                </div>
              </mat-form-field>
              <span *ngIf="passwordForm.controls.pwd.touched && passwordForm.controls.pwd.hasError('required')" class="error-message">Password is required.</span>

              <mat-form-field floatLabel="never" >
                <mat-label class="label-field"> Confirm Password</mat-label>
                <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                  <input class="input-text" matInput type="password" formControlName="conpwd" [type]="hideconpwd ? 'password' : 'text'">
                  <mat-icon matSuffix (click)="hideconpwd = !hideconpwd" style="vertical-align:middle; padding: 2% 2% 0% 0.5%; transform: scale(1.3); cursor: pointer;">
                    {{hideconpwd ? 'visibility_off' : 'visibility'}}
                  </mat-icon>
                </div>
              </mat-form-field>

              <span *ngIf="passwordForm.controls.conpwd.touched && passwordForm.controls.conpwd.hasError('required')" class="error-message">Confirm password is required.</span>
              <span *ngIf = "passwordForm.controls.conpwd.hasError('confirmPasswordMatch')" class="error-message">Passwords should match.</span>
              <button class="button"  mat-raised-button type="submit" [disabled]="passwordForm.invalid">
                <span class="font-Libre-Medium">RESET</span>
              </button>
          </form>
        </div>
      </div>
      <div fxFlex="50%" *ngIf="!mobileScreen" fxHide.lt-md>
        <img class="img" src="/assets/2.0/AVA_login_imagery.jpg" alt="AA Labs">
      </div>
    </div>
  </div>
