<app-main-nav>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutWrap>
      <div fxFlex="75">
        <div class="text-config">Boston Scientific Introduction</div>
        <div class="med-text-config">Training Sessions</div>
        <mat-form-field style="min-width: 50%; max-width: 50%;">
          <mat-label style="color: #021B36; opacity: 0.5;"> Type to Search </mat-label>
          <input style="color: #021B36;" (keyup)="applyFilter($event.target.value)" matInput placeholder="Search">
        </mat-form-field>          
      </div>
  
      <div fxFlex="25">
        <button class="download-button" mat-raised-button (click)="downloadCSV()">
          <span>Download CSV</span>
        </button>      
      </div>
    </div>
  
      <!-- <mat-form-field style="min-width: 80%; max-width: 80%;">
        <mat-label style="color: white; opacity: 0.5;"> Type to Search </mat-label>
        <input style="color: white;" (keyup)="applyFilter($event.target.value)" matInput placeholder="Search">
      </mat-form-field>
      <button class="button" mat-raised-button (click)="downloadCSV()">
        <span>Download CSV</span>
      </button> -->
    
      <mat-table class="table-config" [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="Name">
          <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> User Name </mat-header-cell>
          <mat-cell class="table-data-font" *matCellDef="let element">  {{element.Name}} </mat-cell>
        </ng-container>
    
        <ng-container *ngIf="!mobileScreen" matColumnDef="Company">
          <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Company </mat-header-cell>
          <mat-cell class="table-data-font" *matCellDef="let element"> {{element.Company}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="Logged_In">
          <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Logged In </mat-header-cell>
          <mat-cell class="table-data-font" *matCellDef="let element"> {{element.Logged_In}} </mat-cell>
        </ng-container>
    
        <ng-container *ngIf="!mobileScreen" matColumnDef="Session_Duration">
          <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Session Duration </mat-header-cell>
          <mat-cell class="table-data-font" *matCellDef="let element"> {{element.Session_Duration}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="Action">
          <mat-header-cell class="table-header-font"  *matHeaderCellDef > Action </mat-header-cell>
          <mat-cell class="table-data-font" *matCellDef="let row" (click)="view(row)">
            <button class="view-button" mat-button> View
              <!-- View <mat-icon aria-hidden="false" aria-label="Example home icon">visibility</mat-icon> -->
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator class="paginator-class" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    
      </app-main-nav>
    