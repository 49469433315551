import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  SingleAppRoles = ['AVA', 'AVAT', 'AVAP']
  geoChart;
  geoData;

  totalSessions;
  totalTime;
  errors;


  avgTime;
  avgTimeUpdate;
  users;
  usersUpdate;
  location: [];
  userRole;
  userType;
  isSessionLoading = true;
  isUserLoading = true;

  showAddAdminPanel = false;
  viewAdminPanel = false;
  showAddCoursePopup = false;
  selectedValue: string;
  fadeOut = false;
  inviteSent = false;
  existsError = false;
  displayedColumns = ['Name', 'Email', 'Operations'];
  displayedColumnsCourse = ['Name', 'Sessions', 'Users', 'Duration', 'Operations']
  dataSource;
  dataSourceCourse;

  admin;
  clientName;
  clientFullName;

  userToDelete;
  userToEdit;
  rowID;
  fadeButton = false;
  fadeSaveButton = true;
  nameUpdated = false;
  emailUpdated = false;
  nameToUpdate;
  emailToUpdate;
  errorToShow;
  isLoading = true;
  tempData = [];

  maxImgSize: number = 1024 * 1024;
  imageData: string;
  maxWidth: number = 250;
  maxHeight: number = 150;
  selectedImage: string | ArrayBuffer | null = "";
  selectedFileName: string;
  course;
  selectedImageString: string | null = "";
  isDeleteEnabled = false;

  constructor(
    private _httpService: HttpService,
    private _sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private router: Router) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.clientName = this.router.getCurrentNavigation().extras.state.clientName;
      this.clientFullName = this.router.getCurrentNavigation().extras.state.clientFullName;
      localStorage.setItem('client', this.clientName);
    }
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.clientName = localStorage.getItem('client');
    this._httpService.getAuthentication()
      .subscribe(data => {
        this.userType = data.userType;
        this.userRole = data.role;
        if (this.userType == 'Superadmin') {
          if (this.userRole == 'Mersus') {
            this._httpService.getClientSessionAnalyticsV2({ "clientName": this.clientName }).subscribe(data => {

              this.totalSessions = data.totalSessions;
              this.totalTime = data.totalTime;
              this.users = data.userCount;
              this.errors = data.errors;
              this.isSessionLoading = false;
              if (!this.isSARole()) {
                this.updateNonSADetails();
              }
              else {
                this._httpService.getSACourses({ "org": this.clientName }).subscribe(data => {
                  this.dataSourceCourse = data;
                  this.isLoading = false;
                })
              }
            }, error => {
              this.isSessionLoading = false;
              //console.log("Client Session Analytics Error", error);
            })
          }
        }
      }, error => {
        if (error.status == 401) {
          this.router.navigateByUrl("/Logout", { state: { error: '401' } });
        }
      })
  }

  barChartConfigGeoLocation(graphLabels, values) {
    this.geoChart = new Chart('geo-chart', {
      type: 'horizontalBar',
      data: {
        labels: graphLabels,
        datasets: [
          {
            backgroundColor: ["#007d6f", "#007d6f", "#007d6f", "#007d6f", "#007d6f"],
            maxBarThickness: 60,
            data: values
          }
        ]
      },
      options: {
        legend: { display: false },
        scales: {
          yAxes: [{
            gridLines: {
              display: false
            }
          }],
          xAxes: [{
            display: false,
            gridLines: {
              display: false
            }
          }]
        },
        maintainAspectRatio: false
      }
    });
  }

  dropdownOptions(selection) {
    this.selectedValue = selection;
    if (this.selectedValue == 'add') {
      this.showAddAdminPanel = true;
      this.viewAdminPanel = false;
      this.fadeOut = true;
    }
    if (this.selectedValue == 'view') {
      this._httpService.getAdminsTable({ "client": this.clientName }).subscribe(data => {
        this.dataSource = new MatTableDataSource(data.adminData);
      }, error => {
        //console.log("getAdminsTable Error", error);
      })

      this.viewAdminPanel = true;
      this.showAddAdminPanel = false;
      this.fadeOut = true;
    }
  }

  closePopup() {
    this.showAddAdminPanel = false;
    this.viewAdminPanel = false;
    this.fadeOut = false;
    this.inviteSent = false;
    this.selectedValue = '';
    this.rowID = '';
    this.fadeButton = false;
    this.fadeSaveButton = true;
    this.existsError = false;
    this.showAddCoursePopup = false;
    this.courseForm.reset();
    this.personalForm.reset();
    this.selectedImage = null;
    this.selectedFileName = null;
  }

  public personalForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    fname: ['', Validators.compose([Validators.required])],
    lname: ['', Validators.compose([Validators.required])]
  }, {
  });

  public courseForm = this.formBuilder.group({
    courseName: ['', Validators.compose([Validators.required])]
  });

  addAdmin() {
    this.admin = {
      "email": this.personalForm.controls['email'].value,
      "fname": this.personalForm.controls['fname'].value,
      "lname": this.personalForm.controls['lname'].value,
      "registerTime": new Date().toLocaleString('en-GB', { hour12: false }).replace(',', ''),
      "orgName": this.clientName
    }
    this._httpService.addAdmin(this.admin)
      .subscribe(data => {
        this.inviteSent = true;
      }, error => {
        this.existsError = true;
        if (error = 'User Exist') {
          this.errorToShow = "The email address already exists.";
        }
        else {
          this.errorToShow = "Something went wrong. Please try again after sometime.";
        }
        // console.log("Add Admin Error", error);
      })
    this.inviteSent = false;
    this.existsError = false;
  }

  deleteRow(rowDetails) {
    this.userToDelete = {
      "userID": rowDetails.userID
    }
    Swal.fire({
      title: 'Are you sure you want to delete this admin?',
      text: 'You will not be able to revert this action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this._httpService.deleteAdmin(this.userToDelete)
          .subscribe(data => {
            if (data == 'Success') {
              Swal.fire({
                title: 'Admin deleted!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#021B36'
              }).then(() => {
                window.location.reload();
              });
            }
            else if (data == "User doesn't exist") {
              Swal.fire({
                title: "Admin doesn't exist",
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#021B36'
              });
            }
          }, error => {
            // console.log("Delete Admin Error", error);
          })
      }
    });
  }

  makeEditable(rowDetails) {
    this.rowID = rowDetails.userID;
    this.fadeButton = true;
    this.fadeSaveButton = false;
  }

  onNameChange(name) {
    this.nameUpdated = true;
    this.nameToUpdate = name;
  }

  onEmailChange(email) {
    this.emailUpdated = true;
    this.emailToUpdate = email;
  }

  editRow(rowDetails) {
    if (this.nameToUpdate == undefined && this.emailToUpdate == undefined) {
      this.fadeButton = false;
      this.rowID = '';
      return;
    }
    //The functionality exists but we aren't allowing updating email ID
    if (this.nameToUpdate != undefined && this.emailToUpdate != undefined) {
      this.userToEdit = {
        "userID": rowDetails.userID,
        "fname": this.nameToUpdate.trim().split(' ')[0],
        "lname": this.nameToUpdate.trim().split(' ')[1],
        "email": this.emailToUpdate.trim(),
        "toUpdate": 'both'
      }
    }
    else if (this.nameToUpdate == undefined && this.emailToUpdate != undefined) {
      this.userToEdit = {
        "userID": rowDetails.userID,
        "email": this.emailToUpdate.trim(),
        "toUpdate": 'email'
      }
    }
    else if (this.emailToUpdate == undefined && this.nameToUpdate != undefined) {
      this.userToEdit = {
        "userID": rowDetails.userID,
        "fname": this.nameToUpdate.trim().split(' ')[0],
        "lname": this.nameToUpdate.trim().split(' ')[1],
        "toUpdate": 'name'
      }
    }

    this._httpService.editAdmin(this.userToEdit)
      .subscribe(data => {
        Swal.fire({
          title: 'Admin details updated!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#021B36'
        }).then(() => {
          this.closePopup();
        });
      }, error => {
        // console.log("Edit Admin Error", error);
      })
  }

  openCoursePopup() {
    this.showAddCoursePopup = true;
    this.fadeOut = true;
  }

  addCourse() {
    this.course = {
      "name": this.courseForm.controls['courseName'].value,
      "image": this.selectedImageString,
      "org": this.clientName
    }
    this._httpService.addSACourses(this.course)
      .subscribe(data => {
        window.location.reload();
      }, error => {
        this.existsError = true;
        if (error = 'Course Exist') {
          this.errorToShow = "The course already exists.";
        }
        else {
          this.errorToShow = "Something went wrong. Please try again after sometime.";
        }
        // console.log("Add Trainees Error", error);
      })
  }

  editCourse(row) {
    if (this.isDeleteEnabled) {
      return;
    }
    this.router.navigateByUrl("/CourseDetail", { state: { courseName: row.Name, courseID: row.CourseID } });
  }

  deleteCourse(row: any) {
    this.isDeleteEnabled = true;
    Swal.fire({
      title: 'Are you sure you want to delete this course?',
      text: 'You will not be able to revert this action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this._httpService.deleteSACourses({ "courseID": row.CourseID }).subscribe(
          (data) => {
            window.location.reload();
            this.isDeleteEnabled = false;
          },
          (error) => {
            console.error('Error deleting course:', error);
            this.isDeleteEnabled = false;
          }
        );
      } else {
        // Reset `isDeleteEnabled` if the user cancels deletion
        this.isDeleteEnabled = false;
      }
    });
  }

  isSARole(): boolean {
    return this.SingleAppRoles.includes(this.clientName);
  }

  updateNonSADetails() {
    var temp = [];
    var nonSACourseData =
    {
      Name: "Hakuna Matata",
      Users: this.users,
      Duration: this.totalTime,
      Sessions: this.totalSessions
    }

    switch (this.clientName) {
      case 'VIS':
        nonSACourseData.Name = "Catheter Assembly";
        break;
      case 'BOS':
        nonSACourseData.Name = "Line Clearance";
        break;
      case 'FPT':
        nonSACourseData.Name = "Injection Moulding";
        break;
      case 'RA':
        nonSACourseData.Name = "Introduction to Robotics Arm";
        break;
      case 'JAN':
        nonSACourseData.Name = "Liner Changeout";
        break;
      case 'DEP':
        nonSACourseData.Name = "Powder Coating";
        break;
      case 'IMT':
        nonSACourseData.Name = "Imtech Skills Quiz";
        break;
      case 'IMTLCETB':
        nonSACourseData.Name = "Imtech LCETB Skills Quiz";
        break;
    }

    temp.push(nonSACourseData);

    this.dataSourceCourse = temp;
    this.isLoading = false;
  }

  onFileSelected(event: any) {
    this.existsError = false;
    const file: File = event.target.files[0];
    this.selectedFileName = event.target.files[0].name;
    if (file.size > this.maxImgSize) {
      this.existsError = true;
      this.errorToShow = "File size exceeds the maximum limit.";
      //console.error('File size exceeds the maximum limit.');
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const imgDataString = reader.result as string;
      this.selectedImageString = imgDataString;
      this.resizeImage(imgDataString);
    };
    reader.readAsDataURL(file);
  }

  resizeImage(imgData) {
    const image = new Image();
    image.onload = () => {
      this.imageData = imgData;
      if (image.width > this.maxWidth || image.height > this.maxHeight) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        canvas.width = this.maxWidth;
        canvas.height = this.maxHeight;
        ctx.drawImage(image, 0, 0, this.maxWidth, this.maxHeight);
        this.selectedImage = canvas.toDataURL('image/jpeg');
      }
    }
    image.src = imgData;
  }
}