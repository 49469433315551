import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {
  @ViewChild('courseFileInput') courseFileInput!: ElementRef;
  fadeOut = false;
  courseName = "";
  courseImage;
  showAddModulePopup = false;
  isLoading = true;
  selectedImage: string | ArrayBuffer | null = "";
  selectedModuleImage: string | ArrayBuffer | null = "";
  selectedFileName: string;
  module;
  courseID;
  moduleImage;
  errorToShow;
  existsError = false;

  selectedModuleImageString: string | null = "";
  selectedImageString: string | null = "";
  maxImgSize: number = 1024 * 1024;
  imageData: string;
  maxWidth: number = 250;
  maxHeight: number = 150;

  moduleDetail = [];

  isEdit = false;
  initialFormValues: any;
  currentModuleFormValues: any;
  updatedImage;
  showEditModulePopup = false;
  moduleID;

  constructor(
    private _httpService: HttpService,
    private _sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private router: Router) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.courseName = this.router.getCurrentNavigation().extras.state.courseName;
      this.courseID = this.router.getCurrentNavigation().extras.state.courseID;
      localStorage.setItem('course', this.courseName);
      localStorage.setItem('courseID', this.courseID);
    }
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.courseID = localStorage.getItem('courseID');
    this.courseName = localStorage.getItem('course');
    this._httpService.getAuthentication()
      .subscribe(data => {
        var body = {
          "CourseID": this.courseID
        }
        this._httpService.getSACourseData(body).subscribe(data => {
          if (data) {
            this.courseForm.setValue({
              sheetID: data.SheetID ? data.SheetID : null,
              dbname: data.DBName ? data.DBName : null,
              analytics: data.Endpoint ? data.Endpoint : null,
              token: data.AccessToken ? data.AccessToken : null,
              content: data.ContentEndpoint ? data.ContentEndpoint : null,
              numberOfModules: data.NumberOfModules ? data.NumberOfModules : null
            });
            if (data.CourseImage) {
              if (!data.CourseImage.startsWith('data:image')) {
                if (data.CourseImage.startsWith('iVBOR')) {
                  this.courseImage = `data:image/png;base64,${data.CourseImage}`;
                } else if (data.CourseImage.startsWith('/9j/')) {
                  this.courseImage = `data:image/jpeg;base64,${data.CourseImage}`;
                }
              }
              else {
                this.courseImage = `${data.CourseImage}`;
              }
            }
            this.initialFormValues = this.courseForm.value;
            this.courseForm.disable();
          } else {
            console.error('No data received');
            this.courseForm.enable();
          }
        })
        this._httpService.getSAModules(body).subscribe(data => {
          this.moduleDetail = data.map(module => {
            if (module.img) {
              if (!module.img.startsWith('data:image')) {
                if (module.img.startsWith('iVBOR')) {
                  module.img = `data:image/png;base64,${module.img}`;
                } else if (module.img.startsWith('/9j/')) {
                  module.img = `data:image/jpeg;base64,${module.img}`;
                }
              }
              else {
                module.img = `${module.img}`;
              }
            }
            return module;
          });
          this.isLoading = false;
        })
      }, error => {
        if (error.status == 401) {
          this.router.navigateByUrl("/Logout", { state: { error: '401' } });
        }
        else {
          console.log(error)
        }
      })
  }

  public courseForm = this.formBuilder.group({
    sheetID: ['',],
    dbname: ['',],
    analytics: ['',],
    token: ['',],
    content: ['',],
    numberOfModules: 0
  });

  public moduleForm = this.formBuilder.group({
    moduleName: ['', Validators.compose([Validators.required])],
    refName: ['', Validators.compose([Validators.required, Validators.pattern(/^M[1-9]\d*$/)])],
    hasExplainer: false,
    hasPractice: false,
    hasDemonstration: false,
    hasExam: false
  });

  openModulePopup() {
    this.showAddModulePopup = true;
    this.fadeOut = true;
  }

  addModule() {
    this.module = {
      "CourseName": this.courseName,
      "ModuleName": this.moduleForm.controls['moduleName'].value,
      "ModuleRef": this.moduleForm.controls['refName'].value,
      "ModuleImage": this.selectedImageString,
      "Explainer": this.moduleForm.controls['hasExplainer'].value,
      "Practice": this.moduleForm.controls['hasPractice'].value,
      "Demonstration": this.moduleForm.controls['hasDemonstration'].value,
      "Exam": this.moduleForm.controls['hasExam'].value
    }
    this._httpService.addSAModules(this.module)
      .subscribe(data => {
        window.location.reload();
      }, error => {
        this.existsError = true;
        if (error = 'Module Exist') {
          this.errorToShow = "The module already exists.";
        }
        else {
          this.errorToShow = "Something went wrong. Please try again after sometime.";
        }
        // console.log("Add Trainees Error", error);
      })
  }

  openEditModulePopup(module) {
    this.moduleForm.patchValue({
      moduleName: module.name, refName: module.ref,
      hasExplainer: module.hasExplainer, hasPractice: module.hasPractice,
      hasDemonstration: module.hasDemonstration, hasExam: module.hasExam
    });
    if (module.img != null)
      this.selectedModuleImage = module.img;
    this.showEditModulePopup = true;
    this.fadeOut = true;
    this.moduleID = module.id;
    this.currentModuleFormValues = this.moduleForm.value;
  }

  editModule() {
    const isChanged = JSON.stringify(this.currentModuleFormValues) !== JSON.stringify(this.moduleForm.value);
    var isImgChanged = false;
    if (this.selectedImage != "") {
      isImgChanged = this.selectedModuleImage != this.selectedModuleImageString;
    }

    if (isChanged && isImgChanged) {
      this.module = {
        "toUpdate": "All",
        "ModuleID": this.moduleID,
        "ModuleName": this.moduleForm.controls['moduleName'].value,
        "ModuleImage": this.selectedModuleImageString,
        "Explainer": this.moduleForm.controls['hasExplainer'].value,
        "Practice": this.moduleForm.controls['hasPractice'].value,
        "Demonstration": this.moduleForm.controls['hasDemonstration'].value,
        "Exam": this.moduleForm.controls['hasExam'].value
      }
    }
    else if (isChanged) {
      this.module = {
        "toUpdate": "Form",
        "ModuleID": this.moduleID,
        "ModuleName": this.moduleForm.controls['moduleName'].value,
        "Explainer": this.moduleForm.controls['hasExplainer'].value,
        "Practice": this.moduleForm.controls['hasPractice'].value,
        "Demonstration": this.moduleForm.controls['hasDemonstration'].value,
        "Exam": this.moduleForm.controls['hasExam'].value
      }
    }
    else if (isImgChanged) {
      this.module = {
        "toUpdate": "Image",
        "ModuleID": this.moduleID,
        "ModuleImage": this.selectedModuleImageString
      }
    }

    this._httpService.updateSAModules(this.module)
      .subscribe(data => {
        window.location.reload();
      }, error => {
        this.existsError = true;
        this.errorToShow = "The details couldn't be updated.";
      })
  }

  deleteModuleImage() {
    Swal.fire({
      title: 'Are you sure you want to delete the module image?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedModuleImage = null;
        this.selectedFileName = "";
        this.module = {
          "ModuleID": this.moduleID,
          "Image": ""
        }
        this._httpService.updateSAModuleImage(this.module)
      .subscribe(data => {
        window.location.reload();
      }, error => {
        this.existsError = true;
        this.errorToShow = "The details couldn't be updated.";
      })
      }
    });
  }

  deleteModule(module) {
    Swal.fire({
      title: 'Are you sure you want to delete the module?',
      text: 'You will not be able to revert this action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        var body = {
          "ModuleID": module.id
        }
        this._httpService.deleteSAModule(body).subscribe(data => {
          if (data == true)
            window.location.reload();
          else {
            Swal.fire({
              title: 'Module could not be deleted. Try again later!',
              icon: 'error',
              showCancelButton: true,
              confirmButtonColor: '#021B36',
              cancelButtonColor: '#50555a',
              confirmButtonText: 'Okay',
              cancelButtonText: 'Cancel'
            })
          }
        })
      }
    });
  }

  closePopup() {
    this.fadeOut = false;
    this.showAddModulePopup = false;
    this.showEditModulePopup = false;
    //this.courseForm.reset();
    this.moduleForm.reset();
    this.selectedImage = null;
    this.selectedFileName = null;
    this.selectedModuleImageString = null;
  }

  //Refactor these methods later. Lot of confusion between the usage of different selectedimages and strings in my head
  onFileSelected(event: any) {
    this.existsError = false;
    const file: File = event.target.files[0];
    this.selectedFileName = event.target.files[0].name;
    if (file.size > this.maxImgSize) {
      this.existsError = true;
      this.errorToShow = "File size exceeds the maximum limit.";
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const imgDataString = reader.result as string;
      this.resizeImage(imgDataString);
    };
    reader.readAsDataURL(file);
  }

  resizeImage(imgData) {
    const image = new Image();
    image.onload = () => {
      this.imageData = imgData;
      if (image.width > this.maxWidth || image.height > this.maxHeight) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        canvas.width = this.maxWidth;
        canvas.height = this.maxHeight;
        ctx.drawImage(image, 0, 0, this.maxWidth, this.maxHeight);
        this.selectedImageString = imgData;
        this.selectedImage = canvas.toDataURL('image/jpeg');
        this.selectedModuleImageString = imgData;
        this.selectedModuleImage = canvas.toDataURL('image/jpeg');
      }
    }
    image.src = imgData;
  }

  triggerFileInput() {
    this.courseFileInput.nativeElement.click();
  }

  replaceCourseImage(event: any) {
    this.existsError = false;
    const file: File = event.target.files[0];
    if (file.size > this.maxImgSize) {
      this.existsError = true;
      this.errorToShow = "File size exceeds the maximum limit.";
      //console.error('File size exceeds the maximum limit.');
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const imgDataString = reader.result as string;
      this.updatedImage = imgDataString;
      this.resizeImage(imgDataString);
    };

    Swal.fire({
      title: 'Are you sure you want to update the course image?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        var body = {
          "CourseID": this.courseID,
          "Image": this.updatedImage
        }
        this._httpService.updateSACourseImage(body).subscribe(data => {
          if (data == true)
            window.location.reload();
          else {
            Swal.fire({
              title: 'Image could not be updated. Try again later!',
              icon: 'error',
              showCancelButton: true,
              confirmButtonColor: '#021B36',
              cancelButtonColor: '#50555a',
              confirmButtonText: 'Okay',
              cancelButtonText: 'Cancel'
            })
          }
        })
      }
    });
    reader.readAsDataURL(file);
  }

  deleteCourseImage() {
    Swal.fire({
      title: 'Are you sure you want to delete the course image?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#021B36',
      cancelButtonColor: '#50555a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        var body = {
          "CourseID": this.courseID,
          "Image": ""
        }
        this._httpService.updateSACourseImage(body).subscribe(data => {
          if (data == true)
            window.location.reload();
          else {
            Swal.fire({
              title: 'Image could not be updated. Try again later!',
              icon: 'error',
              showCancelButton: true,
              confirmButtonColor: '#021B36',
              cancelButtonColor: '#50555a',
              confirmButtonText: 'Okay',
              cancelButtonText: 'Cancel'
            })
          }
        })
      }
    });
  }

  editCourseDetails() {
    this.courseForm.enable();
    this.isEdit = true;
  }

  saveCourseDetails() {
    const isChanged = JSON.stringify(this.initialFormValues) !== JSON.stringify(this.courseForm.value);
    if (isChanged) {
      Swal.fire({
        title: 'Are you sure you want to update the course details?',
        text: 'You will not be able to revert this action!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#021B36',
        cancelButtonColor: '#50555a',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          var body = {
            "CourseID": this.courseID,
            "SheetID": this.courseForm.controls.sheetID.value,
            "Endpoint": this.courseForm.controls.analytics.value,
            "DBName": this.courseForm.controls.dbname.value,
            "ContentEndpoint": this.courseForm.controls.content.value,
            "NumberOfModules": this.courseForm.controls.numberOfModules.value,
            "AccessToken": this.courseForm.controls.token.value
          }
          this._httpService.addSACourseData(body).subscribe(data => {
            window.location.reload();
          })
        }
      });
    }
    else {
      Swal.fire({
        title: 'No changes detected',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#021B36',
        cancelButtonColor: '#50555a',
        confirmButtonText: 'Okay',
        cancelButtonText: 'Cancel'
      })
    }
    this.courseForm.disable();
    this.isEdit = false;
  }
}
