import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/v2/shared/service/http.service';
import { SharedService } from 'src/app/v2/shared/service/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-details',
  templateUrl: './my-details.component.html',
  styleUrls: ['./my-details.component.scss']
})
export class MyDetailsComponent implements OnInit {
  SingleAppRoles = ['AVA', 'AVAT', 'AVAP']
  hidepwd = true;
  hidenewpwd = true;
  hideconpwd = true;
  hidepin = true;
  hidenewpin = true;
  hideconpin = true;
  formSubmitted = false;
  formError = false;
  noChanges = false;
  changesMadeTo;
  traineeData = {
    userID: null,
    oldpwd: null,
    newpwd: null,
    oldPIN: null,
    newPIN: null,
    userName: null,
    gender: null,
    age: null
  };
  userID;
  name;
  selectedGender = null;
  selectedAge = null;
  errorMessage;

  userRole = null;
  userType = null;

  defaultName;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _httpService: HttpService,
    private _sharedService: SharedService
  ) { }

  ngOnInit() {
    this._httpService.getAuthentication()
      .subscribe(data => {
        this.userType = data.userType;
        this.userRole = data.role;
        this._sharedService.setRole(data.role);
        if (this.userType == 'User') {
          this.userID = localStorage.getItem('userid');
          this.defaultName = localStorage.getItem('name');
          // this.personalForm.patchValue({
          //   name: localStorage.getItem('name'),
          // });
        }
      })

    if (this.SingleAppRoles.includes(this._sharedService.getRole())) {
      this._httpService.getSATraineeDetails({ "userID": localStorage.getItem('userid') })
        .subscribe(data => {
          this.personalForm.patchValue({
            username: data.userName,
            gender: data.gender,
            age: data.age
          });
        });
    }
    //#region Non Single App Roles
    if (this._sharedService.getRole() == 'BOS') {
      this._httpService.getTraineeDetails('BOS3', localStorage.getItem('userid'))
        .subscribe(data => {
          this.personalForm.patchValue({
            username: data.userName,
            gender: data.gender,
            age: data.age
          });
        });
    }
    if (this._sharedService.getRole() == 'FPT') {
      this._httpService.getTraineeDetails('FPT', localStorage.getItem('userid'))
        .subscribe(data => {
          this.personalForm.patchValue({
            username: data.userName,
            gender: data.gender,
            age: data.age
          });
        });
    }
    if (this._sharedService.getRole() == 'JAN') {
      this._httpService.getTraineeDetails('JAN', localStorage.getItem('userid'))
        .subscribe(data => {
          this.personalForm.patchValue({
            username: data.userName,
            gender: data.gender,
            age: data.age
          });
        });
    }
    if (this._sharedService.getRole() == 'DEP') {
      this._httpService.getTraineeDetails('DEP', localStorage.getItem('userid'))
        .subscribe(data => {
          this.personalForm.patchValue({
            username: data.userName,
            gender: data.gender,
            age: data.age
          });
        });
    }
    if (this._sharedService.getRole() == 'RA') {
      this._httpService.getTraineeDetails('RA', localStorage.getItem('userid'))
        .subscribe(data => {
          this.personalForm.patchValue({
            username: data.userName,
            gender: data.gender,
            age: data.age
          });
        });
    }
    //#endregion
  }

  public passwordForm = this.formBuilder.group({
    pwd: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    conpwd: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    newpwd: ['', Validators.compose([Validators.required, Validators.minLength(8),
    this.regexValidator(new RegExp('[0-9]'), { 'nums': true }),
    this.regexValidator(new RegExp('[a-z]'), { 'lower': true }),
    this.regexValidator(new RegExp('[A-Z]'), { 'upper': true }),
    this.regexValidator(new RegExp('[-\/:-@\[-\`{-~!()&*£$%#<>.]'), { 'special': true })
    ])]
  }, {
    validators: [this.errorIfNotEqual('newpwd', 'conpwd', { confirmPasswordMatch: true }),
    this.errorIfEqual('pwd', 'newpwd', { checkOldNewPasswordMatch: true })]
  }
  );

  public pinForm = this.formBuilder.group({
    pin: ['', Validators.compose([Validators.required, Validators.minLength(4),
    this.regexValidator(new RegExp('[0-9]'), { 'nums': true }),
    ])],
    conpin: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
    newpin: ['', Validators.compose([Validators.required, Validators.minLength(4),
    this.regexValidator(new RegExp('[0-9]'), { 'nums': true })
    ])]
  }, {
    validators: [this.errorIfEqual('pin', 'newpin', { checkOldNewPINMatch: true }),
    this.errorIfNotEqual('newpin', 'conpin', { confirmPINMatch: true })]
  });

  public personalForm = this.formBuilder.group({
    // name : ['', {disabled:true}],
    username: ['',],
    age: ['',],
    gender: ['',]
  }, {
  });

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    }
  }

  errorIfNotEqual(controlName: string, matchingControlName: string, error: ValidationErrors): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors => {
      const control = formGroup.controls[controlName]!;
      const matchingControl = formGroup.controls[matchingControlName]!;
      if (matchingControl.errors) {
        return null;
      }
      let returnError = null;
      if (control.value !== matchingControl.value) {
        returnError = error
      }
      matchingControl.setErrors(returnError);
      return returnError;
    }
  }

  errorIfEqual(controlName: string, matchingControlName: string, error: ValidationErrors) {
    return (formGroup: UntypedFormGroup): ValidationErrors => {
      const control = formGroup.controls[controlName]!;
      const matchingControl = formGroup.controls[matchingControlName]!;
      if (matchingControl.errors) {
        return null;
      }
      let returnError = null;
      if (control.value === matchingControl.value) {
        returnError = error
      }
      matchingControl.setErrors(returnError);
      return returnError;
    }
  }

  update() {
    if (!this.passwordForm.touched && !this.pinForm.touched && !this.personalForm.touched)
      return;
    if (!this.passwordForm.dirty && !this.pinForm.dirty && !this.personalForm.dirty) {
      this.noChanges = true;
      return;
    }
    else {
      this.noChanges = false;
    }
    var changesArray = new Array();
    this.traineeData["userID"] = this.userID;
    if (this.passwordForm.touched && this.passwordForm.valid) {
      if (!changesArray.includes('password')) changesArray.push('password');

      this.traineeData["oldpwd"] = this.passwordForm.controls['pwd'].value;
      this.traineeData["newpwd"] = this.passwordForm.controls['newpwd'].value;
    }
    if (this.pinForm.touched && this.pinForm.valid) {
      if (!changesArray.includes('PIN')) changesArray.push('PIN');

      this.traineeData["oldPIN"] = this.pinForm.controls['pin'].value;
      this.traineeData["newPIN"] = this.pinForm.controls['newpin'].value;
    }
    if (this.personalForm.touched && this.personalForm.valid) {
      if (!changesArray.includes('personal information')) changesArray.push('personal information');

      if (this.personalForm.controls.username.touched && this.personalForm.controls.username.valid)
        this.traineeData["userName"] = this.personalForm.controls['username'].value;
      if (this.personalForm.controls.gender.touched)
        this.traineeData["gender"] = this.selectedGender;
      if (this.personalForm.controls.age.touched)
        this.traineeData["age"] = this.selectedAge;
    }

    if (this.SingleAppRoles.includes(this._sharedService.getRole())) {
      this._httpService.updateSATraineeDetails(this.traineeData)
        .subscribe(data => {
          if (data == 'Invalid PIN' || data == 'Invalid password') {
            this.errorMessage = data;
            this.formError = true;
            this.formSubmitted = false;
          }
          else {
            this.formSubmitted = true;
            this.formError = false;
            this.changesMadeTo = changesArray;
          }
          this.ngOnInit();
        }, error => {
          // console.log("Trainee Mydetails Error", error);
        })
    }
    //#region Non Single App Roles
    if (this._sharedService.getRole() == 'BOS') {
      this._httpService.updateTraineeDetails('BOS3', this.traineeData)
        .subscribe(data => {
          if (data == 'Invalid PIN' || data == 'Invalid password') {
            this.errorMessage = data;
            this.formError = true;
            this.formSubmitted = false;
          }
          else {
            this.formSubmitted = true;
            this.formError = false;
            this.changesMadeTo = changesArray;
          }
          this.ngOnInit();
        }, error => {
          // console.log("Trainee Mydetails Error", error);
        })
    }
    if (this._sharedService.getRole() == 'FPT') {
      this._httpService.updateTraineeDetails('FPT', this.traineeData)
        .subscribe(data => {
          if (data == 'Invalid PIN' || data == 'Invalid password') {
            this.errorMessage = data;
            this.formError = true;
            this.formSubmitted = false;
          }
          else {
            this.formSubmitted = true;
            this.formError = false;
            this.changesMadeTo = changesArray;
          }
          this.ngOnInit();
        }, error => {
          // console.log("Trainee Mydetails Error", error);
        })
    }
    if (this._sharedService.getRole() == 'JAN') {
      this._httpService.updateTraineeDetails('JAN', this.traineeData)
        .subscribe(data => {
          if (data == 'Invalid PIN' || data == 'Invalid password') {
            this.errorMessage = data;
            this.formError = true;
            this.formSubmitted = false;
          }
          else {
            this.formSubmitted = true;
            this.formError = false;
            this.changesMadeTo = changesArray;
          }
          this.ngOnInit();
        }, error => {
          // console.log("Trainee Mydetails Error", error);
        })
    }
    if (this._sharedService.getRole() == 'DEP') {
      this._httpService.updateTraineeDetails('DEP', this.traineeData)
        .subscribe(data => {
          if (data == 'Invalid PIN' || data == 'Invalid password') {
            this.errorMessage = data;
            this.formError = true;
            this.formSubmitted = false;
          }
          else {
            this.formSubmitted = true;
            this.formError = false;
            this.changesMadeTo = changesArray;
          }
          this.ngOnInit();
        }, error => {
          // console.log("Trainee Mydetails Error", error);
        })
    }
    if (this._sharedService.getRole() == 'RA') {
      this._httpService.updateTraineeDetails('RA', this.traineeData)
        .subscribe(data => {
          if (data == 'Invalid PIN' || data == 'Invalid password') {
            this.errorMessage = data;
            this.formError = true;
            this.formSubmitted = false;
          }
          else {
            this.formSubmitted = true;
            this.formError = false;
            this.changesMadeTo = changesArray;
          }
          this.ngOnInit();
        }, error => {
          // console.log("Trainee Mydetails Error", error);
        })
    }
    //#endregion
    this.resetForm();
  }

  cancel() {
    this.resetForm();
    const role = this._sharedService.getRole();
    const userId = localStorage.getItem('userid');

    this.getTraineeDetails(role, userId);
  }

  getTraineeDetails(role, userId) {
    let httpServiceMethod;

    if (this.SingleAppRoles.includes(role)) {
      httpServiceMethod = this._httpService.getSATraineeDetails(userId);
    }
    else {
      switch (role) {
        case 'BOS':
          httpServiceMethod = this._httpService.getTraineeDetails('BOS3', userId);
          break;
        case 'FPT':
          httpServiceMethod = this._httpService.getTraineeDetails('FPT', userId);
          break;
        case 'JAN':
          httpServiceMethod = this._httpService.getTraineeDetails('JAN', userId);
          break;
        case 'DEP':
          httpServiceMethod = this._httpService.getTraineeDetails('DEP', userId);
          break;
        case 'RA':
          httpServiceMethod = this._httpService.getTraineeDetails('RA', userId);
          break;
        default:
          return;
      }
    }

    httpServiceMethod.subscribe(data => {
      this.personalForm.patchValue({
        username: data.userName,
        gender: data.gender,
        age: data.age
      });
    });
  }

  resetForm() {
    this.formSubmitted = false;
    this.formError = false;
    this.noChanges = false;
    const keepValues = [
      this.personalForm.controls.username.value,
      this.personalForm.controls.gender.value,
      this.personalForm.controls.age.value
    ];
    this.passwordForm.reset();
    this.pinForm.reset();
    this.personalForm.reset();
    this.personalForm.controls.username.patchValue(keepValues[0]);
    this.personalForm.controls.gender.patchValue(keepValues[1]);
    this.personalForm.controls.age.patchValue(keepValues[2]);

    this.traineeData = {
      userID: null,
      oldpwd: null,
      newpwd: null,
      oldPIN: null,
      newPIN: null,
      userName: null,
      gender: null,
      age: null
    };
  }
}
