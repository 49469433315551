import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
// import {HttpService} from '../../../service/http.service';
// Export CSV : https://www.npmjs.com/package/export-to-csv
import { ExportToCsv } from 'export-to-csv';
// import { SharedService } from '../../../service/shared.service';


@Component({
  selector: 'app-bos002',
  templateUrl: './bos002.component.html',
  styleUrls: ['./bos002.component.scss']
})
export class Bos002Component{

  constructor(
    private router: Router,
    // private _httpService: HttpService,
    // private _sharedService: SharedService
  ) {  }
  mobileScreen = false;
  displayedColumns: string[];
  dataSource;
  courseName;

  // Objects for CVS export
  // CSV config
  options = {
    filename : "BOS_Data",
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'BOS_Data - ' + new Date(),
    useTextFile: false,
    useBom: true,
    // useKeysAsHeaders: true,
    headers: ["Name","Company Name","Session Login Date","Session Duration", "Start Time", "End Time"]
  };
  // CSV object
  csvDataSource;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // ngOnInit() {
  //   if(window.innerWidth <= 575){
  //     this.mobileScreen = true;
  //     this.displayedColumns = ['Name', 'Logged_In', 'Action'];
  //   }else {
  //     this.displayedColumns = ['Name', 'Company', 'Logged_In', 'Session_Duration', 'Action'];
  //   }
  //   // console.log("this._sharedService.getRole(): " + this._sharedService.getRole());
  //   if(this._sharedService.getRole() == "BOS" || this._sharedService.getRole() == "Mersus"){
  //     this._httpService.getBOS002Data()
  //     .subscribe(data=>{
  //       this.dataSource = new MatTableDataSource(data);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;
  //       // console.log(data);
  //       // CSV Data
  //       this.csvDataSource = data;
  //       // console.log("this.csvDataSource: ", this.csvDataSource);
  //     }, error =>{
  //       // console.log("FIT_002  Error");
  //     })  
  //   }else {
  //     this.router.navigateByUrl("/Logout");
  //   }
  // }

  // Navigate to Trainees page
  view(userData) {
    // console.log(userData);
    this.router.navigateByUrl("/Trainees", { state: { scoreID: userData.ID, courseName: "BOS"} });
  }

  applyFilter(filterValue: string) {
    // console.log("Check-> ", filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // Download CSV
  downloadCSV(){
    this.csvDataSource.forEach(function(val){ delete val.ID });
    // console.log("Download CSV");
    const csvExporter = new ExportToCsv(this.options);
    csvExporter.generateCsv(this.csvDataSource);
  }
}
