<app-main-nav>
  <br>
  
  <div class="text-config">Update Password</div>
  <br><br><br><br>
  <mat-card>
    <form [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword()" novalidate>

      <mat-card-content>
        <mat-form-field floatLabel="always">
          <mat-label class="label-field"> Current Password: </mat-label> <br>
          <input class="input-field" matInput type="password" formControlName="Current_Password">
        </mat-form-field>
      </mat-card-content>

      <mat-card-content>
        <mat-form-field floatLabel="always">
          <mat-label class="label-field"> New Password: </mat-label> <br>
          <input class="input-field" matInput type="password" formControlName="New_Password">
        </mat-form-field>
      </mat-card-content>

      <mat-card-content>
        <mat-form-field floatLabel="always">
          <mat-label class="label-field"> Confirm New Password: </mat-label> <br>
          <input class="input-field" matInput type="password" formControlName="Confirm_New_Password">
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions>
        <span *ngIf = "invalidPassword" class="error-message">{{invalidPasswordText}}</span>
        <button class="button" [disabled]="!updatePasswordForm.valid"  mat-raised-button type="submit">
          <span>Update</span>
        </button>
      </mat-card-actions>
    </form>
  </mat-card>


</app-main-nav>
