<app-main-nav>
<!-- {{userRole}} -->
  <div class="main-container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" fxLayout.xs="column" fxLayoutWrap>
    <div class="card-border" fxFlex="75">
      <div *ngIf="personalData && personalData.length > 4" class="top-row-config">
        <div class="extra-large-text-config table-text-dark">
          Trainee
        </div>
        <div class="large-text-config table-text-bold">
          {{personalData[0].val}}
        </div>
        <table class="med-text-config">
          <col width="200px" />
          <col width="200px" />
          <tbody>
            <tr>
              <td class="table-text-bold">Last Session</td>
              <td class="table-text">{{personalData[2].val}}</td>
            </tr>
            <tr>
              <td class="table-text-bold">Total Session</td>
              <td class="table-text">1</td>
            </tr>
            <tr>
              <td class="table-text-bold">Passed Training</td>
              <div class="table-text" *ngIf="(personalData && personalData[4] && personalData[4].val == 'Yes')">
                <td><mat-icon class="material-icons color_green" style="vertical-align:-0.2em;">circle</mat-icon> {{personalData[4].val}}</td>
              </div>
              <div class="table-text" *ngIf="!(personalData && personalData[4] && personalData[4].val == 'Yes')">
                <td><mat-icon class="material-icons color_red" style="vertical-align:-0.2em;">circle</mat-icon> {{personalData[4].val}}</td>
              </div>
            </tr>
            <tr *ngIf="(userRole != 'VIS_CASE' ) && (userRole != 'BOS')">
              <td class="table-text-bold">Passed Exam</td>
              <div class="table-text" *ngIf="(personalData && personalData[5] && personalData[5].val < 50)">
                <td><mat-icon class="material-icons color_red" style="vertical-align:-0.2em;">circle</mat-icon> {{personalData[5].val}}</td>
              </div>
              <div class="table-text" *ngIf="!(personalData && personalData[5] && personalData[5].val < 50)">
                <td><mat-icon class="material-icons color_green" style="vertical-align:-0.2em;">circle</mat-icon> {{personalData[5].val}}</td>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
  <div class="card-border" fxFlex="25">
  </div>

  </div>

  <br>
  <br>
  <br>
  <br>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutWrap>

    <div class="card-border" fxFlex="33">
      <div class="bottom-row-config">
        <div class="bottom-label-text main-container">
          <img style="height: 35px; vertical-align:-0.1em;" src="/assets/icon/dashboard_sessions_icon.svg"> &nbsp; Training Session
        </div>
        <table class="med-text-config">
          <col width="200px" />
          <col width="200px" />
          <tbody>
              <tr *ngFor="let data of trainingSessionData">
                  <td class="table-text-bold main-container">{{data.param}}</td>
                  <td class="table-text main-container">{{data.val}}</td>
              </tr>
          </tbody>
      </table>
      </div>
    </div>

    <div class="card-border" fxFlex="33">
      <div class="bottom-row-config">
        <div class="bottom-label-text main-container">
          <img style="height: 35px; vertical-align:-0.1em;" src="/assets/icon/dashboard_modules_icon.svg"> &nbsp; Modules
        </div>
        <table class="med-text-config">
          <col width="200px" />
          <col width="200px" />
          <tbody>
              <tr *ngFor="let data of modulesData">
                  <td class="table-text-bold main-container">{{data.param}}</td>
                  <td>
                    <div class="table-text main-container" *ngIf="data.val == 'Pending'">
                      <mat-icon class="material-icons color_red" style="vertical-align:-0.2em;">circle</mat-icon> &nbsp;&nbsp;  {{data.val}}
                    </div>
                    <div class="table-text main-container" *ngIf="data.val == 'Completed'">
                      <mat-icon class="material-icons color_green" style="vertical-align:-0.2em;">circle</mat-icon> &nbsp;&nbsp;  {{data.val}}
                    </div>
                  </td>
              </tr>
          </tbody>
      </table>
      </div>
    </div>

    <div class="card-border" fxFlex="34" *ngIf="(userRole != 'VIS_CASE' ) && (userRole != 'BOS')">
      <div class="bottom-row-config">
        <div class="bottom-label-text main-container">
          <img style="height: 35px; vertical-align:-0.25em;" src="/assets/icon/dashboard_outcomes_icon.svg"> &nbsp; Exam
        </div>
        <table class="med-text-config">
          <col width="200px" />
          <col width="200px" />
          <tbody>
              <tr *ngFor="let data of examData">
                  <td class="table-text-bold main-container">{{data.param}}</td>
                  <td class="table-text main-container">{{data.val}}</td>
              </tr>
          </tbody>
      </table>
      </div>
    </div>

    <div class="card-border" fxFlex="34" *ngIf="(userRole == 'VIS_CASE' ) || (userRole == 'BOS')">
      <div class="bottom-row-config">
        <div class="bottom-label-text main-container">
          <img style="height: 35px; vertical-align:-0.25em;" src="/assets/icon/dashboard_outcomes_icon.svg"> &nbsp; Modules Time
        </div>
        <table class="med-text-config">
          <col width="200px" />
          <col width="200px" />
          <tbody>
              <tr *ngFor="let data of modulesData">
                  <td class="table-text-bold main-container">{{data.param}}</td>
                  <td class="table-text main-container">{{data.time}}</td>
              </tr>
          </tbody>
      </table>
      </div>
    </div>

  </div>

</app-main-nav>
