import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {HttpService} from '../../service/http.service';
import { SharedService } from '../../service/shared.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  errorCode;
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private _httpService: HttpService,
    private _sharedService: SharedService
  ) {
    if(this.router.getCurrentNavigation().extras.state){
      this.errorCode = this.router.getCurrentNavigation().extras.state.error;
    }
   }

  ngOnInit() {
    this._sharedService.clearCookies();

    if( this.errorCode == '401'){
      this.snackBar.open('Session timed out! Please login again.', 'Close', { duration: 5000, horizontalPosition: "center", verticalPosition: "top" });
    }
    else{
      this.snackBar.open('Successful Logout', 'Close', {
        duration: 3000,
        "horizontalPosition": "right",
        "verticalPosition": "bottom",
      });
    }
      this.router.navigateByUrl("");
  }

}
