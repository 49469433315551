<app-main-nav>
    <div>
        <div fxLayout="row" fxLayoutAlign="stretch" flex fxLayout.lt-lg="column">
            <div class="sub-container" fxFlex="60" fxLayout="column" fxFlexAlign="stretch">
                <div fxLayout="row" flex fxLayout.lt-lg="column">
                    <div fxLayout="column" fxFlex="40">
                        <div class="text-config" fxLayout="column" fxLayoutAlign="center start">{{moduleFullName}}</div>
                        <div fxFlex="40" class="med-text-config">Leaderboard</div>
                        <div class="search-bar-config">
                            <mat-form-field style="min-width: 60%; max-width: 60%;">
                                <input class="input-config" (keyup)="searchTable($event.target.value)" matInput>
                                <mat-icon class="mat-icon-config" matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="table-config" fxFlex="70">
                    <mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="Rank">
                            <mat-header-cell class="table-header-font" *matHeaderCellDef>
                                <mat-icon style="transform: scale(2);">equalizer</mat-icon>
                            </mat-header-cell>
                            <mat-cell class="table-data-font" *matCellDef="let row">
                                <span *ngIf="row.Rank == 1"> <img src="/assets/kpi/Award_1st_icon.svg"
                                        style="height: 32px;"> </span>
                                <span *ngIf="row.Rank == 2"> <img src="/assets/kpi/Award_2nd_icon.svg"
                                        style="height: 30px;"> </span>
                                <span *ngIf="row.Rank == 3"> <img src="/assets/kpi/Award_3rd_icon.svg"
                                        style="height: 28px;"> </span>
                                <span *ngIf="row.Rank>3"> {{row.Rank}} </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Name">
                            <mat-header-cell class="table-header-font" *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Name}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Score">
                            <mat-header-cell class="table-header-font" *matHeaderCellDef>
                                <img src="/assets/kpi/Award_white_icon.svg" style="height: 32px;">
                            </mat-header-cell>
                            <mat-cell class="table-data-font" *matCellDef="let row"> {{row.BestScore}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Attempts">
                            <mat-header-cell class="table-header-font" *matHeaderCellDef> Attempts </mat-header-cell>
                            <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Attempts}} </mat-cell>
                        </ng-container>

                        <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showDetails(row)"></mat-row>
                    </mat-table>
                    <div *ngIf="isLoading" style="margin-left:2%; margin-top: 0.5%;">
                        <div *ngFor="let _ of [].constructor(10)">
                            <app-skeleton-loading Cwidth="900" Cheight="48"><br><br>
                            </app-skeleton-loading>
                        </div>
                    </div>
                    <mat-paginator class="paginator-class" [pageSizeOptions]="[10, 20, 50, 100]"
                        showFirstLastButtons></mat-paginator>
                </div>
            </div>
            <div class="sub-container" fxFlex="40" fxFlexAlign="stretch">
                <div class="text-config">Module Statistics</div>
                <br><br>
                <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start stretch">
                    <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg]="'card-column'" fxFlex.md="40%">
                        <mat-card-title class="card-heading-config" [ngClass.lt-lg]="'card-column-heading'">Sessions
                            Duration</mat-card-title>
                        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
                            <app-skeleton-loading Cwidth="250" Cheight="100">
                            </app-skeleton-loading>
                        </div>
                        <mat-card-content class="card-content-config"
                            [ngClass.lt-lg]="'card-column-content'">{{sessionDuration}}</mat-card-content>
                        <mat-card-content class="card-secondary-content-config"
                            [ngClass.lt-lg]="'card-column-secondary-content'">
                            <div fxLayout="row">
                                <p [ngClass]="sessionDurationUpdate >= 0 ? 'positive' : 'negative'">
                                    <span *ngIf="sessionDurationUpdate > 0">+</span>{{sessionDurationUpdate}}%
                                </p>&nbsp;
                                <p>on last month</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg]="'card-column'" fxFlex.md="40%">
                        <mat-card-title class="card-heading-config" [ngClass.lt-lg]="'card-column-heading'">Total
                            Sessions</mat-card-title>
                        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
                            <app-skeleton-loading Cwidth="250" Cheight="100">
                            </app-skeleton-loading>
                        </div>
                        <mat-card-content class="card-content-config"
                            [ngClass.lt-lg]="'card-column-content'">{{totalSessions}}</mat-card-content>
                        <mat-card-content class="card-secondary-content-config"
                            [ngClass.lt-lg]="'card-column-secondary-content'">
                            <div fxLayout="row">
                                <p [ngClass]="totalSessionsUpdate >= 0 ? 'positive' : 'negative'">
                                    <span *ngIf="totalSessionsUpdate > 0">+</span>{{totalSessionsUpdate}}%
                                </p>&nbsp;
                                <p>on last month</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg]="'card-column'" fxFlex.md="40%">
                        <mat-card-title class="card-heading-config" [ngClass.lt-lg]="'card-column-heading'">Modules
                            completed</mat-card-title>
                        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
                            <app-skeleton-loading Cwidth="250" Cheight="100">
                            </app-skeleton-loading>
                        </div>
                        <mat-card-content class="card-content-config"
                            [ngClass.lt-lg]="'card-column-content'">{{passRate}}</mat-card-content>
                        <mat-card-content class="card-secondary-content-config"
                            [ngClass.lt-lg]="'card-column-secondary-content'">
                            <div fxLayout="row">
                                <p [ngClass]="passRateUpdate >= 0 ? 'positive' : 'negative'">
                                    <span *ngIf="passRateUpdate > 0">+</span>{{passRateUpdate}}%
                                </p>&nbsp;
                                <p>on last month</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg]="'card-column'" fxFlex.md="40%">
                        <mat-card-title class="card-heading-config" [ngClass.lt-lg]="'card-column-heading'">Total
                            Trainees</mat-card-title>
                        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 12%;">
                            <app-skeleton-loading Cwidth="250" Cheight="100">
                            </app-skeleton-loading>
                        </div>
                        <mat-card-content class="card-content-config"
                            [ngClass.lt-lg]="'card-column-content'">{{totalTrainees}}</mat-card-content>
                        <mat-card-content class="card-secondary-content-config"
                            [ngClass.lt-lg]="'card-column-secondary-content'">
                            <div fxLayout="row">
                                <p [ngClass]="totalTraineesUpdate >= 0 ? 'positive' : 'negative'">
                                    <span *ngIf="totalTraineesUpdate > 0">+</span>{{totalTraineesUpdate}}%
                                </p>&nbsp;
                                <p>on last month</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg]="'card-column'" fxFlex.md="40%">
                        <mat-card-title class="card-heading-config" [ngClass.lt-lg]="'card-column-heading'">Errors
                            caught</mat-card-title>
                        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 8%;">
                            <app-skeleton-loading Cwidth="250" Cheight="130">
                            </app-skeleton-loading>
                        </div>
                        <mat-card-content class="chart-config">
                            <canvas id="error-chart" height="150" fxFlex="90%">{{errorsChart}}</canvas>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="card-config" fxFlex="45%" [ngClass.lt-lg]="'card-column'" fxFlex.md="40%">
                        <mat-card-title class="card-heading-config" [ngClass.lt-lg]="'card-column-heading'">Training
                            Modes</mat-card-title>
                        <div *ngIf="isStatsLoading" style="margin-left:5%; margin-top: 8%;">
                            <app-skeleton-loading Cwidth="250" Cheight="130">
                            </app-skeleton-loading>
                        </div>
                        <mat-card-content class="chart-config">
                            <canvas id="modes-chart" height="150" fxFlex="90%">{{modesChart}}</canvas>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</app-main-nav>