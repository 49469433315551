import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpService} from '../../service/http.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  mobileScreen = false;
  empID = null;

  constructor(
    private _httpService: HttpService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    if(window.innerWidth <= 575){
      this.mobileScreen = true;
    }
    this.empID = this.activatedRoute.snapshot.params["empID"];
    // console.log(this.empID);

    this._httpService.verifyAccountNewVIS(this.empID)
      .subscribe(data=>{
        // console.log(data);
      }, error => {
        console.log("Trainees Error");
      })
  }

}
