import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors} from "@angular/forms";
import {HttpService} from '../../service/http.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  changePassword: UntypedFormGroup;
  samePassword: boolean
  mobileScreen = false;
  empID = null;
  passwordTextType = "password";

  constructor(
    private snackBar: MatSnackBar,
    private _httpService: HttpService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    if(window.innerWidth <= 575){
      this.mobileScreen = true;
    }
    this.changePassword = this.createFormGroup();
    this.samePassword = false;
    
    this.empID = this.activatedRoute.snapshot.params["empID"];
    // console.log(this.empID);
  }
  createFormGroup(): UntypedFormGroup{
    return new UntypedFormGroup({
      password: new UntypedFormControl("", [
        Validators.required,
        // Validators.pattern('^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d)(?=[^!$%@#£.€*?&]*[!$%@#£.€*?&])[A-Za-z\d!$%@#£.€*?&]{8,}$')
        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%&()!-_=+\\;:\"*/.])[A-Za-z\d@#$%&()!-_=+\\;:\"*/.].{7,}$"),
        // Validators.pattern("[0-9]+"),
        // Validators.pattern("[a-z]+"),
        // Validators.minLength(8)
      ]),
      confirmPassword: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8)
      ]),
    }, {validators: this.checkPasswords})
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value

    return pass === confirmPass ? null : { changePassword: true }
  }
  

  changePasswordCall(): void {
    // API Call check
    this._httpService.forgetPasswordUpdate({
      "empID": this.empID,
      "password": this.changePassword.value.password
    }).subscribe(data => {
      // console.log("Login: " + data.role);
      this.samePassword = false;
      window.open("https://avataracademy.io/", "_self");
    }, error => {
      // console.log("Login Error");
      // console.log(error);
      this.samePassword = true;
      this.snackBar.open('Use a different password', 'Close', {
        duration: 3000,
        "horizontalPosition": "right",
        "verticalPosition": "bottom",
      });
    })
  }

  showPassword() {
    // console.log("Hit");
    if(this.passwordTextType == "password"){
      this.passwordTextType = "text";
    }else {
      this.passwordTextType = "password";
    }
  }
}
