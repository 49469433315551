import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {HttpService} from '../service/http.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  updatePasswordForm: UntypedFormGroup;
  updateRequestObject;
  invalidPassword = false;
  invalidPasswordText = "Invalid Current Password";
  constructor(
    private router: Router,
    private _httpService: HttpService
  ) { }

  ngOnInit() {
    this.updatePasswordForm = this.createFormGroup();
  }

  createFormGroup(): UntypedFormGroup{
    return new UntypedFormGroup({
      Current_Password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2)
      ]),
      New_Password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2)
      ]),
      Confirm_New_Password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2)
      ])
    })
  }


  updatePassword(){
    // console.log("update Password ");

    if(this.updatePasswordForm.value.New_Password == this.updatePasswordForm.value.Confirm_New_Password){
      // Request body
      this.updateRequestObject = {
        "currentPassword" : this.updatePasswordForm.value.Current_Password,
        "newPassword" : this.updatePasswordForm.value.New_Password
      };

      // API Call
      this._httpService.updatePassword(this.updateRequestObject)
      .subscribe(data=>{
        this.invalidPassword = false;
        // console.log(data);
        this.router.navigateByUrl("/Logout");
      }, error =>{
        this.invalidPasswordText = "Invalid Current Password";
        this.invalidPassword = true;
        // console.log("change password Error");
      })
    }else{
      this.invalidPasswordText = "New Password and Confirm New Password should be same.";
      this.invalidPassword = true;
    }
  }
}
