<div class="main-container">
    <div class="container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8%">
        <img src="assets/2.0/MER_logo-on-black.png" fxFlex="50%" fxHide.lt-md>
        <div class="sub-container" fxFlex="50%" fxFlex.lt-md="80%">
            <span class="text-config">404</span><br>
            <span class="mid-text-config">Oops! The page you're trying to reach doesn't exist in reality.</span><br><br>
            <span class="small-text-config">Click the button below for Virtual Reality content instead!</span><br><br><BR>
            <button class="button" mat-raised-button>
                <a mat-button routerLink="">Proceed to login</a>
              </button>
        </div>
    </div>
</div>>
