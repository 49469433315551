<div class="main-background">
    <br><br><br><br><br><br>
    <div class="container" fxLayout="row" fxLayoutAlign="center stretch" [ngClass.lt-md]="'container-lt-md'" fxLayout.lt-md="column" fxLayoutWrap>
      <div fxFlex="50%">
        <br><br><br><br>
        <div fxLayout="column">
            <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
        <br><br>
        <div class="normal-text">
            Enter in a new PIN below. The PIN must have <br>
            <div fxLayout="row" class="checkbox-text" fxLayoutGap="25%">
                <div>
                  <mat-checkbox [checked]="!pinForm.controls.pin.hasError('minlength') && !(pinForm.controls.pin.value.length == 0)" tabindex="-1">Minimum 4 characters</mat-checkbox>
                  <mat-checkbox [checked]="!pinForm.controls.pin.hasError('nums') && !(pinForm.controls.pin.value.length == 0)" tabindex="-1">Only Numeric character</mat-checkbox>
                </div>
                <div>
                  <mat-checkbox [checked]="!pinForm.controls.pin.hasError('maxlength') && !(pinForm.controls.pin.value.length == 0)" tabindex="-1">Maximum 9 characters</mat-checkbox>
                </div>
            </div>
        </div>
          <form [formGroup]="pinForm" (ngSubmit)="reset()" novalidate>

              <mat-form-field floatLabel="never" >
                <mat-label class="label-field"> PIN </mat-label>
                <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                  <input class="input-text" matInput type="password" formControlName="pin" [type]="hidepin ? 'password' : 'text'">
                  <mat-icon matSuffix (click)="hidepin = !hidepin" style="vertical-align:middle; padding: 2% 2% 0% 0.5%; transform: scale(1.5); cursor: pointer;">
                    {{hidepin ? 'visibility_off' : 'visibility'}}
                  </mat-icon>
                </div>
              </mat-form-field>
              <span *ngIf="pinForm.controls.pin.touched && pinForm.controls.pin.hasError('required')" class="error-message">PIN is required.</span>

              <mat-form-field floatLabel="never" >
                <mat-label class="label-field"> Confirm PIN</mat-label>
                <div class="input-field" fxLayoutAlign="flex-end" [ngClass.lt-md]="'mat-icon-config'" fxLayout.lt-md="row" fxLayoutWrap>
                  <input class="input-text" matInput type="password" formControlName="conpin" [type]="hideconpin ? 'password' : 'text'">
                  <mat-icon matSuffix (click)="hideconpin = !hideconpin" style="vertical-align:middle; padding: 2% 2% 0% 0.5%; transform: scale(1.5); cursor: pointer;">
                    {{hideconpin ? 'visibility_off' : 'visibility'}}
                  </mat-icon>
                </div>
              </mat-form-field>

              <span *ngIf="pinForm.controls.conpin.touched && pinForm.controls.conpin.hasError('required')" class="error-message">Confirm PIN is required.</span>
              <span *ngIf = "pinForm.controls.conpin.hasError('confirmPasswordMatch')" class="error-message">PINs should match.</span>
              <button class="button"  mat-raised-button type="submit" [disabled]="pinForm.invalid">
                <span class="font-Libre-Medium">RESET</span>
              </button>
          </form>
        </div>
      </div>
      <div fxFlex="50%" *ngIf="!mobileScreen" fxHide.lt-md>
        <img class="img" src="/assets/2.0/AVA_login_imagery.jpg" alt="AA Labs">
      </div>
    </div>
  </div>
