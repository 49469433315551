  <div class="main-background">
    <br><br><br><br><br><br>
    <div class="container" fxLayout="row" fxLayoutAlign="center stretch" [ngClass.lt-md]="'container-lt-md'" fxLayout.lt-md="column" fxLayoutWrap>
      <div fxFlex="50%">
        <br><br>
        <img class="img-center" src="/assets/2.0/AVA_Logo_white.svg" alt="AVA_full-logo">
        <p style="color: white;font-size:30px;margin: 30px;padding: 30px;justify-content: center;" class="font-Libre-Medium1"><strong>Your License is expired</strong></p>
        <p style="color: white;font-size:25px;margin: 30px;padding: 30px;" class="font-Libre-Medium1"> You no longer have access to the Avatar Academy web portal. Please contact your administrator to ensure continued access.</p>
        <button class="button"  mat-raised-button type="submit" (click)="backtologin()">
            <span class="font-Libre-Medium">OK</span>
         </button>
      </div>
      <div fxFlex="50%" fxHide.lt-md>
        <img class="img" src="assets/2.0/AVA_login_imagery.jpg" alt="AA Labs">
      </div>
    </div>
  </div>
